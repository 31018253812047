/*=============================================================================
 Event.tsx - Web Page

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { useState, } from 'react';
import { cLo, cCo, cLoIf, } from 'utils/util';
import { RegEx_URL, } from 'utils/RegEx';
import type { TMemberCat, IRsvpParams, } from 'features/rsvp/RSVP';
import { EmailLink, ALink, } from 'ui/ui';
import { ExclamationIcon, DocumentDownloadIcon, } from '@heroicons/react/outline';
import { CheckIcon, ChatIcon, } from '@heroicons/react/solid';
import { EmbedYouTube, } from 'features/chat/cont/EmbedView';
import { PDFViewModal, } from 'ui/PDFViewModal';

export interface IEventSchedule {
  start: string;
  end:   string;
  text:  string;
  note?: string;
  ac?:   TMemberCat[];  // access control, if definded, check
};

export const eventDate = {
  // weworkcom:  new Date(2021,8,24,16),  // Sep
  weworkcom:  new Date(2021,9,29,16),  // Oct
  weworktech: new Date(2021,7,27,16),  // Aug
};

const eD = {  // eventData
  weworkcom: {
    head: {
      // date:     { YYYYMM: "2021年8月", DD: "27日(金)" },  // 2021-08-18 change
      // date:     { YYYYMM: "2021年9月", DD: "24日(金)" },  // 2021-09-16 change
      date:     { YYYYMM: "2021年10月", DD: "29日(金)" },
      number:   "第四回",
      title:    "WeWork情報交換会",
      subTitle: "〜 暮らしを守る技術 〜",
    },
    overview: {
      title: "開催概要",
      table: [
        // { col0: "開催日時", col1: "2021年8月27日 17:00より開場" },
        { col0: "開催日時", col1: "2021年10月29日 17:00より開場" },
        { col0: "場　　所", col1: "リンクスクエア新宿 16F（会議室：プライベート仕様会議室 16J）" },
        { col0: "住　　所", col1: "〒151-0051 東京都渋谷区千駄ヶ谷 5-27-5" },
        { col0: "アクセス", col1: <ALink href="http://linksquare-shinjuku.com/access.html">地図</ALink> },
      ],
      imgLink:
        <ALink href="https://www.wework.com/buildings/link-square-shinjuku--tokyo">
          <img {...cLo("shadow-md")}
            src="https://locations-api-production.imgix.net/locations/image/1465fb88-576e-11ea-b8b7-1214a1ac73cf/Web_150DPI-20200210_WeWork_Link_Square_Shinjuku_-_Tokyo_013.jpg?auto=format%20compress&fit=crop&q=50&w=1800&h=1013"
            alt="linksquare"
          />
        </ALink>
    },
    notice: {
      title: "お知らせ",
      leaflets: [
        { thumb: "CITS-forum-2021-th.jpg",
          src:   "CITS-forum-2021.pdf",
          // src:   "HamicPOCKET20211006.pdf",
          title: "CITSフォーラム2021のご案内"
        },
        // { thumb: "canon-tele-1-th.jpg",
        //   src:   "テレワークサポーターリーフレット_202107版.pdf",
        //   title: "キヤノンITソリューションズ株式会社 テレワークサポートシステム"
        // },
        // { thumb: "Bitkey_workspace_office-th.jpg",
        //   src:   "Bitkey_workspace_office.pdf",
        //   title: "ビットロック Starter Kit",
        // },
      ],
    },
    schedule: [
      { start: "15:00", end: "16:00", text: "事務局員の集合時間",         ac: ['A'] },
      { start: "16:00", end: "17:00", text: "会場設営、展示者の集合時間", ac: ['A','B'] },
      { start: "17:00", end: "17:30", text: "展示会" },
      { start: "17:30", end: "19:00", text: "情報交換会" },
      { start: "19:00", end: "20:00", text: "懇親会",
        note: "予約制：当日会場受付で会費（予定）￥1000をお支払いください"
      },
    ],
    exhibit: [
      {
        cat: 'A',
        company: "キヤノンITソリューションズ株式会社",
        product: "テレワークサポートシステム(テレワークサポーター)",
        leaflets: [
          { thumb: "canon-tele-1-th.jpg",
            src:   "テレワークサポーターリーフレット_202107版.pdf",
            title: "キヤノンITソリューションズ株式会社 テレワークサポートシステム"
          },
          { thumb: "canon-tele-case-1-th.jpg",
            src:   "導入事例_ベルシステム24様.pdf",
            title: "キヤノンITソリューションズ株式会社 テレワークサポートシステム"
          },
        ],
      },
      {
        cat: 'A',
        company: "東芝トレーディング株式会社",
        product: "Xactiウェラブルカメラ、360°WEB会議カメラ",
        leaflets: [
          { thumb: "Xacti_wearable-th.jpg",
            src:   "Xacti_wearable.pdf",
            title: "Xactiウェラブルカメラ代理店用"
          },
          { thumb: "leaflet_360webcam-th.jpg",
            src:   "leaflet_360webcam.pdf",
            title: "リーフレット_360°WEB会議カメラ代理店記載版"
          },
        ],
      },
      {
        cat: 'B',
        company: "株式会社ワンズ",
        product: "スタンプラリーアプリ(furariフラリ)",
        leaflets: [
          { thumb: "ones-furari-1.jpg",
            src:   "furari-leaflet.pdf",
            title: "スタンプラリーアプリfurariフラリ",
          },
          { thumb: "ones-furari-2.jpg",
            src:   "furar42.pdf",
            title: "スタンプラリーアプリfurariフラリ",
          },
          { thumb: "ones-furari-3.jpg",
            src:   "furari-achieve.pdf",
            title: "スタンプラリーアプリfurariフラリ",
          },
        ],
      },
      {
        cat: 'B',
        company: "株式会社ビットキー",
        product: "スマートロック",
        leaflets: [
          { thumb: "Bitkey_workspace_office-th.jpg",
            src:   "Bitkey_workspace_office.pdf",
            title: "ビットロック Starter Kit",
          },
        ],
      },
      {
        cat: 'B',
        company: "Hamee Corp.",
        product: "HamicPOCKET",
        leaflets: [
          { thumb: "HamicPOCKET20211006-th.jpg",
            src:   "HamicPOCKET20211006.pdf",
            title: "HamicPOCKET",
          },
        ],
      },
      {
        cat: 'B',
        company: "NTT Communications Corporation",
        product: "従業員の活躍を推進するワークスタイルDX",
        leaflets: [
          { thumb: "NTTCommSmartWork-th.jpg",
            src:   "NTTCommSmartWork.pdf",
            title: "Smart Workstyle",
          },
        ],
      },
      // {
      //   cat: 'B',
      //   company: "CMC Japan株式会社",
      //   product: "顔認証システム",
      // },
      // {
      //   cat: 'B',
      //   company: "株式会社SOYMIL",
      //   product: "豆乳調理器(SOYMILブレンダー)",
      // },
    ],
    exhibitM: [
      {
        cat: 'B',
        company: "株式会社ビットキー",
        product: "スマートロック",
        movies: [
          "https://www.youtube.com/watch?v=yP3FLmJWEdA",
        ],
      },
      {
        cat: 'B',
        company: "NTT Communications Corporation",
        product: "dropin",
        movies: [
          "https://youtu.be/GfKTVTJ4Yik",
        ],
      },
      // {
      //   cat: 'A',
      //   company: "SpacetimeQ Inc",
      //   product: "イベントシステム",
      //   movies: [
      //     "https://youtu.be/zlPSy0KX23A",
      //   ],
      // },
    ],
    dowloadURL: "https://raw.githubusercontent.com/dashgon/dashgon.github.io/master/public/wework/",
    downloadTemplates: [
      {
        thumb: "wwc-01.jpg",
        src:   "wework_情報交換会資料.pdf",
        title: "ダウンロード資料1",
      },
      {
        thumb: "wwc-02.jpg",
        src:   "自己紹介シート_テンプレ株式会社_雛型太郎.pptx",
        title: "ダウンロード資料2",
      },
    ],
  },
  weworktech: {
    head: {
      date:     { YYYYMM: "2021年8月", DD: "27日(金)" },
      number:   "第一回",
      title:    "WeWork技術交流会",
      subTitle: "〜 WeWork TECH MEETING 〜",
    },
    overview: {
      title: "開催概要",
      table: [
        { col0: "開催日時", col1: "2021年8月27日 17:00より開始" },
        { col0: "対　　象", col1: "Web、IT関連のエンジニア"},
        { col0: "場　　所", col1: "オンライン開催（スタジオへの訪問をご希望の場合は予めご連絡ください）"},
        { col0: "参加 URL", col1: <ALink href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_ZjdkYjVmODktZDc1OC00MmNhLTk0YzctODdmOTZiMDJlYjYw%40thread.v2/0?context=%7b%22Tid%22%3a%22a881658a-5fdb-45a5-9d9b-8068b06d4941%22%2c%22Oid%22%3a%22373fe32d-79cf-47ff-b7e3-fc1678d58eeb%22%7d">MS Teams-Meeting</ALink>},
      ],
      imgLink:
        <img {...cLo("shadow-md")}
          src="https://images.unsplash.com/photo-1588196749597-9ff075ee6b5b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2767&q=80"
          alt="wework"
        />
    },
    notice: {
      title: "お知らせ",
      leaflets: [
      ],
    },
    schedule: [
      { start: "16:30", end: "17:00", text: "会場設営" },
      { start: "17:00", end: "19:00", text: "Tech Meeting（オンライン開催)" },
    ],
  },
};

const srcDL = (src: string) =>
  RegEx_URL.url.test(src)
    ? src
    : eD.weworkcom.dowloadURL + src;

export const TitleH: React.FC<IClassNameObj> = ({ className, children }) =>
  <div {...cLo("flex flex-row items-center justify-center font-bold text-2xl my-4", className)}>
    <CheckIcon {...cLo("w-6 h-6 p-0.5 text-indigo-600 bg-green-100",
      "rounded-full shadow-md mr-4")}
    />
    {children}
  </div>;

interface IEventProps {
  grp: IRsvpParams['grp'];
  cat: TMemberCat;
};
/**
 * Event Page
 *
 * Outer window <article>'s height should be h-auto when detail mode, and h-full for hidden.
 */
export const Event: React.FC<IEventProps> = ({ grp, cat, children }) => {
  console.log("Event page");
  const [detail,   setDetail]   = useState(true);
  const [open,     setOpen]     = useState(false);
  const [srcPDF,   setSrcPDF]   = useState('');
  const [srcTitle, setSrcTitle] = useState('');

  const handleClickPDF = (pdf: string, title: string) => {
    setOpen(!open);
    setSrcPDF(pdf);
    setSrcTitle(title);
  }

  // section container
  const twCont = "leading-5 bg-gray-100 dark:bg-gray-300 px-1 sm:px-8 py-6 rounded-lg shadow-md";
  const twFCR = "flex flex-col sm:flex-row";  // flex col in narrow, row in wide mode
  const twLH = cLo("font-bold my-2 text-lg");
  return (
    <article
      {...cLo("relative max-w-full bg-gray-50 dark:bg-gray-200 text-gray-900",
          "rounded-xl sm:m-4 p-1 sm:p-8", detail ? "h-auto" : "h-full")}
    >
      <PDFViewModal src={srcPDF} title={srcTitle} {...{open, setOpen}} />
      <main {...cLo("z-10 relative bg-gray-50 dark:bg-gray-200")}>

        <section {...cLo("relative border-2 border-gray-400 rounded-lg p-4 shadow-lg")}>
          <p {...cLo("absolute top-0 right-0 font-bold italic text-4xl mt-2 mr-4",
            "opacity-30 text-indigo-600")}
          >
            β
          </p>
          <div {...cLo(twFCR, "justify-center")}>
            <div {...cLo("flex flex-col font-bold font-mono",
              "border border-gray-200 shadow-md TextShadowB")}
            >
              <div {...cLo("text-[1.4rem] bg-gray-400 text-green-50 text-center p-2")}>
                {eD[grp].head.date.YYYYMM}
              </div>
              <div {...cLo("text-3xl text-center p-2")}>{eD[grp].head.date.DD}</div>
            </div>
            <div {...cLo("font-bold self-center border border-gray-200",
              "p-4 shadow-md w-full sm:w-auto")}
            >
              <div {...cLo(twFCR, "text-3xl mb-2 md:mx-10")}>
                <div {...cLo("text-2xl text-center items-center sm:mr-2",
                  "text-gray-50 bg-gray-700 px-2")}
                >
                  {eD[grp].head.number}
                </div>
                <div {...cLo("text-center")}>{eD[grp].head.title}</div>
              </div>
              <div {...cLo("text-2xl text-center")}>{eD[grp].head.subTitle}</div>
            </div>
          </div>
          <div {...cLo(twFCR, "text-center justify-center w-full mt-6")}>
            {children}
          </div>
        </section>

        <section>
          <TitleH>{eD[grp].overview.title}</TitleH>
          <div {...cLo(twCont)}>
            <div {...cLo(twFCR)}>
              <table {...cCo("border border-gray-400 shadow-md",
                eD[grp].overview.imgLink, "sm:w-3/5")}
              >
                <tbody {...cLo("bg-gray-50")}>
                  {eD[grp].overview.table.map((row, id) => (
                    <tr key={id} {...cLo(id % 2 === 0 ? 'bg-gray-200' : 'bg-gray-100')}>
                      <td {...cLo("p-3 whitespace-nowrap font-bold text-base")}>{row.col0}</td>
                      <td {...cLo("p-3")}>{row.col1}</td>
                    </tr>))}
                </tbody>
              </table>
              {eD[grp].overview.imgLink &&
                <div {...cLo("sm:w-2/5")}>{eD[grp].overview.imgLink}</div>}
            </div>
          </div>
        </section>

        <section>
          <TitleH>{eD[grp].notice.title}</TitleH>
          <div {...cCo("grid grid-cols-1 justify-items-center",
            eD[grp].notice.leaflets?.length > 1,
            "sm:grid-cols-2 md:grid-cols-3")}
          >
            {eD[grp].notice.leaflets?.map(lf =>
            <div key={lf.title} {...cLo("m-2 cursor-pointer")}
              onClick={() => handleClickPDF(srcDL(lf.src), lf.title)}
            >
              <img src={srcDL(lf.thumb)} alt="thumb"
                {...cLo("w-auto border transition duration-300 ease-in-out hover:scale-105",
                  "hover:border-indigo-600 shadow-md")}
              />
            </div>)}
          </div>
        </section>
        <div {...cLo("w-full text-center text-lg bg-gray-400 rounded-md cursor-pointer",
              "hover:text-gray-100 hover:bg-gray-500 p-1 shadow-md mt-4")}
          onClick={() => setDetail(!detail)}
        >
          {detail ? "省略…" : "詳細…"}
        </div>
      </main>

      <aside {...cLoIf(detail, "relative")}>
        {grp === 'weworkcom'
          ? <>
            <section>
              <TitleH>タイムスケジュール</TitleH>
              <div {...cLo(twCont)}>
                <table {...cLo("w-full border border-gray-400 shadow-md")}>
                  <tbody {...cLo("bg-gray-50")}>
                    {eD.weworkcom.schedule
                      .filter(row => !row.ac || row.ac.includes(cat))
                      .map((row, id) =>
                        <tr key={id} {...cLo(id % 2 === 0 ? 'bg-gray-200' : 'bg-gray-100')}>
                          <td {...cLo("p-3 whitespace-nowrap font-mono font-bold text-base")}>
                            {row.start}〜{row.end}
                          </td>
                          <td {...cLo("p-3 flex flex-row items-center")}>
                            <span {...cLo("whitespace-nowrap")}>{row.text}</span>
                            {row.note && <>
                              <ExclamationIcon {...cLo("flex flex-shrink-0 w-5 h-5 p-0.5",
                                "text-red-700 bg-green-300 rounded-full ml-2")}
                              />
                              <span {...cLo("ml-1 text-sm text-indigo-600")}>{row.note}</span>
                            </>}
                          </td>
                        </tr>)}
                  </tbody>
                </table>
              </div>
            </section>

            <TitleH>イベント内容</TitleH>
            <div {...cLo(twCont)}>
              <ul {...cLo("leading-7")}>
                <li>WeWorkの中で出会った皆さん同士での情報交換を目的とするイベントとなります。</li>
                <li>皆さんで自社商品やサービスのご紹介などを行っていただきます。</li>
                <li>具体的には展示会への展示、自己紹介資料の説明、懇親会への参加を通し皆様との相互理解を深めていただきます。</li>
                <li>今回のテーマは「暮らしを守る技術」のテーマに沿った複数の展示物がございます。</li>
              </ul>
              <h2 {...twLH}>展示物（パンフレット）：</h2>
              <ul {...cLo("grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3")}>
                {eD.weworkcom.exhibit.map((ex, id) => (
                  <li key={id} {...cLo("relative col-span-1 bg-white rounded-lg shadow")}>
                    <div {...cLo("p-3 font-bold text-base rounded-t-lg truncate",
                      (ex.cat === 'A') && "text-white bg-gray-600",
                      (ex.cat === 'B') && "text-gray-100 bg-gray-400")}
                    >
                      {ex.company}
                    </div>
                    <div {...cLo("p-2 text-sm truncate")}>{ex.product}</div>
                    <div {...cLo(ex.leaflets.length > 1 ? "grid grid-cols-2" : "flex-col")}>
                      {ex.leaflets?.map(lf =>
                        <div key={lf.thumb} {...cLo("m-2 cursor-pointer")}>
                          <ALink href={srcDL(lf.src)} download>
                            <img src={srcDL(lf.thumb)} alt="thumb"
                              {...cLo("w-auto border transition duration-300 ease-in-out hover:scale-110",
                                "hover:border-indigo-600 shadow-md")}
                            />
                          </ALink>
                        </div>)}
                    </div>
                  </li>
                ))}
              </ul>
              <h2 {...twLH}>展示物（動画）：</h2>
              <ul {...cLo("grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3")}>
                {eD.weworkcom.exhibitM.map((ex, id) => (
                  <li key={id} {...cLo("col-span-1 bg-white rounded-lg shadow")}>
                    <div {...cLo("p-3 font-bold text-base rounded-t-lg truncate",
                      (ex.cat === 'A') && "text-white bg-gray-600",
                      (ex.cat === 'B') && "text-gray-100 bg-gray-400")}
                    >
                      <span {...cLo("text-yellow-300 TextShadowB mr-2")}>{ex.product}</span>
                      <span {...cLo("text-xs")}>{ex.company}</span>
                    </div>
                    <div {...cLo("w-full flex flex-row items-center justify-between")}>
                      {ex.movies?.map(url =>
                        <EmbedYouTube
                          key={url}
                          className="w-full"
                          {...{url}}
                        />)}
                    </div>
                  </li>
                ))}
              </ul>

              <h2 {...twLH}>協力：<span {...cLo("font-normal text-sm")}>（参加順）</span></h2>
              <ul {...cLo("leading-7 list-disc ml-6 font-bold")}>
                <li>キヤノンITソリューションズ株式会社</li>
                <li>東芝トレーディング株式会社</li>
              </ul>
              <h2 {...twLH}>協賛：<span {...cLo("font-normal text-sm")}>（参加順）</span></h2>
              <ul {...cLo("leading-7 list-disc ml-6")}>
                <li>SpacetimeQ Inc</li>
              </ul>

            </div>
            <TitleH>連絡事項</TitleH>
            <ul {...cLo(twCont, "leading-7")}>
              <li>会場入場ためのQRコートはイベントへの参加希望者のみ後日お知らせいたします。</li>
              <li>本会への参加を推薦したい方がいらっしゃる場合は事前にご連絡ください。</li>
              <li>懇親会は事前予約制となり。会費は現金でのお支払いとなり領収書の発行はできません。</li>
              {(cat === 'A' || cat === 'B') &&
              <>
                <li {...cLo("font-bold mt-4")}>提出資料（自己紹介シート）の提出のお願い</li>
                <li>初めて交流会に参加いただく企業様には自己紹介シートの提出をお願いしております。</li>
                <li>自己紹介シートは交流会の5営業日前までにご送付いただく必要がございますのでファイル一覧よりダウンロードいただき、
                  <EmailLink email="event@spacetimeq.com">[お問い合わせ先]</EmailLink>までご送付ください。
                </li>
                <br/>
                <li>作成いただいた自己紹介シートを元に当日5分程度でご説明をいただいておりますが、紹介したいサービスが複数ある場合は複数ページの作成が可能です。</li>
                <li>ただし、時間の関係上から途中で発表を中断させていただく場合がございます。</li>
              </>}
            </ul>
            </>
          : grp === 'weworktech'
            ? <EventDetailsWeworktech {...{cat}} />
            : null}
      </aside>

      <footer {...cLoIf(detail)}>
        {(grp === 'weworkcom' && ['A', 'B'].includes(cat)) &&
        <section>
          <TitleH>ダウンロードファイル一覧</TitleH>
          <div {...cLo(twCont, twFCR)}>
            {eD[grp].downloadTemplates.map(lf =>
              <div key={lf.src} {...cLo("relative group m-2 cursor-pointer")}>
                <ALink href={srcDL(lf.src)} download>
                  <img src={srcDL(lf.thumb)} alt="thumb"
                    {...cLo("w-40 border transition duration-300 ease-in-out group-hover:scale-125",
                      "hover:border-indigo-600")}
                  />
                  {lf.src}
                  <DocumentDownloadIcon
                    {...cLo("absolute w-8 h-8 top-0 left-0 transition duration-300 ease-in-out",
                    "text-yellow-500 group-hover:scale-150 opacity-50 group-hover:opacity-100")}
                  />
                </ALink>
              </div>
            )}
          </div>
        </section>}
      </footer>
      <section>
        <TitleH>チャット</TitleH>
        <ul {...cLo(twCont, "flex flex-row items-center")}>
          <ChatIcon {...cLo("w-8 h-8 mr-1 text-green-400")} />
          準備中
        </ul>
      </section>
    </article>
  );
}

const EventDetailsWeworktech = ({ cat }: { cat: TMemberCat; }) => {
  const twCont = "leading-5 bg-gray-100 dark:bg-gray-300 px-1 sm:px-8 py-6 rounded-lg shadow-md";
  const twDisc = "font-bold list-disc";
  return (
    <>
      <section>
        <TitleH>タイムスケジュール</TitleH>
        <div {...cLo(twCont)}>
          <table {...cLo("w-full border border-gray-400 shadow-md")}>
            <tbody {...cLo("bg-gray-50")}>
              {(eD.weworktech.schedule as IEventSchedule[])
                .filter(row => !row.ac || row.ac.includes(cat))
                .map((row, id) =>
                  <tr key={id} {...cLo(id % 2 === 0 ? 'bg-gray-200' : 'bg-gray-100')}>
                    <td {...cLo("p-3 whitespace-nowrap font-mono font-bold text-base")}>
                      {row.start}〜{row.end}
                    </td>
                    <td {...cLo("p-3 flex flex-row items-center")}>
                      <span {...cLo("whitespace-nowrap")}>{row.text}</span>
                      {row.note && <>
                        <ExclamationIcon {...cLo("flex flex-shrink-0 w-5 h-5 p-0.5",
                          "text-red-700 bg-green-300 rounded-full ml-2")}
                        />
                        <span {...cLo("ml-1 text-sm text-indigo-600")}>{row.note}</span>
                      </>}
                    </td>
                  </tr>)}
            </tbody>
          </table>
        </div>
      </section>

      <TitleH>イベント内容</TitleH>
      <div {...cLo(twCont)}>
        <ul {...cLo("leading-7")}>
          <li>Wework内での技術系ミーティングです。</li>
          <li>Web、IT関係のサービスを提供されている企業様で自社の製品をご説明いただきます。</li>
          <br />
          <li>エンジニア目線から自社のサービスを発表してください。</li>
          <li>資料については各自でご準備いただき発表時に投影していただく形でお願いします。</li>
          <br />
          <li>発表の内容ですが以下のような内容を10分ほどで発表していただく形でお願いします。</li>
          <br />
          <li {...cLo(twDisc)}>サービスの説明</li>
          <li>サービスの特徴、他社優位性など</li>
          <li {...cLo(twDisc)}>実装技術の説明</li>
          <li>利用環境、利用言語など</li>
          <li {...cLo(twDisc)}>システム概要の説明</li>
          <li>アーキテクチャー、技術スタック、プロトコルなど</li>
          <li {...cLo(twDisc)}>まとめ</li>
          <li>今後の展望、求めるパートナーなど</li>
        </ul>
      </div>
      <TitleH>連絡事項</TitleH>
      <ul {...cLo(twCont, "leading-7")}>
        <li {...cLo("list-disc")}>本イベントはオンラインでの開催となります。</li>
        <li {...cLo("list-disc")}>本イベントはIT関連のエンジニアを対象にしております。</li>
        <li {...cLo("list-disc")}>希望者はオーシャンゲートみなとみらい内のスタジオへの招待が可能です。</li>
        <br/>
        <li>会　　場：オーシャンゲートみなとみらい （会議室：会議室 10H）</li>
        <li>住　　所：〒220-0012　神奈川県横浜市西区みなとみらい三丁目7番2</li>
        <li>アクセス：<ALink href="https://www.google.com/maps/place/WeWork/@35.458015,139.634091,16z/data=!4m5!3m4!1s0x60185dbcbbbfa3c5:0xe1dff56abc6e8fe8!8m2!3d35.4581692!4d139.6343603?hl=en">地図</ALink></li>
      </ul>
    </>
  );
}

// export const FakeDownloadLink: React.FC = ({ children }) => {
//   const [error,  setError]  = useState<TError0>(null);
//   const handleClick = () => {
//     setError(errorFmt(
//       'ERROR',
//       "認証エラー",
//       "ログインが必要な機能です。（現在は利用不可、準備中）")
//     );
//   }
//   return (
//     <>
//       <div {...cLo("underline text-blue-500 cursor-pointer")}
//         onClick={() => handleClick()}
//       >
//         {children}
//       </div>
//       {error && <ErrorDlg {...{error}} />}
//     </>
//   );
// }
//
/*
            {(grp === 'weworkcom') &&
            <p {...cLo("absolute top-0 left-0 font-bold text-lg -mt-8 mr-4",
              "text-red-600 TextShadowB opacity-60 bg-yellow-200 rounded-full")}
            >
              緊急事態宣言により
              <span {...cLo("text-xl")}>延期</span>されました！
            </p>
            }

          
*/

/*=============================================================================
 datetime.ts - date time utilties

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { format, formatDistanceToNow } from 'date-fns';
import { lZ } from 'utils/util';
import { useRef, useState, useEffect, } from 'react';

type TCallback = () => void;

// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export function useInterval(callback: TCallback, delay: number) {
  const savedCallback = useRef<TCallback>();

  // Remember the latest callback
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval
  useEffect(() => {
    function tick() {
      savedCallback.current?.();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

// 05/10/2019 by Soomin
// Idle time CPU usage: 2% WTH?
export function useAnimationFrame(callback: () => void, delay: number) {
  const savedCallback = useRef<TCallback>();
  // const [lastTs, setLastTs] = useState(0);  // lastTs remained 0 in the tick loop even though setLastTs(ts) called.
  const lastTs = useRef<number>(0);  // returned object will persist for the full lifetime of the component

  // Remember the latest callback
  useEffect(() => {
    //console.log(callback);
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval
  useEffect(() => {
    let frameId: number;

    const tickCB = (ts: number) => {  // loop called repeatedly by rAF
      if (ts - lastTs.current >= delay) {
        //console.log(ts, lastTs.current, ts - lastTs.current);
        lastTs.current = ts;
        savedCallback.current?.();
      }
      frameId = requestAnimationFrame(tickCB);
    }
    tickCB(0);  // called once at the start
    return () => cancelAnimationFrame(frameId);
  }, [delay]);
}

/**
 * datatime string
 */
export const datetimeStr = (
  date:      Undefinable<string|number|Date>,
  printDate: boolean = true,
  dtSp:      string = '/',  // date separator
  tmSp:      string = ':'   // time separator
) => {
  if (!date)
    return null;
  const dt = new Date(date);
  let yyyymmdd = '';
  if (printDate) {
    const today = new Date();
    if (dt.getFullYear() !== today.getFullYear())  // suppress year for the same year
      yyyymmdd = dt.getFullYear() + dtSp;
    yyyymmdd += lZ(dt.getMonth() + 1) + dtSp +
                lZ(dt.getDate()) + ' ';
  }
  const hhmm = lZ(dt.getHours()) + tmSp +
               lZ(dt.getMinutes());
  return yyyymmdd + hhmm;
}

/**
 * seconds to hh:mm:ss format string
 * @param fullmode show h:mm part even when they are zeros, 0:00:ss
 * @param h24 show always hh:
 */
export const sec2hmso = (
  sec:      number,
  fullmode: boolean = false,
  h24:      boolean = false
) => {
  if (!fullmode && (sec < 60))
    return ({
      hhmm: '',
      ss:   sec.toFixed(1) + 's'
    });
  const tmSp = ':';
  let hstr: string = '';
  let s = Math.trunc(sec);
  const h = Math.trunc(s/3600);
  if (h > 0) {
    s -= h * 3600;
  }
  if (h24) {
    hstr = lZ(h) + tmSp;
  } else if (h > 0) {
    hstr = h + tmSp;
  }
  const m = Math.trunc(s/60);
  s -= m * 60;
  return ({
    hhmm: hstr + lZ(m),
    ss:   tmSp + lZ(s)
  });
}

export const sec2hms = (
  sec:      number,
  fullmode: boolean = false,
  h24:      boolean = false
) => {
  const { hhmm, ss } = sec2hmso(sec, fullmode, h24);
  return hhmm ? hhmm + ss : ss;
}

/**
 * UMT to local time string with 'ago' description
 */
export const timeUMTlocal = (umt: TDateUMT, ago: boolean = true) => {
  if (!umt)
    return;
  const date = new Date(umt);
  let str = format(date, 'yyyy-MM-dd HH:mm:ss z eee');
  return ago
    ? str + ', ' + formatDistanceToNow(date) + ' ago'
    : str;
}

/**
 * number (milliseconds) to MM月DD日hh:mm string
 */
export const msToMMDDhhmm = (
  sec: number
) => {
  const d = new Date(sec);
  return `${d.getMonth() + 1}月${d.getDate()}日${lZ(d.getHours())}:${lZ(d.getMinutes())}`;
}

export interface ICountdown {
  pos:  0|1|2;   // position in the deadlines
  days: number;  // positive
  hhmm: string;  // hh:mm
  ss:   string;  // :ss
};
export interface ICountdownProps {
  aDead:        number[];  // date in milliseconds, array of deadlines, ascending order
  secInterval?: number;    // count interval in seconds
};
/**
 * Generate countdown data: days in NNN hh:mm:ss format
 *
 *  pos: 0,1,2
 *  0 |aDead[0]| 1 |aDead[1]| 2
 *  - Two deadlines, future version may generalize for a series of deadlines
 */
export const useCountdown = ({ aDead, secInterval = 1 }: ICountdownProps) => {
  const getCountdown = (): ICountdown => {
    // difference in days (floating number)
    const secDiff = (dt1: Date, dt2: Date) =>
      (dt1.getTime() - dt2.getTime())/1000;

    const today = new Date();
    let dSec = secDiff(new Date(aDead[0]), today);
    let pos: 0|1|2 = dSec > 0 ? 0 : 1;
    if (aDead.length >= 2 && dSec < 0) {  // deadline already passed, then use aDead[1]
      dSec = secDiff(new Date(aDead[1]), today);
      pos = dSec > 0 ? 1 : 2;
    }
    
    const secInDays = 60*60*24;
    const days = Math.trunc(dSec / secInDays);  // integer part
    const { hhmm, ss } = sec2hmso(Math.abs(dSec - days*secInDays), true, true);  // fractional part
    return ({ pos, days, hhmm, ss });
  }

  const [cd, setCd] = useState<ICountdown>(getCountdown());

  useInterval(() => {
    setCd(getCountdown());
  }, secInterval * 1000);

  return (cd);
}

/*=============================================================================
 EventPage.tsx - 

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { Copyright, } from 'ui/ui';
import { useLocaleCtx } from 'ui/locale/LocaleCtx';
import { getLS } from 'utils/useLocalStorage';
import { cLo, cCo, } from 'utils/util';

const posts = [
  {
    title: '第四回 WeWork情報交換会',
    href: 'rsvp',  // temporal solution
    category: { name: 'weworkcom', href: '#' },
    description: '2021年10月29日開催予定。緊急事態宣言の延長措置により8月開催予定のイベントが延期となりました。',
    location: 'リンクスクエア新宿 16F（会議室：プライベート仕様会議室 16J）',
    date: '2021年9月16日',
    datetime: '2021-09-16',
    imageUrl: 'https://locations-api-production.imgix.net/locations/image/1465fb88-576e-11ea-b8b7-1214a1ac73cf/Web_150DPI-20200210_WeWork_Link_Square_Shinjuku_-_Tokyo_013.jpg?auto=format%20compress&fit=crop&q=50&w=1800&h=1013',
    readingTime: '5分',
    author: {
      name: 'WeWork情報交換会事務局',
      href: '#',
      imageUrl: 'https://picsum.photos/id/1/32',
    },
    active: true,
  },
  {
    title: '第三回 WeWork情報交換会',
    href: '#',
    category: { name: 'weworkcom', href: '#' },
    description: '2021年6月25日開催',
    location: 'リンクスクエア新宿 16F（会議室：プライベート仕様会議室 16J）',
    date: '2021年9月16日',
    datetime: '2021-09-16',
    imageUrl: 'https://locations-api-production.imgix.net/locations/image/142ba956-576e-11ea-b8b7-1214a1ac73cf/Web_150DPI-20200210_WeWork_Link_Square_Shinjuku_-_Tokyo_011.jpg?auto=format%20compress&fit=crop&q=50&w=1650&h=928',
    readingTime: '',
    author: {
      name: 'WeWork情報交換会事務局',
      href: '#',
      imageUrl: 'https://picsum.photos/id/0/32',
    },
    active: false,
  },
  {
    title: '第二回 WeWork情報交換会',
    href: '#',
    category: { name: 'weworkcom', href: '#' },
    description: '2021年4月23日開催',
    location: '渋谷スクランブルスクエア',
    date: '2021年9月16日',
    datetime: '2021-09-16',
    imageUrl: 'https://locations-api-production.imgix.net/locations/image/b3970c04-5bee-11ea-83c5-1214a1ac73cf/Web_150DPI-20200213_WeWork_Shibuya_Scramble_Square_-_Tokyo_014.jpg?auto=format%20compress&fit=crop&q=50&w=1650&h=928',
    readingTime: '',
    author: {
      name: 'WeWork情報交換会事務局',
      href: '#',
      imageUrl: 'https://picsum.photos/id/0/32',
    },
    active: false,
  },
]

/**
 * 
 */
function EventList() {
  const { dic } = useLocaleCtx();
  const rsvpPath = getLS('rsvp') || 'weworkcom/home';
  const getHRef = (href: string) => {
    return (href === 'rsvp')
    ? rsvpPath
      ? 'rsvp/' + rsvpPath
      : '#'
    : href;
  }
  return (
    <div {...cLo("relative bg-gray-50 pt-4 pb-20 px-4 sm:px-6 lg:pt-6 lg:pb-28 lg:px-8")}>
      <div {...cLo("relative max-w-7xl mx-auto")}>
        <div {...cLo("text-center")}>
          <h2 {...cLo("text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl")}>
            {dic('menu_event')}
          </h2>
          <p {...cLo("mt-3 max-w-2xl mx-auto text-base text-gray-500 sm:mt-4")}>
            WeWorkコミュニティのイベントリストです。過去開催イベントの記事は現在準備中です。
          </p>
        </div>
        <div {...cLo("mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none")}>
          {posts.map((post) =>
            <div key={post.title} {...cLo("flex flex-col rounded-lg shadow-lg overflow-hidden")}>
              <div className="flex-shrink-0">
                <img className="h-48 w-full object-cover" src={post.imageUrl} alt="" />
              </div>
              <div {...cCo("flex-1 p-6 flex flex-col justify-between",
                post.active, "bg-gray-50 hover:bg-white", "bg-gray-200")}
              >
                <div className="flex-1">
                  <p className="text-sm font-medium text-indigo-600">
                    <a href={post.category.href} className="hover:underline">
                      {post.category.name}
                    </a>
                  </p>
                  <a href={getHRef(post.href)} className="block mt-2">
                    <p className="text-xl font-semibold text-gray-900">{post.title}</p>
                    <p className="mt-3 text-base text-gray-500">{post.description}</p>
                    <p className="mt-3 font-bold text-sm text-blue-800">{post.location}</p>
                  </a>
                </div>
                <div className="mt-6 flex items-center">
                  <div className="flex-shrink-0">
                    <a href={post.author.href}>
                      <span className="sr-only">{post.author.name}</span>
                      <img className="h-10 w-10 rounded-full" src={post.author.imageUrl} alt="" />
                    </a>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-900">
                      <a href={post.author.href} className="hover:underline">
                        {post.author.name}
                      </a>
                    </p>
                    <div className="flex space-x-1 text-sm text-gray-500">
                      <time dateTime={post.datetime}>{post.date}</time>
                      <span aria-hidden="true">&middot;</span>
                      <span>{post.readingTime}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>)}
        </div>
      </div>
    </div>
  )
}

/**
 * 
 * https://images.unsplash.com/photo-1593486544625-13ef2368e43a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2225&q=80
 */
export default function EventPage() {
  // const { dic } = useLocaleCtx();
  return (
    <>
      <div className="relative">
        <img className="w-full object-cover filter dark:blur-sm"
          src="https://github.com/dashgon/dashgon.github.io/blob/master/public/stq/spacetimeq-neon-front.jpg?raw=true"
          alt="event"
        />
      </div>
      <EventList />
      <Copyright />
    </>
  );
}

/*
      <div className="absolute inset-0 font-bold TextShadowB-md text-center text-white mt-2 md:mt-4">
        <h1 className="text-3xl text-blue-300">{dic('coming_soon')}</h1>
      </div>
 */

/*, =============================================================================
 svg.tsx - svg utilities

 - Path Data (paths.json) naming convention: shape_role; group_function
   Examples: bell_noti, moon_dark; ico_error, ico_warn

 (C) 2020 SpacetimeQ INC.
=============================================================================*/
import PATHD from 'asset/paths.json';
import { cLIf, cLo, } from './util';

/**
 * To avoid No Index Signature Error for the bracket (Obj[key])
 */
export type TPathDataKey = keyof typeof PATHD;
export const getPathD = (k: TPathDataKey) => PATHD[k];

/**
 * <path> arguments
 */
export const RULE_EVENODD: React.SVGProps<SVGPathElement> = {
  fillRule: "evenodd",
  clipRule: "evenodd"
};

/**
 * <svg> arguments
 */
export const viewBox = (vLen: number) => ({
  viewBox: `0 0 ${vLen} ${vLen}`
});

/**
 * short-hand for <svg width={len} height={len} viewBox=`0 0 ${vLen} ${vLen}`>
 */
export const squareView = (len: number | string, vLen: number = 24) => ({
  width:  len,
  height: len,
  ...viewBox(vLen)
});

// https://github.com/DefinitelyTyped/DefinitelyTyped/blob/1349b640d4d07f40aa7c1c6931f18e3fbf667f3a/types/react/index.d.ts#L2341
// https://stackoverflow.com/questions/48248512/svg-transform-rotate180-does-not-work-in-safari-11
// transform rotate NOT works for Safari!!
export interface ISvgIconProps extends React.SVGAttributes<SVGElement>, IClassX {
  vLen?:   number;         // viewBox width height
  Path?:   TPathDataKey;   // path data id (either d or path should be given)
  Rotate?: number|string;  // rotate image in degree
};
/**
 * SVG Icon using tailwind classes: default with w-6 h-6
 */
export const SvgIcon = ({  // defaults for heroicons.dev
  className   = "w-6 h-6",  // to change default size, reset className
  classX,                   // Xtra classes NOT to overwirte className
  vLen        = 24,
  d, Path,
  strokeWidth = 2,
  stroke      = strokeWidth ? "currentColor" : "none",
  fill        = strokeWidth ? "none" : "currentColor",
  Rotate,
  ...props   // rest in SVGProps
}: ISvgIconProps) =>
  <svg
    {...cLo(className, classX, !!Rotate && "transition-transform duration-500")}
    {...{stroke, fill, ...viewBox(vLen)}}
    {...(!!Rotate && {transform: `rotate(${Rotate})`})}
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      {...{strokeWidth}}
      d={d || (Path && getPathD(Path))}
    />
  </svg>;

/**
 * SVG Icon using tailwind classes: default with w-full h-full fill-current
 * The size should be set at the outer layer.
 */
export const SvgIconFull = ({
  className   = "w-full h-full fill-current",
  classX,            // Xtra classes NOT to overwirte className
  strokeWidth = 0,
  ...props           // rest
}: ISvgIconProps) =>
  <SvgIcon {...{className, classX, strokeWidth}} {...props} />;

interface ISvgIconToggleProps extends ISvgIconProps {
  cond:   boolean;       // condition for toggling
  d2?:    string;        // second data to be switched with d
  Path2?: TPathDataKey;  // second path to be switched with path
};
/**
 * Two icons toggling with tailwind classes
 */
export const SvgIconToggle = ({  // defaults for heroicons.dev
  classX,
  cond,
  d, Path,   // d, Path should be extracted NOT to be passed to the second SvgIcon
  d2, Path2,
  ...props
}: ISvgIconToggleProps) =>
  <>
    <SvgIcon classX={cLIf(cond,  classX)} {...{d, Path}}      {...props} />
    <SvgIcon classX={cLIf(!cond, classX)} d={d2} Path={Path2} {...props} />
  </>;

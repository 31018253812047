/*=============================================================================
 ctcsSlice.ts - pool of contacts

 - ctc: contacts (additional - name card - user info)
 - ctcs: collection of ctc

 (C) 2021 SpacetimeQ INC.
=============================================================================*/
import { createSlice, createAsyncThunk, createEntityAdapter, createSelector,
} from '@reduxjs/toolkit';
import type { EntityState, Update, } from '@reduxjs/toolkit';
import type { TRootState } from 'app/store';
import type { ICtc, ICtcUp, } from 'models';
import { CTCS } from 'api/apiCommon';
import { fetchCtcAPI, updateCtcAPI, queryCtcByEmail, } from 'api/ctcsAPI';
// for utility functions
import { useSelector } from 'react-redux';
import { store, errorOut, } from 'app/store';

const ctcsAdapter = createEntityAdapter<ICtc>();

interface ICtcState extends EntityState<ICtc> {};
const initialState: ICtcState = ctcsAdapter.getInitialState();

/**
 * fetch ctc info by id
 */
export const fetchCtc = createAsyncThunk<
  ICtc,   // Return type    of the payloadCreator
  TCtcID  // First argument to the payloadCreator
>(
  `${CTCS}/fetchCtc`,      // type
  async (id: TCtcID) => {  // payloadCreator
    return await fetchCtcAPI(id); // ******************* API call
  }
);

/**
 * fetch ctc info by email
 */
export const fetchCtcByEmail = createAsyncThunk<
  ICtc,   // Return type    of the payloadCreator
  string  // First argument to the payloadCreator
>(
  `${CTCS}/fetchCtcByEmail`,     // type
  async (email: string) => {     // payloadCreator
    return await queryCtcByEmail(email); // ******************* API call
  }
);

/**
 * update ctc with *Update<ICtc>*, or create one if not exist
 */
export const updateCtc = createAsyncThunk<
  Update<ICtc>,  // Return type    of the payloadCreator
  ICtcUp         // First argument to the payloadCreator
>(
  `${CTCS}/updateCtc`,      // type
  async (ctc: ICtcUp) => {  // payloadCreator
    return await updateCtcAPI(ctc); // ******************* API call
  }
);

const ctcsSlice = createSlice({
  name: CTCS,
  initialState,
  reducers: {
    ctcAdded:     ctcsAdapter.addOne,
    ctcRemoved:   ctcsAdapter.removeOne,
    ctcUpsertOne: ctcsAdapter.upsertOne,
    ctcUpdateOne: ctcsAdapter.updateOne,
    resetCtcs:    _state => initialState,
  },
  extraReducers: builder => {
    builder
    // fetchCtc ----------------------------------------------
    .addCase(fetchCtc.fulfilled,  (state, action) => { ctcsAdapter.addOne(state, action); })
    .addCase(fetchCtc.rejected,  (_state, action) => { errorOut(action.error); })
    // fetchCtcByEmail ---------------------------------------
    .addCase(fetchCtcByEmail.fulfilled,  (state, action) => { ctcsAdapter.addOne(state, action); })
    .addCase(fetchCtcByEmail.rejected,  (_state, action) => { errorOut(action.error); })
    // updateCtc ---------------------------------------------
    .addCase(updateCtc.fulfilled, (state, action) => { ctcsAdapter.updateOne(state, action); })
    .addCase(updateCtc.rejected, (_state, action) => { errorOut(action.error, true); })
  }
});
export default ctcsSlice.reducer;

export const {
  ctcAdded,
  ctcRemoved,
  ctcUpsertOne,
  ctcUpdateOne,
  resetCtcs
} = ctcsSlice.actions;

/**
 * The entity adapter will contain a getSelectors() function that returns a set of selectors
 * that know how to read the contents of an entity state object.
 * Each selector function will be created using the createSelector function from Reselect,
 * to enable memoizing calculation of the results.
 */
export const {
  selectAll:      selectAllCtcs,  // maps over the state.ids arrays, and returns an array of entities
  selectById:     selectCtcById,
  selectIds:      selectCtcIds,       // state.ids array
  // selectEntities: selectCtcsEntities,  // state.entities lookup table
} = ctcsAdapter.getSelectors((state: TRootState) => state.ctcs);

export const selectCtcByEmail = createSelector(
  [selectAllCtcs, (_state: TRootState, email: string) => email],
  (ctcs, email) => ctcs.filter(ctc => ctc.email === email)[0]  // pick one!
);

//-----------------------------------------------------------------------------
// utility functions
//-----------------------------------------------------------------------------
export const useCtcById = (id: TCtcID) =>
  useSelector((state: TRootState) => selectCtcById(state, id));

export const useCtcByEmail = (email: string) =>
  useSelector((state: TRootState) => selectCtcByEmail(state, email));

export const useAllCtcs = () =>
  useSelector((state: TRootState) => selectAllCtcs(state));

//-----------------------------------------------------------------------------
// utility functions - No hooks
//-----------------------------------------------------------------------------
export const isCtcById = (id: TCtcID) =>
  store.getState().ctcs.ids.includes(id);  // ES6 includes

/**
 * Need to find a way to get a Promise for entities not loaded yet.
 */
export const getCtcsById = (id: TCtcID): Undefinable<ICtc> => { // not a hook
  const ctcs = store.getState().ctcs;
  return ctcs.entities[id];
}

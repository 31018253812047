/*=============================================================================
 UserInfo.tsx - user info utilities

 - onContextMenu cannot be used for touch panel

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { AvatarImg, } from 'ui/Avatars';
import { emailId, } from 'utils/RegEx';
import { datetimeStr, } from 'utils/datetime';
import { cLo, cLoIf, } from 'utils/util';
import { useCurrentUser, useUserById, } from './usersSlice';
import type { IUserEx, } from 'models';
import { PopupMenu, } from 'ui/menus';
import { Open1to1Room } from 'features/chat/Open1to1Room';
import { EditRoomModal } from 'features/chat/EditRoom';
import { SvgIcon, RULE_EVENODD } from 'utils/svg';

export const EmailVerified: IFClassName< Pick<IUserEx, 'emailV'> > = ({ className, emailV }) =>
  <span {...{className}}>{emailV ? '✅' : '❌'}</span>;

/**
 * callback to be called with the clicked uid
 */
export interface IClickUserCallback {
  clickUserCB?: (uid: TUserID) => void;  // click callback
};

/**
 * Users (Contacts or Room Users) list
 * To set overflow-y-scroll need to specify maximum height at the outer layer
 * issue: When overflow-y-scroll, the bottom-most popup menu hidden
 */
export const UsersList: React.FC<{ emptyMsg: string; }> = ({
  emptyMsg, children
}) =>
  <div {...cLo("flex-1 px-2 cursor-pointer py-1")}>
    {children ||
      <p {...cLo("text-sm italic text-center text-blue-500")}>{emptyMsg}</p>}
  </div>;

interface IUserInfoCommonProps extends IClickUserCallback, IClassNameObj {
  bdrClr?:   TClassName;  // border color - tailwind classNames
  on?:       boolean;     // joined or exited
  contacts?: boolean;     // contact list mode?
  selected?: boolean;     // selected in the list
};
interface IUserInfoProps extends IUserInfoCommonProps {
  uid: TUserID;
};
/**
 * User Info with given uid; Just prints uid if failed to get User
 */
export const UserInfo = ({ uid, ...props }: IUserInfoProps) => {
  const user = useUserById(uid);
  return user
  ? <UserInfoByUser {...{user}} {...props} />
  : <div {...cLo(props.className, "font-mono text-3xs dark:text-red-100 text-red-700")}>
      {uid}
    </div>;
}

interface IUserInfoByUserProps extends IUserInfoCommonProps {
  user: IUserEx;
};
/**
 * Show user info given user object.
 * @param clickUserCB will be called with clicked UID
 */
export const UserInfoByUser = ({
  user, className, bdrClr, on, contacts, selected, clickUserCB
}: IUserInfoByUserProps) => {
  const cUid = useCurrentUser()?.uid;
  if (!cUid) {
    console.log("No current user!");
    return null;
  }
  const name = user.name || emailId(user.email);
  const handleClick: TDivClickHandler = (_e) => {
    // DO NOT e.preventDefault(); if there is another button to process the click
    clickUserCB?.(user.uid);
  }
  // const handleContextMenu: TDivClickHandler = (e) => {
  //   e.preventDefault();
  //   console.log(e.clientX, e.clientY);
  // }
  return (
    <div {...cLo("relative flex items-center justify-between px-0.5 py-2 cursor-pointer",
        "border-b border-r rounded-br-lg dark:border-gray-600 border-gray-300",
        "dark:hover:bg-gray-800 hover:bg-gray-300", className)}
      onClick={handleClick}
    >
      <AvatarImg
        url={user.photoURL}
        className="w-12 h-12 ml-1"
        {...{bdrClr, on, name}}
      />
      <div {...cLo("flex-auto min-w-0 ml-3 group-hover:block",
          "whitespace-nowrap overflow-hidden")}
      >
        <p {...cLo("text-sm leading-none",
          !!user.name
            ? "font-mono"
            : "font-serif italic",
          (user.uid === cUid)
            ? "dark:text-purple-300 text-purple-600"
            : "dark:text-blue-300 text-blue-600")}
        >
          {name}
        </p>
        <p className="py-1 font-extralight text-xs dark:text-gray-100 text-gray-900">
          <EmailVerified {...cLo("mr-1 text-3xs")} emailV={user.emailV} />
          {user.email}
        </p>
        <div className="flex items-center">
          <p {...cLoIf(user.city && (user.city !== 'city'), "text-2xs px-1 rounded-sm",
            "dark:text-gray-100 dark:bg-gray-500",
            "text-white bg-gray-400")}
          >
            {user.city}
          </p>
          <p {...cLoIf(user.signInAt, "ml-2 text-3xs dark:text-green-400 text-green-700")}>
            {datetimeStr(user.signInAt)}
          </p>
        </div>
      </div>
      <SvgIcon
        Path="ico_info"
        {...RULE_EVENODD}
        {...cLoIf(selected, "w-8 h-8 fill-current text-blue-600")}
        strokeWidth={0}
      />
      {contacts &&
      <PopupMenu {...cLo("w-6 shadow-md")}
          title={name}
          menuItems={[
            {
              item: <Open1to1Room uids={[cUid, user.uid]} />,
            },
            {
              item: <EditRoomModal
                      {...cLo("focus:outline-none cursor-pointer")}
                      label="New Channel"
                      uids={[cUid, user.uid]}
                    />,
            },
          ]}
          Path="dots_vertical"
        />}
    </div>
  );
}

/*=============================================================================
 roomsSlice.ts - rooms data

 - similar pattern with usersSlice

 (C) 2021 SpacetimeQ INC.
=============================================================================*/
import { createSlice, createAsyncThunk, createEntityAdapter,
} from '@reduxjs/toolkit';
import type { EntityState, Update, } from '@reduxjs/toolkit';
import type { TRootState, } from 'app/store';
import type { IRoom, IRoomCreate, IRoomUp, } from 'models';
import { ROOMS } from 'api/apiCommon';
import { fetchRoomAPI, createRoomAPI,
  updateRoomAPI, updateRoomUsersAPI, } from 'api/roomsAPI';
import type { IUpdateRoomUsersProps, } from 'api/roomsAPI';
// for utility functions
import { useSelector } from 'react-redux';
import { getRoomId, } from 'features/msgs/msgsSlice';
import { selectRoomId, } from 'features/msgs/msgsSlice';
import { store, errorOut, } from 'app/store';

type TRoomID = IRoom['id'];  // indexed access type

const roomsAdapter = createEntityAdapter<IRoom>();

interface IRoomState extends EntityState<IRoom> { };
const initialState: IRoomState = roomsAdapter.getInitialState();

/**
 * fetch room info by id (document id = room.id)
 */
export const fetchRoom = createAsyncThunk<
  IRoom,    // Return type    of the payloadCreator
  TRoomID   // First argument to the payloadCreator
>(
  `${ROOMS}/fetchRoom`,        // type
  async (id: TRoomID) => {  // payloadCreator
    return await fetchRoomAPI(id); // ******************* API call
  }
);

/**
 * create Room by id (document id = room.id)
 */
export const createRoom = createAsyncThunk<
  IRoom,       // Return type    of the payloadCreator
  IRoomCreate  // First argument to the payloadCreator
>(
  `${ROOMS}/createRoom`,       // type
  async (room: IRoomCreate) => {  // payloadCreator
    return await createRoomAPI(room); // ******************* API call
  }
);

/**
 * update room with *Update<IRoom>*
 */
export const updateRoom = createAsyncThunk<
  Update<IRoom>,  // Return type    of the payloadCreator
  IRoomUp         // First argument to the payloadCreator
>(
  `${ROOMS}/updateRoom`,  // type
  async (room: IRoomUp) => { // payloadCreator
    return await updateRoomAPI(room); // ******************* API call
  }
);

/**
 * update Users with *Update<IRoom>*
 */
export const updateRoomUsers = createAsyncThunk<
  Update<IRoom>,         // Return type    of the payloadCreator
  IUpdateRoomUsersProps  // First argument to the payloadCreator
>(
  `${ROOMS}/updateRoomUsers`,  // type
  async (props: IUpdateRoomUsersProps) => { // payloadCreator
    return await updateRoomUsersAPI(props); // ******************* API call
  }
);

const roomsSlice = createSlice({
  name: ROOMS,
  initialState,
  //-----------------------------------------------------------------------------
  // reducers
  //-----------------------------------------------------------------------------
  reducers: {
    roomAdded:     roomsAdapter.addOne,
    roomRemoved:   roomsAdapter.removeOne,
    roomUpsertOne: roomsAdapter.upsertOne,
    roomUpdateOne: roomsAdapter.updateOne,
    resetRooms:    _state => initialState,
  },
  extraReducers: builder => {
    builder
    // fetchRoom ----------------------------------------------
    .addCase(fetchRoom.fulfilled,  (state, action) => { roomsAdapter.addOne(state, action); })
    .addCase(fetchRoom.rejected,  (_state, action) => { errorOut(action.error); })
    // createRoom ---------------------------------------------
    .addCase(createRoom.fulfilled, (state, action) => { roomsAdapter.addOne(state, action); })
    .addCase(createRoom.rejected, (_state, action) => { errorOut(action.error, true); })
    // updateRoom ---------------------------------------------
    .addCase(updateRoom.fulfilled, (state, action) => { roomsAdapter.updateOne(state, action); })
    .addCase(updateRoom.rejected, (_state, action) => { errorOut(action.error, true); })
    // updateRoomUsers ----------------------------------------
    .addCase(updateRoomUsers.fulfilled, (state, action) => { roomsAdapter.updateOne(state, action); })
    .addCase(updateRoomUsers.rejected, (_state, action) => { errorOut(action.error, true); })
  }
});
export default roomsSlice.reducer;

export const {
  roomAdded,
  roomRemoved,
  roomUpsertOne,
  roomUpdateOne,
  resetRooms,
} = roomsSlice.actions;

export const {
  selectAll:      selectAllRooms,
  selectById:     selectRoomById,
  selectIds:      selectRoomIds,
  // selectEntities: selectRoomEntities,  // state.entities lookup table
} = roomsAdapter.getSelectors((state: TRootState) => state.rooms);

//-----------------------------------------------------------------------------
// utility functions
//-----------------------------------------------------------------------------
/**
 * Since we cannot use hooks conditionally, pass an invalid id for the null case.
 */
export const useRoomById = (roomId: Nullable<TRoomID>) =>
  useSelector((state: TRootState) => selectRoomById(state, roomId || -1));

export const useCurrentRoom = () => {
  const roomId = useSelector( selectRoomId );
  // const roomId = getRoomId();  // from msgsSlice
  return useSelector((state: TRootState) => selectRoomById(state, roomId || -1));
}

//-----------------------------------------------------------------------------
// utility functions - no hooks
//-----------------------------------------------------------------------------
/**
 * The order (index in the {room}.users array) of the user: 0..n
 */
export const getUserOrder = (uid: TUserID) => {
  const roomId = getRoomId();  // from msgsSlice
  if (!roomId)
    return 0;
  const room = store.getState().rooms.entities[roomId];
  if (!room)
    return 0;
  return room.users.indexOf(uid);
}

export const getRoomById = (id: TRoomID): Undefinable<IRoom> => { // not a hook
  const rooms = store.getState().rooms;
  return rooms.entities[id];
}

export const isRoomById = (id: TRoomID) =>
  store.getState().rooms.ids.includes(id);  // ES6 includes

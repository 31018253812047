/*=============================================================================
 TermsOfUse.tsx - Terms of Use, Privacy Policy, etc.

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { useRef, useState, useEffect, } from 'react';
import { cLo, } from 'utils/util';
import { CRtoHTML, } from 'ui/ui';
import { useLocaleCtx, } from 'ui/locale/LocaleCtx';
// for ModalDialog
// react-aria ------------------------------------------------------------------------------------
import { OverlayContainer } from '@react-aria/overlays';
import { useOverlayTriggerState } from '@react-stately/overlays';
import { useLink, } from '@react-aria/link';
import type { AriaLinkOptions } from '@react-aria/link';
import { useButton }  from '@react-aria/button';
// react-aria ------------------------------------------------------------------------------------
import { ModalDialog } from 'ui/ModalDialog';

export const TermsOfUse = ({ accept }: { accept: string; }) => {
  const { locale, dic } = useLocaleCtx();
  const terms   = { href: `./text/tou_jp.txt`,     title: dic('tou_title') };
  const privacy = { href: `./text/privacy_jp.txt`, title: dic('privacy_title') }
  return (
    <span {...cLo("ml-2 align-middle font-thin text-xs dark:text-gray-100 text-black")}>
      {(locale === 'en')
        ? "I " + accept + " the "
        : ''
      }
      <ALinkModal {...terms}>{terms.title}</ALinkModal> &amp;{' '}
      <ALinkModal {...privacy}>{privacy.title}</ALinkModal>
      {(locale === 'en')
        ? '.'
        : ` ${accept}`
      }
    </span>
  );
}

type Thref = Pick< React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href' >;
interface IALinkModalProps extends AriaLinkOptions, Thref {
  title: string;
};
const ALinkModal: React.FC<IALinkModalProps> = (props) => {
  const state = useOverlayTriggerState({});
  const openLinkRef = useRef<HTMLAnchorElement>(null);
  const closeBtnRef = useRef<HTMLButtonElement>(null);
  const { dic } = useLocaleCtx();
  
  const { linkProps:   openLinkProps } = useLink({ ...props, onPress: () => state.open() }, openLinkRef);
  const { buttonProps: closeBtnProps } = useButton( { onPress: () => state.close() }, closeBtnRef);

  const [text, setText] = useState<string>('');
  useEffect(() => {
    fetchText(props.href)
      .then(text => setText(text));
  }, [props.href]);

  return (
    <>
      <a
        ref={openLinkRef}
        {...openLinkProps}
        className="font-bold text-blue-400 hover:text-blue-300 cursor-pointer"
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.children}
      </a>
      {state.isOpen && (
        <OverlayContainer>
          <ModalDialog
            title={props.title}
            clsDialog="bg-gray-100 rounded-lg"
            isOpen
            onClose={state.close}
            isDismissable
          >
            <div {...cLo("max-w-md max-h-96 overflow-y-scroll p-4",
              "border border-gray-300")}
            >
              <CRtoHTML {...{text}}
                {...cLo("text-sm text-gray-700")}
              />
              <div {...cLo("flex justify-center")}>
                <button
                  ref={closeBtnRef}
                  {...closeBtnProps}
                  {...cLo("flex justify-center w-full my-2 p-2 bg-blue-700 text-white",
                    "rounded-md shadow-lg hover:bg-blue-800")}
                >
                  {dic('ok')}
                </button>
              </div>
            </div>
          </ModalDialog>
        </OverlayContainer>
      )}
    </>
  );
}

const fetchText = async (path: stringU) => {
  if (path) {
    const res = await fetch(path);
    if (res.ok) {
      return res.text();
    } else {
      console.error("fetchText:Fetch error!", res.status, res.statusText);
    }
  }
  return '';
}

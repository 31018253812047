/*=============================================================================
 App.tsx - 

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { DarkThemeProvider } from 'ui/darkThemeCtx';
import { consoleLogo } from 'ui/ui';
import Layout from 'ui/layout/Layout';
import 'styles/App.scss';

consoleLogo("STQEvent");

export const VERSION_APP = 6;  // 2021-11-05
// export const VERSION_APP = 5;  // 2021-10-15
export const VERSION_RSVP = 4;  // 2021-10-26 16:00
// export const VERSION_RSVP = 3;  // 2021-10-15 16:00
// const VER_LATEST = 2;  // 2: 2021-09-16 16:30
// const VER_LATEST = 1;  // Application version: update to invalidate cache, 1: 2021-08-18 16:12
export const VERSION_STR = `β${VERSION_APP}.${VERSION_RSVP}`;

function App() {
  return (
    <DarkThemeProvider>
      <div className="App">
        <Layout />
      </div>
    </DarkThemeProvider>
  );
}

export default App;

/*=============================================================================
 windows.ts - window utilities

 - Use this pattern to set max-width
   const { w: maxWidth } = useWindowSize();
   <div style={{ maxWidth }}>

 - useLayoutEffect instead of useEffect

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { useState, useLayoutEffect, } from 'react';

const RESIZE = 'resize';

interface IWidthHeight {
  w: number;
  h: number;
};
/**
 * reacts to both width and height changes
 * aspect ration = w / h; Portrait (asp <= 1) Landscape (asp > 1)
 */
export const useWindowSize = () => {
  const [winSize, setWinSize] = useState<IWidthHeight>({
    w: window.innerWidth,
    h: window.innerHeight
  });

  const handleWindowResize = () => setWinSize({
    w: window.innerWidth,
    h: window.innerHeight
  });

  useLayoutEffect(() => {
    window.addEventListener(RESIZE, handleWindowResize);
    return () => window.removeEventListener(RESIZE, handleWindowResize);
  }, []);

  return winSize;
}


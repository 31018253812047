/*=============================================================================
 RsvpForm.tsx - 

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { useState, useEffect, } from 'react';
import { cL, cLo, cCo, } from 'utils/util';
import { ButtonC, } from 'ui/ui';
import { LabeledRadio, } from 'ui/inputForms';
import { sendFetchJson, EmailInfo, } from './RSVP';
import { ModalDlg, } from 'ui/ModalDlgTw';
import type { IAnswersBase, TTernary, IRsvpFormParams, } from "./RsvpForm";
import { SEL_ABSENCE, SEL_ATTEND, SEL_UNDECIDED, initAnsBase, ansBaseNames,
  MemberCard, SelectMainChoices, parseResponse, } from './RsvpForm';

const MAX_TEXTINPUT = 200;

/**
 * Answer sheet in JSON
 */
interface IAnswers extends IAnswersBase {
  p:  string;   // place, should have been TBinary
};
const initAns: IAnswers = {
  ...initAnsBase,
  p:  "0",  // place (radio)
};
const ansNames = {
  ...ansBaseNames,
  p0: "オンラインで参加する",
  p1: "WeWork OGから参加する",
};

const AnswerSheet = ({ ans }: { ans: IAnswers; }) => {
  const twSec = "text-left border border-gray-500 p-4 shadow-md";
  const twM = cLo("font-bold text-xl");  // Main
  const twS = cLo("text-base ml-8");     // Sub
  const Text: React.FC<IClassNameObj> = ({ className, children }) => children
    ? <span {...cLo("text-indigo-600 bg-yellow-100 rounded-full p-1", className)}>
        {children}
      </span>
    : null;
  return (
    <>
      <div {...cLo("text-6xl text-bold text-center py-4 my-2",
        "bg-gray-700 rounded-full shadow-md")}
      >
        {(ans.a === SEL_ATTEND)
        ? <span {...cLo("text-green-200")}>{ansNames.a1}</span>
          : (ans.a === SEL_ABSENCE)
            ? <span {...cLo("text-red-200")}>{ansNames.a0}</span>
            : <span {...cLo("text-yellow-200")}>{ansNames.aT}</span>}
      </div>
      {(ans.a === SEL_UNDECIDED) &&
        <section {...cLo(twSec)}>
          {ansNames.aT}：<Text>{ans.aT}</Text>
        </section>}
      <section {...cLo(twSec,
        (ans.a === SEL_ABSENCE)   && "opacity-10 bg-gray-400",
        (ans.a === SEL_UNDECIDED) && "opacity-20 bg-gray-400")}
      >
        <div {...twM}>{ansNames[ans.p === "1" ? "p1" : "p0"]}</div>
      </section>
      <section {...cLo(twSec)}>
        <div {...twS}>{ansNames.rT}：<Text>{ans.rT}</Text></div>
      </section>
    </>
  );
}
/**
 * Form input part of RSVP
 */
export const RsvpForm = ({
  grp, tgt,
  srvAct, setSrvAct,
  onClose, dead
}: IRsvpFormParams) => {
  const [open, setOpen] = useState(false);  // ModalConfirm
  const [ans,  setAns]  = useState<IAnswers>(initAns);
  const { cho, eventTitle, } = srvAct || {};

  console.log("RsvpForm");
  useEffect(() => {
    if (!cho) {
      console.log("No cho!");
      return;
    }
    const res = parseResponse(cho);
    console.log("RsvpForm:useEffect", cho, res);
    if (res?.choice) {
      setAns(res.choice);
    }
  }, [cho]);

  const handleOK = async () => {
    console.log("handleOK", ans);
    setOpen(true);
  }

  const onModalDlgCB = (ok: boolean) => {
    if (ok) {
      sendFetchJson(grp, tgt, setSrvAct, { choice: ans });
      onClose(ok);
    }
  }

  return (!srvAct || dead)
  ? <div {...cLo("text-left items-center text-2xl my-20 mx-10")}>
      {srvAct
      ? srvAct.cho
        ? <div>
            <p {...cLo("text-center")}>ご回答</p>
            <AnswerSheet {...{ans}} />
          </div>
        : <p {...cLo("text-center text-red-400 my-10")}>回答の〆切りが過ぎています。</p>
      : <div {...cLo("border-4 border-gray-200 rounded-xl p-10")}>
          出欠回答機能を利用するには事務局からの招待が必要です。<br/>
          お問い合わせ（メール）でイベントへの参加希望をご連絡ください。
        </div>}
      <EmailInfo {...cLo("mt-10")} />
    </div>
  : <div {...cLo("Sq_h-max overflow-y-scroll")}>
      <h1 {...cLo("font-bold text-lg text-center p-2 text-gray-100 bg-gray-600")}>
        出欠確認フォーム
      </h1>
      <div {...cLo("p-2 bg-gray-300 font-bold text-center")}>
        イベント名：{eventTitle}
      </div>

      <div {...cLo("m-4 shadow-md border-2 border-gray-500")}>
        <MemberCard {...{srvAct}} />
      </div>

      <div {...cLo("mx-4 my-1 p-2 bg-gray-100 rounded-lg shadow-md border border-gray-400")}>
        <FormChoices {...{ans, setAns}} />
      </div>

      <div {...cLo("flex flex-row justify-center mt-4")}>
        <ButtonC
          classX={cL("BC_Green p-6 m-2 w-40 text-base text-lg rounded-md TextShadowB")}
          onPress={() => handleOK()}
        >
          回答
        </ButtonC>
        <ButtonC
          classX={cL("BC_Red p-6 m-2 w-40 text-base text-lg rounded-md TextShadowB")}
          onPress={() => onClose(false)}
        >
         キャンセル
        </ButtonC>
      </div>
      <ModalDlg {...{open, setOpen}}
        title="ご回答"
        ok="送信"
        cancel="キャンセル"
        onOkCancel={onModalDlgCB}
      >
        <AnswerSheet {...{ans}} />
      </ModalDlg>
    </div>;
}

interface IFormChoicesProps {
  ans:    IAnswers;
  setAns: (ans: IAnswers) => void;  // callback
};
/**
 * Let the input handlers in the form set its parent component's states (ans) by calling
 * the callback (setAns).
 * All controlled components in React.
 */
const FormChoices = ({ans, setAns}: IFormChoicesProps) => {

  // main choice states are kept in the child SelectMainChoices component.
  // Don't keep duplicate states here. Just handover callback to sync.
  const setSelect = (sel: TTernary) => {
    setAns({ ...ans, a: sel });
  }
  const updateVal = (e: React.ChangeEvent<HTMLInputElement>, key: keyof IAnswers) => {
    console.log("updateVal:", e.target.value);
    setAns({
      ...ans,
      [key]: e.target.value.length < MAX_TEXTINPUT
        ? e.target.value
        : e.target.value.substring(0, MAX_TEXTINPUT)
    });
  }

  // onFormSubmit will be called when ENTER key pressed
  // only when <input type="submit" value="" /> put inside.
  // Let's call parent's handleOK
  const onFormSubmit: React.FormEventHandler = (ev) => {
    ev.preventDefault();  // to prevent page reload on form submit
  }

  // if (select === 1 && ach.ch.includes(true)) {
  //   setAch({ ch: new Array<boolean>(NUM_CHOICES).fill(false), text: ach.text });
  // }
  const twTLabel = cLo("text-sm sm:text-base text-white text-center TextShadowB");
  const twTInput = cLo("flex flex-col bg-gray-500 pb-2 px-1 my-2 rounded-lg");

  return (
    <form
      {...cLo("flex flex-col w-full")}
      onSubmit={ev => onFormSubmit(ev)}
    >
      <SelectMainChoices {...cLo("my-2")} select={ans.a} {...{setSelect}} />
      {(ans.a === SEL_UNDECIDED) &&
        <div {...twTInput}>
          <span {...twTLabel}>
            {ansNames.aT}
          </span>
          <input
            type="text"
            placeholder={ansNames.aT}
            {...cLo("w-full border border-gray-400")}
            value={ans.aT}
            onChange={e => updateVal(e, 'aT')}
            disabled={ans.a !== SEL_UNDECIDED}
          />
        </div>
      }
      <div {...cLo("bg-gray-400 rounded-lg",
          (ans.a === SEL_ABSENCE)   && "opacity-20",
          (ans.a === SEL_UNDECIDED) && "opacity-50")}
      >
        <p {...cLo("text-sm text-center")}>イベントへの出席について確認させてください。</p>
        <SelectSubChoices {...{ans, setAns}} />
      </div>

      <div {...twTInput}>
        <span {...twTLabel}>
          {ansNames.rT}：
          <span {...cLo("text-xs")}>
            例）本イベントへの参加を推薦したい方がいる
          </span>
        </span>
        <input
          type="text"
          placeholder={ansNames.rT}
          {...cLo("w-full border border-gray-400")}
          value={ans.rT}
          onChange={e => updateVal(e, 'rT')}
        />
      </div>

      <input type="submit" value=" " {...cLo("bg-gray-200")} />
    </form>
  );
}

const SelectSubChoices = ({ans, setAns}: IFormChoicesProps) => {
  const twSection = cLo("bg-gray-50 border border-gray-400 p-2");
  const LabelCmt: React.FC<IClassNameObj> = ({ className, children }) =>
     <div {...cLo("text-sm text-indigo-600", className)}>{children}</div>;
  const disabled = { disabled: ans.a !== SEL_ATTEND };
  return (
    <section {...twSection}>
      <LabeledRadio
        name="ans_p"
        value="0"
        checked={ans.p === "0"}
        {...disabled}
        onChange={e => setAns({ ...ans, p: e.target.value })}
      >
        <span {...cCo("ml-2", ans.p === "0", "font-bold")}>{ansNames.p0}</span>
      </LabeledRadio>
      <div {...cLo("pt-2")} />
      <LabeledRadio
        type="radio"
        name="ans_p"
        value="1"
        checked={ans.p === "1"}
        {...disabled}
        onChange={e => setAns({ ...ans, p: e.target.value })}
      >
        <span {...cCo("ml-2", ans.p === "1", "font-bold")}>{ansNames.p1}</span>
      </LabeledRadio>
      <LabelCmt {...cLo("ml-4")}>
        オーシャンゲートみなとみらい （会議室：会議室 10J）
      </LabelCmt>
    </section>
  );
}

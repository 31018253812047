/*=============================================================================
 authSlice.ts - authentication data

 (C) 2020 SpacetimeQ INC.
=============================================================================*/
import { createSlice, createAsyncThunk, } from '@reduxjs/toolkit';
import type { PayloadAction, } from '@reduxjs/toolkit';
import type { TRootState, } from 'app/store';
import { AUTH, } from 'api/apiCommon';
import type { TAuthError, } from 'api/apiCommon';
import { authAPI, } from 'api/authAPI';
import type { TAuthProps, } from 'api/authAPI';
import type { TUser0 } from 'models';
import { store } from 'app/store';

export interface IAuthState {
  user:      TUser0;
  pending:   boolean;
  error?:    TAuthError;
};

const initialState: IAuthState = {
  user:    null,
  pending: false,
};

/**
 * Login Request
 */
export const apiReq = createAsyncThunk<
  TUser0,     // Return type    of the payloadCreator
  TAuthProps  // First argument to the payloadCreator
>(
  `${AUTH}/apiReq`,               // type
  async (ap: TAuthProps) => {  // payloadCreator
    console.log("apiReq:", ap);
    return await authAPI(ap);  // calls API wrapper
  }
);

const authSlice = createSlice({
  name: AUTH,
  initialState,
  reducers: {
    stateChanged(state, action: PayloadAction<TUser0>) {
      state.user    = action.payload;
      state.pending = false;
      state.error   = undefined;
    },
  },
  extraReducers: builder => {
    builder
    .addCase(apiReq.pending, (state) => {
      state.pending = true;
      state.error   = undefined;
    })
    .addCase(apiReq.fulfilled, (state) => {
      // state.user = action.payload;  // let it be handled on stateChanged
      state.pending = false;
    })
    .addCase(apiReq.rejected, (state, action) => {
      state.pending = false;
      state.error = action.error as TAuthError;
      console.error("Login Error:", action.error.code, action.error.message);
    })
  }
});

export default authSlice.reducer;

export const {
  stateChanged,
} = authSlice.actions;
export const selectAuth = (state: TRootState) => state.auth;

//-----------------------------------------------------------------------------
// utility functions - Not a hook
//-----------------------------------------------------------------------------
export const getCurrentUser = () =>  // not a hook
  store.getState().auth.user;


/*=============================================================================
 AuthRoute - private route with access levels
 DEPENDENCY: redux store to get the authentication status

 (C) 2020 SpacetimeQ INC.
=============================================================================*/
import { Route, Redirect, useLocation, } from 'react-router-dom';
import type { RouteProps, } from 'react-router-dom';

export type TAccessLevel =  // Access Level required for the page
    0   // public
  | 1   // login
  | 2;  // admin (not implemented yet)
export interface IAuthRouteProps extends RouteProps {
  access?: TAccessLevel;   // required access level
  authLv:  TAccessLevel;   // current user's authorized level: 0 for logged-out
}

/**
 * return access level for the logged in condition
 */
export const accessLevel = (logged: boolean): TAccessLevel => logged ? 1 : 0;

/**
 * calls appropriate Route according to the required access level
 * render props passes three route props: match, location, and history
 */
export const ARoute = ({
  access = 0, // required access level of the destination page
  authLv,     // current authentication level of the user who is requesting the page
  children,   // target page
  ...props    // arguments to react-dom <Route>
}: IAuthRouteProps) => {
  return (
    <Route
      {...props}
      render={ ({ location }) => (authLv >= access)
        ? children
        : <Redirect to={{ pathname: "/auth", state: { from: location } }} />
      }
    />
  );
}

/**
 * FromPath (original destination path) before redirected to the auth path
 */
export const useFromPath = () => {
  const location = useLocation<{ from: { pathname: string; } }>();
  return (location && location.state) ? location.state.from.pathname : null;
}

/**
 * Redirect to From (original destination) after getting required authentication
 * You can't use any of the hooks from within the same component that puts the Router into the tree.
 */
export const AuthRedirectToFrom = ({ isUser }: { isUser: boolean }) => {
  const fromPath = useFromPath();

  if (!fromPath || !isUser)
    return null;
  console.log("AuthRedirectToFrom:", fromPath);
  return <Redirect to={{ pathname: fromPath }} />;
}

// A wrapper for <Route> that redirects to the login screen if you're not yet authenticated.
// location.state is set only when Redirected to the "/login" path
// https://github.com/ReactTraining/history/blob/master/docs/api-reference.md#location.state
/*
export const PrivateRoute = ({ children, ...rest }: RouteProps) => {
  // const { auth } = useStore().getState();
  // const auth = useSelector((state: TRootState) => state.auth);
  // const auth = useSelector((state: ReturnType<typeof store.getState>) => state.auth);
  // console.log("PrivateRoute:", auth);
  return (
    <Route
      {...rest}
      render={ ({ location }) => loggedin
        ? children
        : <Redirect to={{ pathname: "/auth", state: { from: location } }} />
      }
    />
  );
}
*/

/*=============================================================================
 common-ui.tsx - Common UIs in React

 - [2021-02-26] React Aria, toggleButton isSelected, isPressed
 - ../node_modules/@react-types/button/src/index.d.ts
 - Svg rotate not works for safari!

 (C) 2020 SpacetimeQ INC.
=============================================================================*/
import { useState, useRef } from 'react';
import { cL, cLo, cLoIf, isSafari, replaceCRtoHTML, } from 'utils/util';
import { RULE_EVENODD, viewBox, getPathD, SvgIcon, SvgIconToggle } from 'utils/svg';
import type { TPathDataKey, } from 'utils/svg';
import { LocaleFlag, } from 'ui/locale/selectLocales';
// react-aria ------------------------------------------------------------------------------------
import { useToggleState, } from '@react-stately/toggle';
import { useButton, useToggleButton, } from '@react-aria/button';
import type { AriaButtonProps, AriaToggleButtonProps, } from '@react-types/button';
// react-aria ------------------------------------------------------------------------------------

export const SpacetimeQ = () =>
  <>
    <span className="dark:text-blue-400 text-blue-700">Space</span>
    <span className="dark:text-green-400 text-green-700">time</span>
    <span className="dark:text-red-400 text-red-700">Q</span>
  </>;

export const Copyright: IFClassName = ({
  className = "mt-2 mr-2 text-2xs text-right"
}) =>
  <p {...cLo("font-serif italic tracking-widest", className)}>
    <span className="dark:text-gray-200 text-gray-600">(C) {new Date().getFullYear()} </span>
    <SpacetimeQ />
    <span className="dark:text-gray-200 text-gray-600"> INC <LocaleFlag /></span>
  </p>;

export const consoleLogo = (
  title:   string,
  contact: string = "Q@spacetimeq.com"
) => {
  const BGC  = 'background:#ffffe0;';
  const C_R  = `color:red;${BGC}`;
  const C_G  = `color:green;${BGC}`;
  const C_B  = `color:blue;${BGC}`;
  const C_B2 = 'color:royalblue;';
  const C_C  = 'font-family:monospace;color:cyan;';
  const C_N  = 'color:darkgray';
// ASCII ART: http://patorjk.com/software/taag/#p=display&f=Graffiti&t=Type%20Something%20
  console.info("%c╔═╗┌─┐┌─┐┌─┐%c┌─┐┌┬┐┬┌┬┐┌─┐%c╔═╗  %c" + title + " - contact:%c" + contact + '%c',
              C_B,          C_G,           C_R,   C_N,                      C_C,             C_N);
  console.info("%c╚═╗├─┘├─┤│  %c├┤  │ ││││├┤ %c║═╬╗ %c" + Date() + '%c',
              C_B,          C_G,           C_R,   C_B2,           C_N);
  console.info("%c╚═╝┴  ┴ ┴└─┘%c└─┘ ┴ ┴┴ ┴└─┘%c╚═╝╚ %c(C) %cSpace%ctime%cQ%c INC, %cΓΝΩΘΙ ΣΑΥΤΟΝ%c",
              C_B,          C_G,           C_R,    C_N, C_B,   C_G, C_R,C_N,     C_B2,        C_N);
};

const twButtonPressed = (isPressed: boolean) => isPressed && "border border-red-400";

/**
 * Button Custom
 */
export const ButtonC: IFClassName<AriaButtonProps & IClassX> = ({
  className: cn,
  classX = "BC_Def p-2 m-2 text-base rounded-md",
  ...props
}) => {
  const ref = useRef<HTMLButtonElement>(null);
  const { buttonProps, isPressed } = useButton(props, ref);
  return (
    <button {...{ref}}
      {...buttonProps}
      {...cLo("BtnC", classX, cn, twButtonPressed(isPressed))}
    >
      {props.children}
    </button>
  );
}

type TOpVariant = 0|5|10|20|25|30|40|50|60|70|75|80|90|95|100;
export type THideAttr =
  | "hidden"
  | "invisible"
  | `opacity-${TOpVariant}`
  | "Ani";
/**
 * Extract the behavior to close(hide) on click as a hook
 * When you need to keep the layout and just hide the window, use "invisible"
 * - Dependencies: .Ani_dialog / .Ani_dialog_close in App.scss
 * Usage) const { display, handleClose } = useClose("opacity-50");
 * <div {...cLo("...", display)} onClick={handleClose} >
 */
export const useClose = (ha: THideAttr = "Ani") => {
  const [close, setClose] = useState(false);
  const handleClose = () => { setClose(!close); }
  return ({
    hide: (ha === "Ani")
    ? `Ani_dialog${close ? "_close" : ""}`  // css class
    : (close ? ha : ""),
    handleClose
  });
}

export type TIconKindColors = { [K in TMessageKind]: stringU };
interface IIconButtonProps extends IClassNameObj, AriaButtonProps {
  kind: TMessageKind;
};
/**
 * IconButton on the dialog; role="button" shows cursor-pointer
 */
export const IconButton = ({
  className: cn = "h-10 w-10 border-4",  // add height, width, text and border colors
  kind,
  ...props
}: IIconButtonProps) => {
  const colors: TIconKindColors = {
    ERROR: cL("text-red-500 border-red-500 hover:text-red-700 hover:border-red-700",
         "dark:text-yellow-400 dark:border-yellow-400 dark:hover:text-white dark:hover:border-white"),
    OK:    cL("text-green-500 border-green-500 hover:text-green-700 hover:border-green-700",
         "dark:text-white dark:border-white dark:hover:text-white dark:hover:border-white"),
    WARN:  cL("text-yellow-500 border-yellow-500 hover:text-yellow-700 hover:border-yellow-700",
         "dark:text-white dark:border-white dark:hover:text-white dark:hover:border-white"),
    INFO:  cL("text-blue-500 border-blue-500 hover:text-blue-700 hover:border-blue-700",
         "dark:text-white dark:border-white dark:hover:text-white dark:hover:border-white"),
  };
  const ref = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton(props, ref);
  return (
    <button
      {...{ref}}
      {...buttonProps}
      {...cLo("flex items-center justify-center flex-shrink-0 rounded-full hover:shadow-md",
        "Btn_ani", colors[kind], cn)}
    >
      <svg className="w-6 h-6 fill-current" {...viewBox(20)}>
        <path {...RULE_EVENODD} d={getPathD(("ico_" + kind.toLowerCase()) as TPathDataKey)} />
      </svg>
    </button>
  );
}
/* &times; */

/**
 * Close Button
 * Default is absolute top right position.
 */
export const CloseButton: IFClassName<AriaButtonProps> = ({
  className = "absolute top-0 right-0 mt-1 mr-1 h-4 w-4 border",
  ...props
}) =>
  <IconButton
    kind='ERROR'
    {...props}
    {...cLo(className, "opacity-50 hover:opacity-100")}
  />;

/**
 * top right close button for a modal dialog
 * Be sure to set a 'relative' at the outer div
 */
export const AriaCloseButton = ({
  className = "absolute top-0 right-0 m-1",
  clsSize   = "w-4",
  closeBtnRef, closeBtnProps
}: {
  closeBtnRef:   React.RefObject<HTMLButtonElement>;
  closeBtnProps: AriaButtonProps;
  clsSize?:      TClassName;
} & IClassNameObj) =>
  <button ref={closeBtnRef}
    {...closeBtnProps}
    {...cLo("flex items-center justify-center flex-shrink-0 rounded-full hover:shadow-md",
      "text-red-500 border-red-500 hover:text-red-400 hover:border-red-700",
      "bg-gray-500 Btn_ani", className)}
  >
    <SvgIcon {...cLo(clsSize)} Path="ico_error" strokeWidth={0} vLen={20} />
  </button>;

export const SpinningCircle: IFClassName = ({
  className: cn = "h-10 w-10 text-white"
}) =>
  <svg
    {...cLo("animate-spin -ml-1 mr-3", cn)}
    fill="none"
    {...viewBox(24)}
  >
    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
    <path className="opacity-75" fill="currentColor" d={getPathD("arc_spin")} />
  </svg>;

// =========================================
// Tailwind Colorset Definitions
// =========================================
/**
 * colorset Text, Focus
 */
export const twTextFocus = cL(
  "dark:text-gray-300 dark:hover:text-white",
  "text-gray-700 hover:text-black",
  "dark:focus:outline-none dark:focus:text-white dark:focus:bg-gray-700",
  "focus:outline-none focus:text-black focus:bg-gray-400"
);
export const twBtnFocus = cL(
  "rounded-full dark:text-gray-300 dark:bg-gray-800 dark:hover:bg-gray-600",
  "bg-gray-300 hover:bg-gray-400 focus:outline-none cursor-pointer");

// =========================================

export type TAngleRotateProps = 'L'|'R'|'U'|'D'; // Left, Right, Up, Down
export type TRightAngles      = 0|90|180|270;
/**
 * direction to angle (degree)
 * L R U D
 */
const ANGLE_ROTATION: Record<TAngleRotateProps, TRightAngles> = {
  'L':   0,
  'R': 180,
  'U':  90,
  'D': 270,
};

interface IButtonRotateProps extends IMouseProps {
  Path?: TPathDataKey;
  dir?:  TAngleRotateProps;
  fill?: string;
};
/**
 * With the left arrow, generate 4 directional arrows
 */
export const ButtonRotate = ({
  Path = "arrow_circle",
  dir, fill, onClick
}: IButtonRotateProps) => {
  return (
    <button className="p-1 focus:outline-none hover:text-yellow-600" {...{onClick}}>
      <SvgIcon
        className="w-5 h-5"
        {...{Path}}
        {...(dir && { Rotate: ANGLE_ROTATION[dir] })}
        {...(fill && { fill })}
      />
    </button>
  );
}

interface IToggleButtonTextProps extends AriaToggleButtonProps {
  textOn:   string;
  textOff?: string;
};
/**
 * Toggle Button
 */
export function ToggleButtonText({ textOn, textOff, ...props }: IToggleButtonTextProps) {
  const ref = useRef<HTMLButtonElement>(null);  // null is required in TypeScript
  const state = useToggleState(props);
  const { buttonProps, isPressed } = useToggleButton(props, state, ref);
  return (
    <button
      {...{ref}}
      {...buttonProps}
      {...cLo("inline-flex p-1 items-center justify-center", twTextFocus,
        twButtonPressed(isPressed))}
    >
      {state.isSelected ? textOn : textOff}
    </button>
  );
}

interface IToggleButtonSvgProps extends AriaToggleButtonProps {
  d?:     string;       // for SvgIconToggle
  d2?:    string;
  Path?:  TPathDataKey;
  Path2?: TPathDataKey;
};
/**
 * toggle button with a pair of SVGs
 * Use with isDisabled, isSelected, onPress
 * Using onChange (instead of onPress) can cause rendering error!
 */
export function ToggleButtonSvg({ d, d2, Path, Path2, ...props }: IToggleButtonSvgProps) {
  const ref = useRef<HTMLButtonElement>(null);  // null is required in TypeScript
  const state = useToggleState(props);
  const { buttonProps, isPressed } = useToggleButton(props, state, ref);
  return (
    <button
      {...{ref}}
      {...buttonProps}
      {...cLo("inline-flex p-0.5 items-center justify-center", twBtnFocus,
        twButtonPressed(isPressed))}
    >
      <SvgIconToggle
        cond={state.isSelected}
        {...{d, d2, Path, Path2}}
        strokeWidth={1}
      />
    </button>
  );
}

interface IDrawerAnchorButtonProps extends AriaToggleButtonProps {
  show?:     boolean;  // show drawer
  vertical?: boolean;  // vertical move (then right means down)
  right?:    boolean;  // on the right side (default is from the left)
};
/**
 * << button for SideDrawer
 * included here for other Aria related dependencies
 */
export function DrawerAnchorButton({
  show, vertical, right, ...props
}: IDrawerAnchorButtonProps) {
  const ref = useRef<HTMLButtonElement>(null);  // null is required in TypeScript
  const state = useToggleState(props);
  const { buttonProps, isPressed } = useToggleButton(props, state, ref);
  const angleShow = ANGLE_ROTATION[right
    ? vertical ? 'D' : 'R'
    : vertical ? 'U' : 'L'];
  const angle = show ? angleShow : (angleShow + 180) % 360 as TRightAngles;
  const PATH_ANGLE: Record<TRightAngles, TPathDataKey> = {
      0: "double_left",
    180: "double_right",
     90: "double_up",
    270: "double_down",
  };
  return (
    <button
      {...{ref}}
      {...buttonProps}
      {...cLo("inline-flex p-0.5 items-center justify-center", twBtnFocus,
        twButtonPressed(isPressed))}
    >
      <SvgIcon
        {...cLo("w-4 h-6")}
        {...(!isSafari && { Rotate: angle })}
        Path={PATH_ANGLE[isSafari ? angle : 0]}
        strokeWidth={1}
      />
    </button>
  );
}

interface INotiIconButtonProps extends AriaToggleButtonProps {
  notis?: number;  // number of unread notifications
};
/**
 * Notification Icon Button
 */
export function NotiIconButton({ notis = 0, ...props }: INotiIconButtonProps) {
  const ref = useRef<HTMLButtonElement>(null);  // null is required in TypeScript
  const disabled = !notis;
  const state = useToggleState(props);
  const { buttonProps, isPressed } = useToggleButton(props, state, ref);
  return (
    <button
      {...{ref}}
      {...buttonProps}
      {...{disabled}}
      {...cLo("p-1 border-transparent rounded-full disabled:opacity-50", twTextFocus,
        twButtonPressed(isPressed))}
    >
      <SvgIcon classX="text-red-400" fill="pink" strokeWidth={1} Path="bell_noti" />
      <span
        {...cLoIf(!disabled,
          "fixed top-2 ml-3 bg-red-700 text-white rounded-full w-3 h-3 shadow-md text-3xs")}
      >
        {notis}
      </span>
    </button>
  );
}

/**button
 * Create both on and off state svg and then switch the show and hide attribute only
 * Make sure just the class= toggles not the <svg itself using the Inspector
 * is Menu On (shown)?
 */
export function BurgerButton(props: AriaToggleButtonProps) {
  return <ToggleButtonSvg
    d="M6 18L18 6M6 6l12 12"
    d2="M4 6h16M4 12h16M4 18h16"
    {...props}
  />;
}

export const EmailLink: React.FC<{ email: string; }> = ({ email, children }) =>
  <a href={`mailto:${email}`} rel="noreferrer" target="_blank">
    { children || email }
  </a>;

export const ALink: React.FC<{ href: string; download?: boolean; }> = ({
  href,
  download = false,
  children
}) =>
  <a {...{href}} {...(!download && { rel: "noreferrer", target: "_blank" })}>
    { children || href }
  </a>;

/**
  * Plain text in template string to HTML
  */
export const CRtoHTML: IFClassName<{ text: string; }> = ({ className, text }) =>
  <div {...{className}} dangerouslySetInnerHTML={{ __html: replaceCRtoHTML(text) }} />;

/*=============================================================================
 RsvpForm.tsx - 

 - grp: weworkcom, weworktech, ...
 - Need to abstract common parts for any grp

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { useState, useEffect, } from 'react';
import { cL, cLo, cCo, } from 'utils/util';
import { timeUMTlocal } from 'utils/datetime';
import { ButtonC, } from 'ui/ui';
import { LabeledCheckbox, } from 'ui/inputForms';
import type { IRsvpParams, IServerActions, } from './RSVP';
import { sendFetchJson, EmailInfo, } from './RSVP';
import { ModalDlg, } from 'ui/ModalDlgTw';
import { ClockIcon, EyeIcon, EyeOffIcon, } from '@heroicons/react/outline';
import { CheckIcon, XIcon, } from '@heroicons/react/solid';

const MAX_TEXTINPUT = 200;
export const SEL_ABSENCE   = 0;  // 欠席
export const SEL_ATTEND    = 1;  // 出席
export const SEL_UNDECIDED = 2;  // その他
export type TBinary  = 0|1;
export type TTernary = 0|1|2;
//-----------------------------------------------------------------------------
/**
 * Answer sheet in JSON, base/common part
 */
export interface IAnswersBase {
  a:  TTernary; // 0: absence, 1: attend, 2: undecided
  aT: string;   // reason only for a:2
  rT: string;   // recommendation text
};
export const initAnsBase: IAnswersBase = {
  a:  0, aT:  "",          // main choices
  rT: "",                  // recommendation
};
export const ansBaseNames = {
  a:  "出欠回答",
  a0: "欠席",
  a1: "出席",
  aT: "その他",
  rT: "質問事項",
};
//-----------------------------------------------------------------------------
/**
 * grp specific answers
 */
interface IAnswers extends IAnswersBase {
  sa:  TBinary;  // schedule sa
  sa1: TBinary;  //   schedule sa1
  saT: string;   //   schedule saT
  sb:  TBinary;
  sb1: TBinary;
  sc:  TBinary;
};
const initAns: IAnswers = {
  ...initAnsBase,
  sa:  0, sa1: 0, saT: "",  // schedule a
  sb:  0, sb1: 0,           // schedule b
  sc:  0,                   // schedule c
};
const ansNames = {
  ...ansBaseNames,
  sa:  "17:00～17:30 ／ 展示会",
  sa1: "展示会で自社のサービスを展示したい",
  saT: "展示したい内容",
  sb:  "17:30～19:00 ／ 情報交換会",
  sb1: "情報交流会で自社のサービスを発表したい",
  sc:  "19:00～20:00 ／ 懇親会",
};

const AnswerSheet = ({ ans }: { ans: IAnswers; }) => {
  const mark = (ch: TBinary) => (ch === 1) ? '✅' : '❌';
  const twSec = "text-left border border-gray-500 p-4 shadow-md";
  const twM = cLo("font-bold text-xl");  // Main
  const twS = cLo("text-base ml-8");     // Sub
  const Text: React.FC<IClassNameObj> = ({ className, children }) => children
    ? <span {...cLo("text-indigo-600 bg-yellow-100 rounded-full p-1", className)}>
        {children}
      </span>
    : null;
  return (
    <>
      <div {...cLo("text-6xl text-bold text-center py-4 my-2",
        "bg-gray-700 rounded-full shadow-md")}
      >
        {(ans.a === SEL_ATTEND)
        ? <span {...cLo("text-green-200")}>{ansNames.a1}</span>
          : (ans.a === SEL_ABSENCE)
            ? <span {...cLo("text-red-200")}>{ansNames.a0}</span>
            : <span {...cLo("text-yellow-200")}>{ansNames.aT}</span>}
      </div>
      {(ans.a === SEL_UNDECIDED) &&
        <section {...cLo(twSec)}>
          {ansNames.aT}：<Text>{ans.aT}</Text>
        </section>}
      <section {...cLo(twSec,
        (ans.a === SEL_ABSENCE)   && "opacity-10 bg-gray-400",
        (ans.a === SEL_UNDECIDED) && "opacity-20 bg-gray-400")}
      >
        <div {...twM}>{mark(ans.sa)} {ansNames.sa}</div>
        <div {...twS}>{mark(ans.sa1)} {ansNames.sa1}</div>
        <div {...twS}>{ansNames.saT}：<Text>{ans.saT}</Text></div>
        <div {...twM}>{mark(ans.sb)} {ansNames.sb}</div>
        <div {...twS}>{mark(ans.sb1)} {ansNames.sb1}</div>
        <div {...twM}>{mark(ans.sc)} {ansNames.sc}</div>
      </section>
      <section {...cLo(twSec)}>
        <div {...twS}>{ansNames.rT}：<Text>{ans.rT}</Text></div>
      </section>
    </>
  );
}

export interface IRsvpFormParams extends IRsvpParams {
  srvAct:    Nullable<IServerActions>;
  setSrvAct: (data: Nullable<IServerActions >) => void;
  onClose:   (refresh: boolean) => void;  // callback
  dead:      boolean;  // deadline passed
};
/**
 * Form input part of RSVP
 */
export const RsvpForm = ({
  grp, tgt,
  srvAct, setSrvAct,
  onClose, dead
}: IRsvpFormParams) => {
  const [open, setOpen] = useState(false);  // ModalConfirm
  const [ans,  setAns]  = useState<IAnswers>(initAns);
  const { cho, eventTitle, } = srvAct || {};

  console.log("RsvpForm");
  useEffect(() => {
    if (!cho) {
      console.log("No cho!");
      return;
    }
    const res = parseResponse(cho);
    console.log("RsvpForm:useEffect", cho, res);
    if (res?.choice) {
      setAns(res.choice);
    }
  }, [cho]);

  const handleOK = async () => {
    console.log("handleOK", ans);
    setOpen(true);
  }

  const onModalDlgCB = (ok: boolean) => {
    if (ok) {
      sendFetchJson(grp, tgt, setSrvAct, { choice: ans });
      onClose(ok);
    }
  }

  return (!srvAct || dead)
  ? <div {...cLo("text-left items-center text-2xl my-20 mx-10")}>
      {srvAct
      ? srvAct.cho
        ? <div>
            <p {...cLo("text-center")}>ご回答</p>
            <AnswerSheet {...{ans}} />
          </div>
        : <p {...cLo("text-center text-red-400 my-10")}>回答の〆切りが過ぎています。</p>
      : <div {...cLo("border-4 border-gray-200 rounded-xl p-10")}>
          出欠回答機能を利用するには事務局からの招待が必要です。<br/>
          お問い合わせ（メール）でイベントへの参加希望をご連絡ください。
        </div>}
      <EmailInfo {...cLo("mt-10")} />
    </div>
  : <div {...cLo("Sq_h-max overflow-y-scroll")}>
      <h1 {...cLo("font-bold text-lg text-center p-2 text-gray-100 bg-gray-600")}>
        出欠確認フォーム
      </h1>
      <div {...cLo("p-2 bg-gray-300 font-bold text-center")}>
        イベント名：{eventTitle}
      </div>

      <div {...cLo("m-4 shadow-md border-2 border-gray-500")}>
        <MemberCard {...{srvAct}} />
      </div>

      <div {...cLo("mx-4 my-1 p-2 bg-gray-100 rounded-lg shadow-md border border-gray-400")}>
        <FormChoices {...{ans, setAns}} />
      </div>

      <div {...cLo("flex flex-row justify-center mt-4")}>
        <ButtonC
          classX={cL("BC_Green p-6 m-2 w-40 text-base text-lg rounded-md TextShadowB")}
          onPress={() => handleOK()}
        >
          回答
        </ButtonC>
        <ButtonC
          classX={cL("BC_Red p-6 m-2 w-40 text-base text-lg rounded-md TextShadowB")}
          onPress={() => onClose(false)}
        >
         キャンセル
        </ButtonC>
      </div>
      <ModalDlg {...{open, setOpen}}
        title="ご回答"
        ok="送信"
        cancel="キャンセル"
        onOkCancel={onModalDlgCB}
      >
        <AnswerSheet {...{ans}} />
      </ModalDlg>
    </div>;
}

export const MemberCard = ({ srvAct }: { srvAct: Nullable<IServerActions>; }) => {
  const [show, setShow] = useState(true);
  if (!srvAct)
    return null;
  const { time, email, lastName, firstName, company, phone, } = srvAct;
  const person = [
    { col0: "組織名",    col1: company },
    { col0: "名　前",    col1: lastName + ' ' + firstName },
    { col0: "メール",    col1: email },
    { col0: "電話番号",  col1: phone },
    { col0: "アクセス",  col1: timeUMTlocal(time), cls: "text-xs text-indigo-400" },
  ];
  const twEye = cLo("absolute w-5 h-5 top-0 right-0 m-1");
  return (
    <>
      <h1 {...cLo("relative FontMincho font-bold text-lg text-center",
        "cursor-pointer bg-gray-500 text-gray-50")}
        onClick={() => setShow(!show)}
      >
        {show
        ? <EyeOffIcon {...twEye} />
        : <EyeIcon    {...twEye} />}
        <span>{show ? '参加者名' : `${company}　${lastName} ${firstName}`}</span>
      </h1>
      <table {...cCo("w-full shadow-md", !show, "hidden")}>
        <tbody {...cLo("bg-gray-50")}>
          {person.map((row, id) => (
            <tr key={id} {...cLo(id % 2 === 0 ? 'bg-gray-100' : 'bg-gray-200')}>
              <td {...cLo("px-3 py-1 whitespace-nowrap font-bold text-base")}>{row.col0}</td>
              <td {...cLo("px-3 py-1", row.cls)}>{row.col1}</td>
            </tr>))}
        </tbody>
      </table>
    </>
  );
}

/**
 * Parse previous response, always return non null values
 *
 * Production version will use JSON/stringify format.
 */
export const parseResponse = (jsonStr: string0U) => {
  if (!jsonStr)
    return null;
  try {
    return JSON.parse(jsonStr);
  } catch (e) {
    console.error(e);
  }
  return null;
}

interface IFormChoicesProps {
  ans:    IAnswers;
  setAns: (ans: IAnswers) => void;  // callback
};
/**
 * Let the input handlers in the form set its parent component's states (ans) by calling
 * the callback (setAns).
 * All controlled components in React.
 */
const FormChoices = ({ans, setAns}: IFormChoicesProps) => {

  // main choice states are kept in the child SelectMainChoices component.
  // Don't keep duplicate states here. Just handover callback to sync.
  const setSelect = (sel: TTernary) => {
    if ((ans.a !== SEL_ATTEND && sel === SEL_ATTEND) &&  // new state changing to ATTEND
      !ans.sa && !ans.sb && !ans.sc)                     // no session chosen
    {
      setAns({
        ...ans, a: sel,
        sa: 1, sb: 1, sc: 1  // force all schedules selection
      });
    } else {
      setAns({ ...ans, a: sel });
    }
  }
  const updateVal = (e: React.ChangeEvent<HTMLInputElement>, key: keyof IAnswers) => {
    setAns({
      ...ans,
      [key]: e.target.value.length < MAX_TEXTINPUT
        ? e.target.value
        : e.target.value.substring(0, MAX_TEXTINPUT)
    });
  }

  // onFormSubmit will be called when ENTER key pressed
  // only when <input type="submit" value="" /> put inside.
  // Let's call parent's handleOK
  const onFormSubmit: React.FormEventHandler = (ev) => {
    ev.preventDefault();  // to prevent page reload on form submit
  }

  // if (select === 1 && ach.ch.includes(true)) {
  //   setAch({ ch: new Array<boolean>(NUM_CHOICES).fill(false), text: ach.text });
  // }
  const twTLabel = cLo("text-sm sm:text-base text-white text-center TextShadowB");
  const twTInput = cLo("flex flex-col bg-gray-500 pb-2 px-1 my-2 rounded-lg");

  return (
    <form
      {...cLo("flex flex-col w-full")}
      onSubmit={ev => onFormSubmit(ev)}
    >
      <SelectMainChoices {...cLo("my-2")} select={ans.a} {...{setSelect}} />
      {(ans.a === SEL_UNDECIDED) &&
        <div {...twTInput}>
          <span {...twTLabel}>
            {ansNames.aT}
          </span>
          <input
            type="text"
            placeholder={ansNames.aT}
            {...cLo("w-full border border-gray-400")}
            value={ans.aT}
            onChange={e => updateVal(e, 'aT')}
            disabled={ans.a !== SEL_UNDECIDED}
          />
        </div>
      }
      <div {...cLo("bg-gray-400 rounded-lg",
          (ans.a === SEL_ABSENCE)   && "opacity-20",
          (ans.a === SEL_UNDECIDED) && "opacity-50")}
      >
        <p {...cLo("text-sm text-center")}>イベントへの出席について確認させてください。</p>
        <SelectSubChoices {...{ans, setAns}} />
      </div>

      <div {...twTInput}>
        <span {...twTLabel}>
          {ansNames.rT}：
          <span {...cLo("text-xs")}>
            例）本イベントへの参加を推薦したい方がいる
          </span>
        </span>
        <input
          type="text"
          placeholder={ansNames.rT}
          {...cLo("w-full border border-gray-400")}
          value={ans.rT}
          onChange={e => updateVal(e, 'rT')}
        />
      </div>

      <input type="submit" value=" " {...cLo("bg-gray-200")} />
    </form>
  );
}

const SelectSubChoices = ({ans, setAns}: IFormChoicesProps) => {
  // Binary choices
  const updateBin = (e: React.ChangeEvent<HTMLInputElement>, key: keyof IAnswers) => {
    setAns({ ...ans, [key]: e.target.checked ? 1 : 0 });
  }
  const updateVal = (e: React.ChangeEvent<HTMLInputElement>, key: keyof IAnswers) => {
    setAns({
      ...ans,
      [key]: e.target.value.length < MAX_TEXTINPUT
        ? e.target.value
        : e.target.value.substring(0, MAX_TEXTINPUT)
    });
  }
  const twTLabel = cLo("text-sm sm:text-base text-white text-center TextShadowB");
  const twTInput = cLo("flex flex-col bg-gray-500 pb-2 px-1 my-2 rounded-lg");
  const twSection = cLo("bg-gray-50 border border-gray-400 p-2");
  const twCheck0 = cLo("w-5 h-5 mx-2");  // checkbox level 0
  const twCheck1 = cLo("mx-2 sm:mx-8");  // checkbox level 1
  const Label0: React.FC<IClassNameObj> = ({ className, children }) =>
     <span {...cLo("font-bold text-xl sm:text-2xl", className)}>{children}</span>;

  const Label1: React.FC<IClassNameObj> = ({ className, children }) =>
     <span {...cLo("font-bold text-base", className)}>{children}</span>;

  const LabelCmt: React.FC<IClassNameObj> = ({ className, children }) =>
     <div {...cLo("text-sm text-indigo-600", className)}>{children}</div>;

  const disabled = { disabled: ans.a !== SEL_ATTEND };
  return (
    <>
      <section {...twSection}>
        <LabeledCheckbox
          id="ans_sa"
          {...twCheck0}
          {...disabled}
          checked={ans.sa === 1}
          onChange={e => updateBin(e, 'sa')}
        >
          <Label0>17:00～17:30 ／ 展示会</Label0>
        </LabeledCheckbox>
        <div {...twCheck1}>
          <LabeledCheckbox
            id="ans_sa1"
            disabled={ans.sa === 0}
            checked={ans.sa1 === 1}
            onChange={e => updateBin(e, 'sa1')}
          >
          　<Label1>自社のサービスを展示したい</Label1>
            <LabelCmt>
              展示物について事務局と調整が必要です。<br/>
              また当日は展示会の1時間前に来場いただく必要があります。
            </LabelCmt>
          </LabeledCheckbox>
          <div {...twTInput}>
            <span {...twTLabel}>展示したい内容</span>
            <input
              type="text"
              placeholder="展示したい内容"
              {...cLo("w-full border border-gray-400")}
              {...disabled}
              disabled={ans.sa === 0}
              value={ans.saT}
              onChange={e => updateVal(e, 'saT')}
            />
          </div>
        </div>
      </section>

      <section {...twSection}>
        <LabeledCheckbox
          id="ans_sb"
          {...twCheck0}
          {...disabled}
          checked={ans.sb === 1}
          onChange={e => updateBin(e, 'sb')}
        >
          <Label0>17:30～19:00 ／ 情報交換会</Label0>
        </LabeledCheckbox>
        <div {...twCheck1}>
          <LabeledCheckbox
            id="ans_sb1"
            disabled={ans.sb === 0}
            checked={ans.sb1 === 1}
            onChange={e => updateBin(e, 'sb1')}
          >
          　<Label1>自社及び自社のサービスを発表したい</Label1>
            <LabelCmt>
              事項紹介シートの事前作成が必要となります。<br/>
              フォーマットをダウンロードし記入後に事務局までご送付ください。
            </LabelCmt>
          </LabeledCheckbox>
        </div>
      </section>

      <section {...twSection}>
        <LabeledCheckbox
          id="ans_sc"
          {...twCheck0}
          {...disabled}
          checked={ans.sc === 1}
          onChange={e => updateBin(e, 'sc')}
        >
          <Label0>19:00～20:00 ／ 懇親会</Label0>
          <div {...twCheck1}>
            <LabelCmt>
              会費制：当日会場受付で会費（予定）￥1000をお支払いください。
            </LabelCmt>
          </div>
        </LabeledCheckbox>
      </section>
    </>
  );
}

/**
 * Stateless main choices (ATTEND, ABSENCE, UNDECIDED) seletor UI
 */
interface ISelectMainChoicesProps {
  select:    TTernary;
  setSelect: (sel: TTernary) => void;
};
export const SelectMainChoices: IFClassName<ISelectMainChoicesProps> = ({
  select, setSelect, className
}) => {
  // const [at, setAt] = useState<0|1|2>(select);  // attend state -> Wrong! Refer parent states
  const handlePress = (sel: TTernary) => {
    setSelect(sel);
  }
  const twLabel = cLo("text-gray-700");
  const twBX = "w-12 h-12 sm:w-14 sm:h-14 text-base text-lg rounded-full";  // ButtonC classX
  const twIcon = cLo("w-full p-1 mb-1");
  const twSel = "flex flex-shrink-0 p-7 sm:p-8 rounded-full";  // select

  return (
    <div {...cLo("flex flex-col w-full max-w-sm mx-auto", className)}>
      <div {...cLo("flex flex-row justify-around bg-white rounded-full shadow-md",
        "px-2 py-2 FontMincho whitespace-nowrap")}
      >
        <div {...cCo(twSel, select === SEL_ATTEND, "bg-green-200 shadow-md")}>
          <ButtonC
            classX={cL(twBX, "BC_Green")}
            onPress={() => handlePress(SEL_ATTEND)}
          >
            <CheckIcon {...twIcon} />
            <span {...twLabel}>{ansNames.a1}</span>
          </ButtonC>
        </div>
        <div {...cCo(twSel, select === SEL_ABSENCE, "bg-red-200 shadow-md")}>
          <ButtonC
            classX={cL(twBX, "BC_Red")}
            onPress={() => handlePress(SEL_ABSENCE)}
          >
            <XIcon {...twIcon} />
            <span {...twLabel}>{ansNames.a0}</span>
          </ButtonC>
        </div>
        <div {...cCo(twSel, select === SEL_UNDECIDED, "bg-yellow-200 shadow-md")}>
          <ButtonC
            classX={cL(twBX, "BC_Yellow")}
            onPress={() => handlePress(SEL_UNDECIDED)}
          >
            <ClockIcon {...twIcon} />
            <span {...twLabel}>{ansNames.aT}</span>
          </ButtonC>
        </div>
      </div>
    </div>
  );
}

/*=============================================================================
 SideOverTw.tsx - 

 - Check z-order: z-50

 - plugins: [  // tailwind.config.js
 - require('@tailwindcss/aspect-ratio'),

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { Fragment, } from 'react';
import { cLo, } from 'utils/util';
import { XIcon } from '@heroicons/react/outline';
import { Dialog, Transition } from '@headlessui/react';

interface ISideOverProps extends Omit<IModalDlgStates, 'OnOkCancel'> { };
/**
 * Check for the z-orders, esp. Navbar: z-50 - z-orders need to be managed in a central place
 */
export const SideOver: React.FC<ISideOverProps> = ({
  open,
  setOpen,
  children,  // content
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" static
       {...cLo("z-40 fixed inset-0 overflow-hidden")}
        {...{open}}
        onClose={setOpen}
      >
        <div {...cLo("absolute inset-0 overflow-hidden")}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              {...cLo("absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity")}
            />
          </Transition.Child>
          <div {...cLo("fixed inset-y-0 right-0 max-w-full flex")}>
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div {...cLo("w-screen max-w-xl")}>
                {/* push down navbar offset */}
                <div className="Sq_navbar-h" />
                <div {...cLo("h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl")}>
                  <div {...cLo("relative min-h-0 flex-1 flex flex-col overflow-y-scroll")}>
                    <button
                      {...cLo("absolute top-0 right-0 mt-2 mr-8",
                      "bg-white rounded-full text-gray-400 hover:text-gray-500",
                      "focus:outline-none focus:ring-2 focus:ring-indigo-500",
                      "opacity-50 hover:opacity-100")}
                      onClick={() => setOpen(false)}
                    >
                      <span {...cLo("sr-only")}>Close panel</span>
                      <XIcon {...cLo("h-8 w-8 rounded-full text-white bg-red-400")}
                        aria-hidden="true"
                      />
                    </button>
                    {children}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

/*=============================================================================
 apiCommon.ts - Common definitions for API data

 - Backend API dependent namings

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import firebase from './firebase';
import { ITimestamp } from 'models';

/**
 * Firebase Auth Error
 */
export type TAuthError  = firebase.auth.Error;

// ----------------------------------------------------------------------------
// Collections and Documents, structural definitions
// Firebase Cloud Firestore
// - User UID: 28 digits  4V9x6bgXA8Pb4bE80RqJ2lsKDIe2
// - doc id:   20 digits  HgOCHACGcol4jj3gxoLW
// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------
// constants for *Slice reducers; use constants for consistency
// ----------------------------------------------------------------------------
export const AUTH  = 'auth';
export const USERS = 'users';
export const CONTS = 'conts';  // Contacts in users
export const ROOMS = 'rooms';
export const MSGS  = 'msgs';   // messages
export const GRP   = 'grp';    // group
export const CTCS  = 'ctcs';   // contacts in group

export const fsPath = {  // Firestore Path to collections and documents
  users: USERS,  // collection
  rooms: ROOMS,  // collection
  ctcs:  CTCS,   // collection
  user:  (uid:    TUserID) => `${USERS}/${uid}`,             // doc
  ctc:   (id:     TCtcID)  => `${CTCS}/${id}`,               // doc
  room:  (roomId: TRoomID) => `${ROOMS}/${roomId}`,          // doc
  conts: (uid:    TUserID) => `${USERS}/${uid}/${CONTS}`,    // sub-collection
  msgs:  (roomId: TRoomID) => `${ROOMS}/${roomId}/${MSGS}`,  // sub-collection
  cont:  (uid:    TUserID, cid:   TUserID) => `${USERS}/${uid}/${CONTS}/${cid}`,      // doc
  msg:   (roomId: TRoomID, msgId: TMsgID)  => `${ROOMS}/${roomId}/${MSGS}/${msgId}`,  // doc
}
// ----------------------------------------------------------------------------

type TTimestampProp = 'createdAt'|'updatedAt'|'signInAt'|'time';

/**
 * convert firebase timestamp to a serializable miliseconds (number)
 */
export const serializeTime = (ts: ITimestamp) => (ts.seconds * 1000);

/**
 * serialize Timestamp and construct an object with the given property name
 * index signature to give the shape of data object
 */
export const serializeTS = (prop: TTimestampProp, ts: Undefinable<ITimestamp>) =>
  ts && ({ [prop]: serializeTime(ts) });

/**
 * let the firebase get server Timestamp and construct an object with prop
 */
export const serverTS = (prop: TTimestampProp) =>
  ({ [prop]: firebase.firestore.FieldValue.serverTimestamp() });

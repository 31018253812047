/*=============================================================================
 reqQueue.ts - request Queue

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { errorFmt } from 'utils/util';

// class CacheRequests {  // cache for requested string ids (uid, room Id, etc...)
//   queue: string[] = [];
//   isReq = (id: string) => this.queue.includes(id);
//   addReq = (id: string, name?: string) => {
//     if (this.isReq(id)) {
//       console.log(`${name}:`, id, " already in queue");
//       return 0;
//     }
//     console.log(`${name}:`, id);
//     return this.queue.push(id);  // length
//   }
//   removeReq = (id: string) => this.queue = this.queue.filter(i => i !== id);
// };
/*  cache for requested string ids (uid, room Id, etc...)
 */

const REQ_TTL = 3000;  // Request Time-To-Live ms
/**
 * cached requests: to prevent repeated requests
 * - need TTL: For simplicity, let's clear all queues 3 seconds later 'any' request
 *
 */
class CacheRequests {
  queue: Set<string>;
  lastTime: number;  // date
  constructor() {
    this.queue = new Set<string>();
    this.lastTime = 0;
  }
  isReq = (id: string) => this.queue.has(id);
  addReq = (id: string, name?: string) => {
    const now = Date.now();
    if (this.isReq(id) && (now - this.lastTime < REQ_TTL)) {
      console.log(`${name}:`, id, " already in queue");
      return 0;
    }
    console.log(`${name}:`, id);
    this.lastTime = now;
    return this.queue.add(id);  // Set object with added value
  }
  removeReq = (id?: stringU) => id  // Do NOT reset lastTime since it's shared
    ? this.queue.delete(id)
    : this.queue.clear();  // clear all
};

export const WARN_REQ_IN_QUEUE = errorFmt('WARN', "Request Cache", "Request already in queue");
export const CREQ = new CacheRequests();

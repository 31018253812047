/*=============================================================================
 RegEx.ts - RegEx

 - With /g option, JS just gives an array of full matching strings, not each group details
   How can we get the capture group #2?
 - RegEx test() function with /g advances lastIndex: be careful for the repeated use!

 (C) 2021 SpacetimeQ INC
=============================================================================*/

export const RegEx_Auth = {  // Authentication
  email:        /^\w+(?:[.-]?\w+)*@\w+(?:[.-]?\w+)*(?:\.\w{2,3})+$/,   // single, complete email string
  email_g:      /\w+(?:[.-]?\w+)*@\w+(?:[.-]?\w+)*(?:\.\w{2,3})+/g,    // multiple emails in text
  email_id:     /(^\w+(?:[.-]?\w+)*)@/,                                // 0: full email 1: email id
  password:     /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/,  // unused
  //---------------------------------------------------------------------------
  // For Password security scores
  //---------------------------------------------------------------------------
  pw_uppercase: /(?=.*[A-Z])/,
  pw_special:   /(?=.*[!@#$&*])/,
  pw_number:    /(?=.*[0-9])/,
  pw_lowercase: /(?=.*[a-z])/,
};

export const RegEx_URL = {
  nonempty: /^(?!\s*$).+/,
  blob: /^blob:/,
  url: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g,
  proto: /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi,
      // /^(ht|f)tp(s?):\\/\\/[0-9a-zA-Z]([-.\\w]*[0-9a-zA-Z])*((0-9)*)*(\\/?)([a-zA-Z0-9\\-\\.\\?\\,\\'\\/\\\\+&=%\\$#_]*)?$/
  youtube: /(?:https?:\/\/)?(?:(?:(?:www\.?)?youtube\.com(?:\/(?:(?:watch\?.*?(v=[^&\s]+).*)|(?:v(\/.*))|(channel\/.+)|(?:user\/(.+))|(?:results\?(search_query=.+))))?)|(?:youtu\.be(\/.*)?))/g,
        // /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)/i,
  instagram: /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am)\/([A-Za-z0-9-_]+)/im,
};

export const RegEx_Cont = {  // Content
  img: /\.(gif|jpe?g|tiff?|png|webp|bmp)(\?|$)/i,
  //---------------------------------------------------------------------------
  // YouTube
  //---------------------------------------------------------------------------
  youtubeId: /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/i,
  //---------------------------------------------------------------------------
  // Math $$KaTeX$$
  //---------------------------------------------------------------------------
  // in-between issue: $$eq1$$in-between$$eq2$$ solved.
  // Check the escape sequnce '\$' then interpret as the literal dollar sign.
  // math_eq:      /(?<=\$\$)(.{3,}?)(?=\$\$)/gs,   // eq only (positive lookbehind not supported in Safari)
  math_del:     /(\$\$)/g,                       // to remove delimeters
  math_eq_inc:  /(\$\$)((?:(?!\1).){3,}?)\1/gs,  // eq with delimeters
             // /(\$\$)(.{3,}?)(\$\$)/gs
  math_block:   /\b(?:lim|sum|int)/,             // to be block (not inline) mode; do not set /g
  //---------------------------------------------------------------------------
  // Firebase Authentication, Cloud Firestore
  //---------------------------------------------------------------------------
  firebase_uid:    /\b([0-9a-z]{28})\b/i,   // firebase uid https://regexr.com/5pig3
  firebase_doc_id: /\b([0-9a-z]{20})\b/i,   // firebase document id
  //---------------------------------------------------------------------------
  // #hashtag, @username
  //---------------------------------------------------------------------------
  hashtag:  /(?!\s)#[A-Za-z]\w*\b/g,   // https://regex101.com/r/nD7iD9/5
  // /(^|\B)#(?![0-9_]+\b)([a-zA-Z0-9_]{1,30})(\b|\r)/g
  username: /((?=[^\w!])@\w+\b)/g,     // https://regex101.com/r/jU9hC5/5
  userid:   /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{2,29}$/,  // instagram userid https://regexr.com/3cg7r
  handle:   /^\S*$/,  // string consisting only of non-whitespaces
  zipJp:    /^[0-9]{3}-[0-9]{4}$/,
  zipUs:    /^[0-9]{5}(?:-[0-9]{4})?$/,
};

/**
 * get email id portion
 */
export const emailId = (email: string0U) => {  // email should be a complete email string
  if (!email)
    return undefined;
  const em = email.match(RegEx_Auth.email_id);
  return em?.[1];  // em[0]: full email, em[1]: id portion
}

// export const RegEx_Util = {  // Utilities
//   no_whitespace: /\S/g,
//   // check if there's any other than whitespaces
// };

//---------------------------------------------------------------------------
// Test Datasets
//---------------------------------------------------------------------------
/* YouTube ID
https://regexr.com/5pltm
YouTube ID extract test set
http://www.youtube.com/sandalsResorts#p/c/54B8C800269D7C1B/0/FJUvudQsKCM
http://www.youtube.com/user/Scobleizer#p/u/1/1p3vcRhsYGo
http://youtu.be/NLqAF9hrVbY
http://www.youtube.com/embed/NLqAF9hrVbY
https://www.youtube.com/embed/NLqAF9hrVbY
http://www.youtube.com/v/NLqAF9hrVbY?fs=1&hl=en_US
http://www.youtube.com/watch?v=NLqAF9hrVbY
http://www.youtube.com/user/Scobleizer#p/u/1/1p3vcRhsYGo
http://www.youtube.com/ytscreeningroom?v=NRHVzbJVx8I
http://www.youtube.com/user/Scobleizer#p/u/1/1p3vcRhsYGo
http://www.youtube.com/watch?v=JYArUl0TzhA&feature=featured
//---------------------------------------------------------------------------
/* Math Eq
KaTeX math expressions
$1,000 $20,000
$${\partial V\over\partial t}+{1\over 2}\sigma^2S^2{\partial^2 V\over\partial S^2}+rS{\partial V\over\partial S}-rV=0$$
in-between problem $$y=\sqrt{x}$$

Does it work over multi-lines? $$
\int\!\!\!\!\int_D f(x,y)dxdy,\oint_C\mathbf{F}(\mathbf{r})\cdot d\mathbf{r}
$$
I want to exclude trivial cases such as $$eq$$
*/

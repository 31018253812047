/*=============================================================================
 RSVP.tsx - Répondez s'il vous plaît - require confirmation of an invitation

  RSVP                       - RSVP landing page /rsvp/{grpId}/{tgtId}
  │                              HTTP url encoded request GET ?grp=_&tgt=_&cho=_&txt=_
  └── Event                  - Event details page
      │
      └── RsvpForm           - Invitation Response sheet
          │   on SideOver      on the left drawer
          │
          └── RsvpConfirm    - Modal confirm form for answer sheet

 - Cloud Firestore: /rsvp/{target}/action/{docId}
   target doc: createdAt, text
   action doc: text, time

  http://localhost:3000/rsvp/weworkcom/dSYHnxi3pRUvKLbmumjZ

 - To purge user actions made before the changed event schedule:
   1. update version for the rsvp table go ignore previous cache in user's LocalStorage
   2. Server-Side Control: Cloud Function - search actions data only for the valid period

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { VERSION_RSVP } from 'app/App'
import { useEffect, useState, } from 'react';
import { useParams, } from 'react-router-dom';
import { cL, cLo, cCo, } from 'utils/util';
import type { ICountdownProps, } from 'utils/datetime';
import { useCountdown, msToMMDDhhmm, } from 'utils/datetime';
import { Copyright, ButtonC, } from 'ui/ui';
import { Event, eventDate, } from 'features/event/Event';
import { getLS, setLS } from 'utils/useLocalStorage';
import { RsvpForm } from './RsvpForm';
import { RsvpForm as RsvpFormWT  } from './RsvpFormWeworktech';
// import type { ISteps, } from 'ui/StepsTw';
// import { StepsPanel, stepsStatus, } from 'ui/StepsTw';
import { SideOver, } from 'ui/SideOverTw';
import { MailIcon, ClockIcon, } from '@heroicons/react/outline';
import { CheckIcon, XIcon, } from '@heroicons/react/solid';
import { SEL_ATTEND, SEL_ABSENCE, SEL_UNDECIDED, } from './RsvpForm';
import { TooltipSpan } from 'ui/Tooltip';
import { useCurrentUser } from 'features/users/usersSlice';
// import { useCtcByEmail } from 'features/ctcs/ctcsSlice';
import { useLocaleCtx } from 'ui/locale/LocaleCtx';

export type TMemberCat = 'A'|'B'|'C';

export const fetchUserIP = async () => {
  // ------------------------------------------------------------
  const res = await fetch("https://geolocation-db.com/json/");
  // ------------------------------------------------------------
  console.log("fetchUserIP:", res);
  if (res.ok) {
    return await res.json();
  } else {
    throw new Error(`getUserIP: ${res.status}`);
  }
}

/**
 * routed URL starts with "/rsvp" and followed by two parameters
 * that can be retrieved by useParams()
 */
export const RouteRsvp = "/rsvp/:grp/:tgt";  // should exactly match with IRsvpParams
export interface IRsvpParams {
  grp: 'weworkcom'|'weworktech';  // group id
  tgt: string;  // target: 20 (firestore doc Id) or 21 (nanoid()) characters
};

const URL_RSVP = process.env.NODE_ENV !== 'production'
  ? "http://localhost:5001/stqevent/us-central1/setRsvp"
  : "https://us-central1-stqevent.cloudfunctions.net/setRsvp";

/**
 * When sending to server, send with cho and txt; when receiving only get cho.
 */
export const urlArg = (
  grp: IRsvpParams['grp'],
  tgt: IRsvpParams['tgt'],
  cho: string0U,  // stringified JSON, choices
  txt: string0U   // stringified JSON, userAgent, additional user info
) => {
  let url = `${URL_RSVP}?grp=${grp}&tgt=${tgt}`;
  if (cho) url += `&cho=${cho}`;
  if (txt) url += `&txt=${txt}`;
  return url;
}

/**
 * Server data gotten with grp/tgt key should be saved to the LocalStorage in JSON stringified
 * Since 2021-08-18, by adding version we can control the cache refresh cycle.
 * For each version upgrade, every client will fetch from server not from the cache (LocalStorage).
 */
const paramKey = (
  grp: IRsvpParams['grp'],
  tgt: IRsvpParams['tgt']
) => `${grp}/${tgt}/${VERSION_RSVP}`;
export const setLSParamJson = (
  grp: IRsvpParams['grp'],
  tgt: IRsvpParams['tgt'],
  json: string              // save in JSON data from server
) => {
  return setLS(paramKey(grp, tgt), json);
}
export const getLSParamJson = (
  grp: IRsvpParams['grp'],
  tgt: IRsvpParams['tgt']
) => {
  return getLS(paramKey(grp, tgt));
}

/**
 * 1. Check the target code (21 characters)
 * 2. Check if the group name is correct by comparing with the data gotten by the target code
 * https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
 * {
  "country_code":"JP",
  "country_name":"Japan",
  "city":null,
  "postal":null,
  "latitude":35.69,
  "longitude":139.69,
  "IPv4":"126.243.245.74",
  "state":null
  }
 */
export interface IUserLocal {
  // country_code: string0;
  // country_name: string0;
  // city:         string0;
  // postal:       string0;
  // latitude:     number;
  // longitude:    number;
  // ipv4:         string0;
  // state:        string0;
  userAgent?:   string;   // navigator.userAgent
  choice:       object;   // choice for the Event, stringified JSON
};
export interface IServerActions {
  //--------------------  ...rsvp/{tgtId}/actions/ { time, txt }
  time?:       number;   // submit time (server time)
  cho?:        string0;  // last choice and env data
  // txt?:        string0;  // userAgent and others, only for server-side, don't transfer to client
  //--------------------  /grp/{grpId}/rsvp/{tgtId}/
  email:       string;   //  .../tgt/ { email, lastName, ... }
  lastName?:   string0;
  firstName?:  string0;
  company?:    string0;
  phone?:      string0;
  cat?:        TMemberCat;  // member category (A-C)
  //--------------------  // common to the event (not to each user)
  eventTitle?: string0;
  deadline?:   number;    // UTC time string "Thu, 05 Aug 2021 13:00:00 GMT" at server
};

export type TResponseState = 'error'|'attend'|'absence'|'undecided'|'dead';
interface IResponseState {
  state: TResponseState;
  color: string;   // button color className
  dead:  boolean;  // button attribute (opacity, disabled)
};
const responseState = (sa: Nullable<IServerActions>): IResponseState => {
  const btnSt = (
    state: IResponseState['state'],
    color: IResponseState['color'],
    dead:  IResponseState['dead']
  ) => ({ state, color, dead });

  let dead = false;
  if (!sa)
    return btnSt('error', 'BC_Gray', dead);  // no data

  if (sa.deadline) {
    const deadDay = new Date(sa.deadline);
    const today   = new Date();
    // --------------------------------------------------------------------------------
    // Check if it passed the deadline
    dead = today.getTime() > deadDay.getTime();
    // --------------------------------------------------------------------------------
  }

  if (sa.cho) {  // has choice data
    try {
      const json = JSON.parse(sa.cho);
      if (json?.choice) {  // got response
        const ans = json.choice;
        switch (ans.a) {
          case SEL_ABSENCE:   return btnSt('absence',   'BC_Red',    dead);
          case SEL_ATTEND:    return btnSt('attend',    'BC_Green',  dead);
          case SEL_UNDECIDED: return btnSt('undecided', 'BC_Yellow', dead);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
  return dead
    ? btnSt('dead',  'BC_Gray', dead)   // deadline
    : btnSt('error', 'BC_Gray', dead);  // no user response or invalid data
}

const ResponseStatus = ({ state }: { state: TResponseState; }) => {
  const { dic } = useLocaleCtx();

  const Column: React.FC<{
    Icon:  (props: React.ComponentProps<'svg'>) => JSX.Element;
    color: string;
    st:    typeof state;
  }> = ({ Icon, color, st, children }) =>
    <div {...cLo("flex flex-col justify-center")}>
      <Icon {...cLo("w-10 h-10 p-1 text-white rounded-full shadow-md", color,
        (st === state) ? "border-2 border-indigo-600" : "opacity-30")}
      />
      <div {...cCo("text-sm", st === state, "font-bold text-indigo-600", "text-gray-600")}>
        {children}
      </div>
    </div>;

  return (
    <div {...cLo("flex flex-row items-center justify-around w-40")}>
      <Column Icon={CheckIcon} color="bg-green-400"  st='attend'>{dic('btn_attend')}</Column>
      <Column Icon={XIcon}     color="bg-red-400"    st='absence'>{dic('btn_absence')}</Column>
      <Column Icon={ClockIcon} color="bg-yellow-400" st='undecided'>{dic('btn_etc')}</Column>
    </div>
  );
}

export const gatherUserLocal = () => {
  return navigator.userAgent
  ? ({ userAgent: navigator.userAgent })
  : null;
}

/**
 * returns Promise<Response> of fetch API
 * From 2021-08-05, server sends recent data as a result (previously just sent "RSVP set" text.)
 */
export const sendFetchJson = async (
  grp: IRsvpParams['grp'],
  tgt: IRsvpParams['tgt'],
  onFetchCB: (srvAct: IServerActions) => void,    // callback on fetch success
  json: Nullable< Partial<IUserLocal> > = null
) => {
  // Check validity before server request
  if (tgt.length < 20 || tgt.length > 21) {  // length should be 20 or 21
    console.log("sendFetchJson:Invalid tgt format!", tgt);
    return null;
  }
  const ua = gatherUserLocal();
  if (!json && !ua) {
    console.log("No data to send");
    return null;
  }
  // --------------------------------------------------------------------------------
  let res = await fetch( urlArg(grp, tgt,
    json && JSON.stringify(json),
    ua   && JSON.stringify(ua)) );
  // --------------------------------------------------------------------------------
    // .then(response => { console.log(response); response.json(); })
  if (res.ok) {
    console.log("sendFetchJson:", res);
    const dataServer = await res.json();
    onFetchCB?.(dataServer);
    setLSParamJson(grp, tgt, JSON.stringify(dataServer));
  } else {
    console.error("sendFetchJson:Fetch error!", res.status, res.statusText);
  }
}

/**
 *  open state is used to open SideOver for attendancy forms
 *  or for inquiries when the user was 
 */
const RSVP = () => {
  const [open, setOpen] = useState(false);  // SideOver
  const { grp, tgt } = useParams<IRsvpParams>();
  const cUser = useCurrentUser();
  const { dic } = useLocaleCtx();

  const [srvAct, setSrvAct] = useState< Nullable<IServerActions > >(null);  // server data

  const handlePress = () => {
    setOpen(!open);   // if not valid srvAct, redirect to info
  }

  // const handleSignup = () => {
  //   window.location.href = "https://ev.spacetimeq.com/profile";
  // }

  // const query = new URLSearchParams(useLocation().search);
  useEffect(() => {
    // If tgt key exists in LocalStorage, read from it to avoid network request.
    const af = async () => {
      // // ------------------------------------------------------------
      // const json = await fetchUserIP();
      // // ------------------------------------------------------------
      // if (json) {
      //   console.log(json);
      //   setULocal(json);
      // } else {
      //   console.error("Fetch error!", json.status, json.statusText);
      // }
      sendFetchJson(grp, tgt, setSrvAct);  // without choice result
    }
    const dataLocal = getLSParamJson(grp, tgt); // If we have data saved in LS
    if (dataLocal) {  // already called the first fetch
      console.log("Data loaded from LocalStorage:", dataLocal);
      setSrvAct(JSON.parse(dataLocal));
    } else {  // but when do we need to update from server data?
      af();
    }
    console.log("RSVP:useEffect()");
  }, [grp, tgt, srvAct?.time])

  // console.log(grp, tgt, "uLocal:", uLocal);
  // console.log("uServer:", uServer, timeUMTlocal(uServer?.time));
  const resState = responseState(srvAct);

  const resSubmitted = resState.state === 'attend'
                    || resState.state === 'absence';

  // const steps: ISteps[] = [  // status should be set in useEffect?
  //   { id: '01', name: dic('step_invitation'), status: stepsStatus(true,          srvAct?.email),
  //     href: srvAct?.email ? undefined : handlePress
  //   },
  //   { id: '02', name: dic('step_response'),   status: stepsStatus(srvAct?.email, resSubmitted),
  //     href: handlePress
  //   },
  //   { id: '03', name: dic('step_signup'),     status: stepsStatus(!!cUser, false),
  //     // href: handleSignup
  //   },
  //   { id: '04', name: dic('step_event'),      status: stepsStatus(false,  false) },
  // ];
  const twFCR = "flex flex-col md:flex-row";  // column <-> row
  const twFRC = "flex flex-row sm:flex-col lg:flex-row";

  if (!srvAct && cUser) {
    setSrvAct({
      email:     cUser.email || '',
      lastName:  '',
      firstName: '',
      company:   cUser.org
    });
  }
  const invitee = srvAct
    ? ((srvAct.company   || '') + ' ' +
       (srvAct.lastName  || '') + ' ' +
       (srvAct.firstName || '') + ' ' +
       srvAct.email) + ` [${dic('temporal_auth')}]`
       // srvAct.email) + ` [${dic(cUser ? 'login' : 'temporal_auth')}]`
    : null;

  if (srvAct?.email) {  // Save for guided signup
    setLS('refEmail', srvAct.email);
    setLS('rsvp', `${grp}/${tgt}`);
  }

  return (
    <>
      <SideOver {...{open, setOpen}}
        onOkCancel={(ok: boolean) => alert(ok)}
      >
      {grp === 'weworkcom'
      ? <RsvpForm {...{grp, tgt, srvAct, setSrvAct}}
          dead={resState.dead}
          onClose={() => setOpen(false)}
        />
      : grp === 'weworktech'
        ? <RsvpFormWT {...{grp, tgt, srvAct, setSrvAct}}
            dead={resState.dead}
            onClose={() => setOpen(false)}
          />
        : null}
      </SideOver>
      <div>
        {/*
        <StepsPanel {...cLo("bg-gray-100")} {...{steps}} />
        */}
        <Event {...{grp}} cat={srvAct?.cat || 'C'}>
          <p {...cLo("absolute top-0 right-0 text-sm text-indigo-600 sm:-mt-6 mr-4")}>
            {invitee || '出欠回答とためには、E-mailの招待状リンクからアクセスしてください。'}
          </p>
          <TooltipSpan
            {...cLo("flex justify-center")}
            clsPos="z-50 inset-y-0 right-0 mt-6 p-1"
            clsSize="w-40 h-16 font-normal text-base"
            tip="ボタンを押下してください。"
          >
            <div {...cLo(twFRC, "justify-between sm:justify-center mb-2")}>
              <div {...cLo("flex flex-shrink-0 justify-center m-2")}>
                <ButtonC
                  classX={cL(resState.color,
                    "px-8 py-4 text-base text-xl rounded-3xl TextShadowB")}
                  onPress={() => handlePress()}
                >
                  {dic('btn_attendancy')}<br/>{dic(resState.dead ? 'btn_review' : 'btn_reply')}
                </ButtonC>
              </div>
              <div {...cLo("flex justify-center m-2 bg-gray-100 p-4 rounded-full shadow-lg")}>
                <ResponseStatus state={resState.state} />
              </div>
            </div>
          </TooltipSpan>
          <div {...cLo(twFCR, "w-full")}>
            <div {...cLo(twFCR, "justify-around items-center",
              "bg-indigo-100 rounded-full m-2 p-4 w-full shadow-lg")}
            >
              <Countdown
                aDead={[
                  resSubmitted
                    ? 0
                    : srvAct?.deadline || 0,
                  eventDate[grp]?.getTime()
                ]}
              />
            </div>
            <EmailInfo />
          </div>
        </Event>
        <Copyright />
      </div>
    </>
  );
}

export const EmailInfo: IFClassName = ({ className }) =>
  <div {...cLo("flex flex-col justify-center ml-1", className)}>
    <a href="mailto:event@spacetimeq.com" rel="noreferrer" target="_blank">
      <div {...cLo("flex flex-col items-center")}>
        <MailIcon {...cLo("w-10 h-10 text-gray-500")} />
        <span {...cLo("text-2xs whitespace-nowrap")}>お問い合わせ</span>
        <span {...cLo("text-3xs whitespace-nowrap")}>event@spacetimeq.com</span>
      </div>
    </a>
  </div>;

/**
 * Representation of the countdown data
 */
const Countdown = (props: ICountdownProps) => {
  const { aDead } = props;
  const cd = useCountdown(props);
  const twDigital = "FontDigitalN text-indigo-400";

  if (cd.pos === 2) {  // past
    return <span {...cLo("font-bold text-xl")}>{-cd.days}日前のイベント</span>;
  }
  const tD = msToMMDDhhmm(aDead[cd.pos]);  // target date
  const twFCR = "flex flex-col lg:flex-row";
  return (
    <span {...cLo(twFCR, "justify-center items-center font-bold")}>
      <span {...cLo("flex flex-col")}>
        <span {...cCo("text-xl", cd.pos === 0, "text-red-400", "text-indigo-600")}>
          {['回答〆切り日', '開催日'][cd.pos]}
        </span>
        <span {...cLo("text-lg")}>{tD}</span>
      </span>
      <span {...cLo("text-base text-gray-400 lg:mr-2")}>まで</span>
      <span {...cLo("flex flex-row items-end whitespace-nowrap")}>
        <span {...cLo(twDigital, "text-6xl tracking-wider mx-2")}>{cd.days}</span>
        <span {...cLo("flex items-top")}>
          <span {...cLo("text-3xl mb-1")}>日</span>
          <span {...cLo(twDigital, "text-4xl tracking-wider ml-2")}>{cd.hhmm}</span>
          <span {...cLo(twDigital, "text-xl tracking-wider ml-1 align-top")}>{cd.ss}</span>
        </span>
      </span>
    </span>
  );
}

export default RSVP;

/*=============================================================================
 CalToday.tsx - Calendar Today

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { useState, useEffect, useRef } from 'react';
import { useDarkThemeCtx } from 'ui/darkThemeCtx';
import { cLo } from 'utils/util';
import { todayData, weekName, emojiMoon, brightnessMoon } from 'utils/calendar';
import { twDow } from './Calendar';

export const CalToday: IFClassName = ({ className }) => {
  const { year, month, date, dow, moon } = todayData();
  return (
    <div {...cLo(className, "p-6 text-center font-serif dark:text-gray-200 text-gray-800")}>
      <div className="pb-6 truncate TextShadow-md">
        <span className="text-3xl">{year}</span><br/>
        <span className="text-6xl">{month}/{date}</span>
        <span {...cLo(twDow(dow), "text-4xl")}>{weekName(dow, 'jp')}</span>
      </div>
      <MoonPhase age={moon} force />
      <LocaleTime />
    </div>
  );
}

/**
 * Later replace emoji with symbolic3d moon phase
 */
const MoonPhase = ({ age, force }: { age: number; force: boolean }) => {
  const { dark } = useDarkThemeCtx();
  const emoji = emojiMoon(age, force ? 1 : undefined);  // tolerance: undefinded - use default
  if (!emoji)
    return null;
  const br = 2 + Math.round( 18 * brightnessMoon(age) );  // min:2 .. max:20
  return (
    <div {...cLo("relative py-4")}>
      <span
        className="pr-2 text-9xl"
        style={{textShadow:`${dark ? "lightcyan" : "black"} 1px 1px ${br}px`}}
      >
        {emoji}
      </span>
      <span className="absolute text-base dark:text-yellow-300 text-gray-500">
        {age.toFixed(1)}
      </span>
    </div>
  );
}

/**
 * toLocalTimeString('en-US', { hour12: 24 })  // 24:01:48 ...bug
 */
const LocaleTime = () => {
  const [time, setTime] = useState<Date>(new Date());
  const [h24,  setH24]  = useState(false);   // toggle 24 hour mode
  const tz = useRef< Nullable<string[]> >(null);  // timezone
  useEffect(() => {
    tz.current = Intl.DateTimeFormat().resolvedOptions().timeZone.split('/');
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);
    return () => clearInterval(timer);
  }, []);
  return (
    <div {...cLo("py-4 flex justify-center")}>
      <div {...cLo("FontDigital text-3xl text-xs cursor-pointer",
        "dark:text-cyan-200 text-cyan-400")}
        onClick={() => setH24(!h24)}
      >
        {time.toLocaleTimeString('en-US', { hour12: h24 })}
      </div>
      {tz.current &&
        <div {...cLo("flex flex-col font-mono ml-2 text-left")}>
          <span {...cLo("text-3xs")}>{tz.current[0]}</span>
          <span {...cLo("text-xs bg-gray-600 text-white p-0.5 rounded-md")}>{tz.current[1]}</span>
        </div>}
    </div>
  );
}

/*=============================================================================
 ctcsAPI.ts - ctcs database APIs abstraction layer

 (C) 2021 SpacetimeQ INC.
=============================================================================*/
import firebase from './firebase';
import type { ICtc, ICtcCreate, ICtcUp, } from 'models';
import { fsPath, serverTS, serializeTS, } from './apiCommon';
import { errorFmt, } from 'utils/util';
import type { Update } from '@reduxjs/toolkit'
import { CREQ, WARN_REQ_IN_QUEUE } from './reqQueue';

//-----------------------------------------------------------------------------
// Ctcs
//-----------------------------------------------------------------------------
/**
 * fetch ctcs: wrapper of the firebase API
 */
export const fetchCtcAPI = (id: string): Promise<ICtc> =>
  new Promise<ICtc>(async (resolve, reject) => {
    if (!CREQ.addReq(id, "fetchCtcAPI")) {
      reject(WARN_REQ_IN_QUEUE);
      return;
    }
    try {
      //-----------------------------------------------------------------------
      // ctc document, read
      //-----------------------------------------------------------------------
      const doc = await firebase.firestore()
        .doc(fsPath.ctc(id))
        .get();
      //-----------------------------------------------------------------------

      if (doc.exists) {
        const data = doc.data(); // need to exclude non-serializables such as createdAt
        // console.log(doc.id, " => ", data);
        if (data) {
          console.log(data);
          resolve({  // order matters: cratedAt should be put last not to be overwritten
            id,
            ...(data as ICtcCreate),
            ...serializeTS('createdAt', data.createdAt),
            ...serializeTS('updatedAt', data.updatedAt),
          });
        } else {
          reject(errorFmt('ERROR', "fetchCtcAPI", "Null data"));
        }
      } else {
        reject(errorFmt('ERROR', "fetchCtcAPI", `${fsPath.ctcs}/${id} not found!`));
      }
    } catch (error) {
      reject(error);
    } finally {
      CREQ.removeReq(id);
    }
  });

/**
 * query contact info
 */
export const queryCtcByEmail = (email: string): Promise<ICtc> =>
  new Promise<ICtc>(async (resolve, reject) => {
    if (!CREQ.addReq(email, "queryCtcByEmail")) {
      reject(WARN_REQ_IN_QUEUE);
      return;
    }
    try {
      //-----------------------------------------------------------------------
      // ctcs collection
      //-----------------------------------------------------------------------
      const querySnapshot = await firebase.firestore()
        .collection(fsPath.ctcs)
        .where("email", "==", email.toLowerCase())
        .get();
      //-----------------------------------------------------------------------
      // console.log("querySnapshot size:", querySnapshot.size);
      if (querySnapshot.empty) {
        reject(null);
      } else {
        querySnapshot.forEach(doc => {
          // doc.data() is never undefined for query doc snapshots
          // need to exclude non-serializables such as createdAt
          const { createdAt, updatedAt, ...data } = doc.data();
          resolve({  // order matters: cratedAt should be put last not to be overwritten
            id: doc.id,
            ...(data as ICtcCreate),
            ...serializeTS('createdAt', createdAt),
            ...serializeTS('updatedAt', updatedAt),
          });
        });
      }
    } catch (error) {
      reject(error);
    } finally {
      CREQ.removeReq(email);
    }
  });

/**
 * update ctc with the given data, and create if not exist
 *  @param id ICtcUp
 *  @returns Update<ICtc>
 *  ISSUE: 'createdAt' property with serialized time is not excluded.
 */
export const updateCtcAPI = (ctc: ICtcUp): Promise< Update<ICtc> > => {
  return new Promise< Update<ICtc> >(async (resolve, reject) => {
    const { id, ...data } = ctc;
    try {
      //-----------------------------------------------------------------------
      // ctc document, create or update
      //-----------------------------------------------------------------------
      if (id) {
        console.log("✅updateCtcAPI - data:", data);
        await firebase.firestore()
          .doc(fsPath.ctc(id))
          .update({
            ...data,  // should not include createdAt: Be sure to Omit it
            ...serverTS('updatedAt'),
          });  // Promise<void>
        //-----------------------------------------------------------------------
        console.log("ctc successfully updated:", id);
        resolve({ id, changes: { ...data } });
      } else {
        const docRef = await firebase.firestore()
          .collection(fsPath.ctcs)
          .add({  // should not include id
            ...data,
            ...serverTS('createdAt'),  // should come later not to be overwritten
            });  // Promise<void>
        //-----------------------------------------------------------------------
        console.log("ctc successfully created:", docRef.id);
        resolve({ id: docRef.id, changes: { ...data } });
      }
    } catch (error) {
      reject(error);
    }
  });
}

export const queryCtcIDByEmail = (email: string): Promise<Nullable< TCtcID >> =>
  new Promise<Nullable< TCtcID >>(async (resolve, reject) => {
    if (!CREQ.addReq(email, "queryCtcIDByEmail")) {
      reject(WARN_REQ_IN_QUEUE);
      return;
    }
    try {
      //-----------------------------------------------------------------------
      // ctcs collection
      //-----------------------------------------------------------------------
      const querySnapshot = await firebase.firestore()
        .collection(fsPath.ctcs)
        .where("email", "==", email.toLowerCase())
        .get();
      //-----------------------------------------------------------------------
      // console.log("querySnapshot size:", querySnapshot.size);
      if (querySnapshot.empty) {
        resolve(null);  // Ctc Not found
      } else {
        querySnapshot.forEach(doc => resolve(doc.id));
      }
    } catch (error) {
      reject(error);
    } finally {
      CREQ.removeReq(email);
    }
  });



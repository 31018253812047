/*=============================================================================
 PageNotFound.tsx - 

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { Copyright, } from 'ui/ui';

/**
 * https://images.unsplash.com/photo-1593486544625-13ef2368e43a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2225&q=80
 * https://github.com/dashgon/dashgon.github.io/blob/master/public/stq/SpacetimeQ-neon.jpg?raw=true
 */
const PageNotFound = () => {
  return (
    <>
      <div className="relative text-center">
        <img className="w-full object-cover filter dark:blur-sm"
          src="https://github.com/dashgon/dashgon.github.io/blob/master/public/stq/SpacetimeQ-neon.jpg?raw=true"
          alt="event"
        />
        <div className="absolute inset-0 font-bold TextShadowB-md text-white mt-5 md:mt-10">
          <h1 className="text-5xl font-bold text-red-300 FontElite">Oops! Page Not Found</h1>
          <p className="text-2xl mt-6 text-yellow-300 FontTegomin">ページが見つかりません！</p>
        </div>
        <Copyright />
      </div>
    </>
  );
}

export default PageNotFound;

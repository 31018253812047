/*=============================================================================
 authContext.tsx - authentication state

 - Changed from userContext to authContext, due to the buggy behavior of useSelector

 (C) 2021 SpacetimeQ INC.
=============================================================================*/
import { createContext, useContext, } from 'react';
import type { IAuthState } from 'features/auth/authSlice';
import { useAuthStateChanged } from 'api/authAPI';

/**
 * Context Provider
 */
const AuthContext = createContext<IAuthState>({
  user:     null,
  pending:  false,
});

/**
 * Context Consumer using Hooks
 */
export const useAuthCtx = () => useContext(AuthContext);

export const AuthProvider: React.FC = ({ children }) => {
  const auth = useAuthStateChanged();  // subscribe to the authentication state changes
  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  );
}

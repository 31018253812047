/*=============================================================================
 PDFViewModal.tsx - PDF Viewer in Modal dialog

 - https://github.com/wojtekmaj/react-pdf
 - pdf.js - "Warning: TT: undefined function" - bad font recovery messages
 - Warning: loadFont - translateFont failed: "UnknownErrorException:
   The CMap "baseUrl" parameter must be specified,
   ensure that the "cMapUrl" and "cMapPacked" API parameters are provided."

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { useState, useEffect, } from 'react';
import { cL, cLo, cCo, } from 'utils/util';
import { useLocaleCtx, } from 'ui/locale/LocaleCtx';
import { useWindowSize } from 'utils/windows';
import { ModalDlg, } from 'ui/ModalDlgTw';
import { ALink, } from 'ui/ui';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';  // ES6 modules
import { DocumentDownloadIcon, } from '@heroicons/react/outline';

interface IPDFViewModalProps extends IModalDlgStates {
  src:   string;  // PDF src url
  title: string;
};

export const PDFViewModal = ({ src, title, open, setOpen }: IPDFViewModalProps) => {
  const { dic } = useLocaleCtx();
  const { w: maxWidth, } = useWindowSize();
  const [page,   setPage]   = useState(1);   // current PDF page
  const [nPages, setNPages] = useState(0);   // number of PDF pages
  const [prog,   setProg]   = useState(0);   // progress
  const [eMsg,   setEMsg]   = useState('');  // error message
  const nextPage = () => (page + 1 > nPages) ? page : page + 1;
  const prevPage = () => (page - 1 <= 0)     ? 1    : page - 1;
  const twArrowBtn = cL("w-8 h-8 text-xl font-bold bg-black text-white rounded-full p-0.5",
    "shadow-md cursor-pointer transition duration-300 ease-in-out");
  useEffect(() => {
    setPage(1);
    setNPages(0);
  }, [src]);  // need to initialize when src changes
  return (
    <ModalDlg {...{open, setOpen, title}}
      ok={dic('close')}
      twCont="max-w-screen"
    >
      <Document
        file={src}
        loading="ファイル読み込み中"
        error={`フィアル読み込み失敗:${src}`}
        onLoadProgress={({ loaded, total }) => setProg( (loaded / total)*100 )}
        onLoadSuccess={pdf => {
          setPage(1);
          setNPages(pdf.numPages);
          setEMsg('');
        }}
        onLoadError={error => setEMsg(error.message)}
      >
        {nPages
        ? <>
            <div {...cLo("flex flex-row border border-gray-600 shadow-md")}>
              <Page
                loading="ページ読み込み中"
                pageNumber={page}
                width={Math.min(maxWidth-80, 800)}
              />
            </div>
            <div {...cLo("relative flex flex-row justify-center space-x-2 py-2")}>
              <div {...cCo(twArrowBtn, page === 1, "bg-gray-300", "hover:bg-red-400")}
                onClick={() => setPage(prevPage())}
              >←</div>
              <div {...cLo("p-0.5")}>
                {page} / {nPages}
              </div>
              <div {...cCo(twArrowBtn, page === nPages, "bg-gray-300", "hover:bg-red-400")}
                onClick={() => setPage(nextPage())}
              >→</div>
              <ALink href={src} download>
                <DocumentDownloadIcon
                  {...cLo("absolute w-8 h-8 right-0 transition duration-300 ease-in-out",
                  "text-yellow-500 hover:scale-125 opacity-80 hover:opacity-100")}
                />
              </ALink>
            </div>
          </>
        : <div {...cLo("min-h-[800px] max-w-[800px]")}>
            {eMsg || `読み込み中... ${prog}%`}
          </div>
        }
      </Document>
    </ModalDlg>
  );
}

/*=============================================================================
 SideDrawer.tsx - SideDrawer menu, Sidebar with Horizontal or Vertical move

 - Changed from 'absolute bottom-0 left-0' to 'fixed' to be from the y-scroll
 - Auto close sidebar on clicking any menu
 - To animate the pushing effect with padding: add to talwind.config.js
    transitionProperty: { spacing: 'margin, padding',
   and at the main content: 
    className="transform transition-spacing ease-in-out duration-300 sm:duration-500"
 - To generalize the solution, need to bring out the control buttons. Then using callback will
   make the connections complicated.
 - Abstracted away the inner content using Render Prop as a children

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { useState, useRef } from 'react';
import { cL, cCo, cLo, cLoIf, } from 'utils/util';
import { DrawerAnchorButton, } from 'ui/ui';
import { TooltipSpan } from 'ui/Tooltip';

export interface ISideDrawerProps {
  disabled?: boolean;  // disable the button
  right?:    boolean;  // on the right side (default is from the left)
  vertical?: boolean;  // vertical move (then right means down)
  dimBack?:  boolean;  // dim background
  refPush?:  React.RefObject<HTMLDivElement>;  // ref to <div> to be pushed away
  tooltip:   string;
  children:  (closeCB: () => void) => React.ReactNode;  // render prop
};
/**
 * Sliding side drawer with an ancher button
 * pushes the background content if the ref to the background div is givien
 * @param children a render prop that will be given with a callback on menu click
 * Let push work only when to be pushed clientWidth is at least 2x longer.
 * - Initially shown NOT works.
 */
const SideDrawer = ({
  disabled, right, vertical, dimBack, refPush, tooltip, children
}: ISideDrawerProps) => {
  const [show, setShow] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);  // to get the clientWidth of the drawer

  const pushMain = (nextShow: boolean) => {
    if (refPush && refPush.current && ref.current) {
      const dir = right
        ? vertical ? 'bottom' : 'right'
        : vertical ? 'top'    : 'left';
      const len = nextShow
        ? vertical
          ? ref.current.clientHeight
          : (refPush.current.clientWidth > ref.current.clientWidth * 2)
            ? ref.current.clientWidth
            : 0
        : 0;
      refPush.current.setAttribute("style", `padding-${dir}:${len}px;`);
    }
    setShow(nextShow);
  }
  const handlePressAnchorButton = () => {  // toggle drawer on the anchor button click
    if (!disabled)
      pushMain(!show);
  }
  /**
   * so that the children can close itself, such as closing on the menu item (children) click
   * @param nShow should be set true to update the padding on the client resize
   */
  const closeCB = () => {
    if (show)  // only when the children is open/shown
      pushMain(false);
  }
  if (show) {
    if (disabled) {
      pushMain(false);  // to hide the drawer when disabled
    } else {  // Adjustment for the cases when vertical state switches on window resize
      if (vertical) {  // We cannot get the drawer's size correctly at this rendering phase.
        if (refPush?.current?.style.paddingLeft) {
          refPush.current.setAttribute("style", `padding-left:0px;`);
        }
      } else {
        if (refPush?.current?.style.paddingTop) {
          refPush.current.setAttribute("style", `padding-top:0px;`);
        }
      }
    }
  }
  return (
    <div {...cLoIf(!disabled)}>
      <div {...cCo("fixed top-1 z-[60]", right, "right-1", "left-1")}>
        <TooltipSpan
          {...(right && { clsPos: "z-50 inset-y-0 right-0 mt-6 p-1" })}
          clsSize="w-44 h-8 font-normal text-sm"
          tip={tooltip}
        >
          <DrawerAnchorButton
            {...{show, vertical, right}}
            onPress={handlePressAnchorButton}
          />
        </TooltipSpan>
      </div>
      <aside {...{ref}}
        {...cLo("fixed flex flex-wrap z-20",
          right && (vertical ? "bottom-0" : "right-0"),
          !show && "pointer-events-none")}
      >
        <div {...cLo("relative dark:bg-gray-900 bg-gray-100 Drawer_transition",
            vertical ? "w-screen" : "h-screen",
            show
            ? cL("shadow-md", vertical ? "translate-y-0" : "translate-x-0")
            : right
              ? vertical ?  "translate-y-full" :  "translate-x-full"
              : vertical ? "-translate-y-full" : "-translate-x-full")}
        >
          {children(closeCB)}
        </div>
      </aside>
      <div {...cLoIf(!disabled && show && dimBack, "fixed inset-0 z-10 bg-black opacity-25")} />
    </div>
  );
}

export default SideDrawer;

// import { ToggleButtonSvg } from 'ui/ui';

/*=============================================================================
 ModalDialog.tsx - react-aria Modal Dialog

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { useRef, } from 'react';
import { cLo, } from 'utils/util';
import { DarkThemeProvider, } from './darkThemeCtx';
// react-aria ------------------------------------------------------------------------------------
import type { OverlayProps }    from '@react-types/overlays';
import type { AriaDialogProps } from '@react-types/dialog';
import { useOverlay, usePreventScroll, useModal } from '@react-aria/overlays';
import { useDialog }  from '@react-aria/dialog';
import { FocusScope } from '@react-aria/focus';   // to manage focus for its descendants
// react-aria ------------------------------------------------------------------------------------

interface IModalDialogProps extends OverlayProps, AriaDialogProps {
  title:         string;
  clsBgOpacity?: string;   // tailwind class bg-opacity-
  clsDialog?:    string;
  onClose:       () => void;
  isDismissable: boolean;
};
/**
 * Modal Dialog using react-aria
 */
export const ModalDialog: React.FC<IModalDialogProps> = props => {
  // const [position, setPosition] = useState<IPosition>({ x: 100, y: 100 });
  const { title, children, clsBgOpacity = "bg-opacity-20", clsDialog } = props;

  // Handle interacting outside the dialog and pressing the Escape key to close the modal.
  const ref = useRef<HTMLDivElement>(null);
  const { overlayProps } = useOverlay(props, ref);

  // Prevent scrolling while the modal is open,
  // and hide content outside the modal from screen readers.
  usePreventScroll();
  const { modalProps } = useModal();

  // Get props for the dialog and its title
  const { dialogProps, titleProps } = useDialog(props, ref);

  return (  // using ReactPortal that is outside the normal DOM node, we need another darkmode ctx
    <DarkThemeProvider>
      <div {...cLo("fixed flex inset-0 items-center justify-center bg-black z-50",
        clsBgOpacity)}
      >
        <FocusScope contain restoreFocus autoFocus >
          <div {...{ref}}
            {...cLo(clsDialog)}
            {...overlayProps}
            {...dialogProps}
            {...modalProps}
          >
            <h1 {...titleProps}
              {...cLo("font-mono font-bold text-center rounded-t-md",
                "dark:bg-gray-400 dark:text-gray-700",
                "bg-gray-700 text-gray-100")}
            >
              {title}
            </h1>
            {children}
          </div>
        </FocusScope>
      </div>
    </DarkThemeProvider>
  );
}

// Draggable does not pass through click event on the Responsive mode
// import Draggable from 'react-draggable';
// <Draggable nodeRef={ref}>
//
// , "cursor-move"
// import { useMove }    from '@react-aria/interactions';
// interface IPosition {
//   x: number;
//   y: number;
// };
//
  // const { moveProps } = useMove({
  //   onMoveStart(e) { console.log("MoveStart", e); },
  //   onMove(e) {
  //     console.log(e.deltaX, e.deltaY);
  //     setPosition({x: position.x + e.deltaX, y: position.y + e.deltaY});
  //   },
  //   onMoveEnd(e) { console.log("MoveEnd", e); },
  // });
// {...moveProps}
// style={{ position: 'absolute', left: position.x, top: position.y }}

/*=============================================================================
 inputForms.tsx - general input forms

 - InputText
  |
  +- InputEmail
  |
  +- InputPassword - checks security score

 - tailwindcss/forms forces type text and password border color to blue on focus

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { useState } from 'react';
import { addIf, cC, cCo, cLo, } from 'utils/util';
import { useLocaleCtx } from 'ui/locale/LocaleCtx';
import { RegEx_Auth } from 'utils/RegEx';
import { SvgIcon, SvgIconToggle, } from 'utils/svg';
import type { TPathDataKey, } from 'utils/svg';

const SCORE_COLORS = [  // 0..10
  "border-gray-300",
  "border-red-500",
  "border-red-400",
  "border-red-300",
  "border-red-200",
  "border-yellow-500",
  "border-yellow-400",
  "border-yellow-300",
  "border-green-300",
  "border-green-400",
  "border-green-500",
];

interface IInputScoredTextProps extends React.InputHTMLAttributes<HTMLInputElement> {
  contClass?: TClassName;    // container className
  iconPath?:  TPathDataKey;  // left icon Path
  iconVLen?:  number;        // icon View Length
  scoreFunc?: (text: string) => number;  // validity score (0 to 10 points) function callback
};
/**
 * Input text with an icon and score function
 */
const InputScoredText: React.FC<IInputScoredTextProps> = ({
  contClass,
  className: cn,  // className for <input>
  iconPath,
  iconVLen,
  scoreFunc,
  value,
  children,  // for additional right icon
  ...props   // rest
}) =>
  <div {...cLo("relative text-gray-400 focus-within:text-purple-600", contClass)}>
    <div className="absolute mt-1 inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
      {iconPath &&
      <SvgIcon
        className="h-5 w-5"
        Path={iconPath}
        {...(iconVLen && { vLen: iconVLen })}
      />}
    </div>
    <input {...{value}}
      {...cLo("py-2 my-1 font-mono bg-white placeholder-gray-400 rounded-br-lg shadow-md",
        "dark:focus:ring focus:ring dark:focus:ring-yellow-200 focus:ring-purple-300",
        "border-t-4", SCORE_COLORS[scoreFunc ? scoreFunc(value as string) : 0],
        "appearance-none w-full block pl-12 focus:outline-none", cn)}
      {...props}
    />
    {children}
  </div>;

/**
 * Email score for a valid email address
 * 0..10
 */
function scoreEmail(text: string): number {
  if (RegEx_Auth.email.test(text)) return 10;  // /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
  let score = 0;
  if (text.length > 5)      score += 2;
  else if (text.length > 2) score += 1;
  if (text.includes('.'))   score += 2;
  if (text.includes('@'))   score += 4;
  return score;
}

/**
 * Password score for a secure password
 * 0..10
 */
function scorePassword(text: string): number {
  let score = 0;  // Lower case, Upper case, Numbers, Symbols
  if (text.length > 7)             score += 3;
  else if (text.length > 0)        score += 1;
  if (RegEx_Auth.pw_uppercase.test(text)) score += 2;  // upper case
  if (RegEx_Auth.pw_special.test(text))   score += 2;  // special character
  if (RegEx_Auth.pw_number.test(text))    score += 2;  // number
  if (RegEx_Auth.pw_lowercase.test(text)) score += 1;  // lower case
  return score;
}

/**
 * input email
 */
export const InputEmail = ({ scoreFunc, ...props }: IInputScoredTextProps) =>
  <InputScoredText
    id="email"
    className="text-gray-900"
    placeholder="Email address"
    iconPath="email_auth"
    iconVLen={20}
    scoreFunc={scoreEmail}
    {...props}
  />;

interface IInputPasswordProps extends IInputScoredTextProps {
  show?: boolean;  // if defined, this is for the secondary confirm password input
};
/**
 * for password input
 * The secondary 'password2' input has show attribute to be enabled only in Signup mode.
 */
export const InputPassword = ({
  contClass, show, type, scoreFunc,
  ...props
}: IInputPasswordProps) => {
  const { dic } = useLocaleCtx();
  const PASSWORD = "password"
  const [conceal, setConceal] = useState(true);      // conceal text
  const secondary = show !== undefined;

  return (
    <InputScoredText
      id={PASSWORD + addIf(secondary, '2')}
      {...cCo("text-gray-400", secondary, "bg-gray-100")}
      type={conceal ? PASSWORD : "text"}
      placeholder={dic(secondary ? 'confirm_password' : 'password')}
      iconPath="lock_auth"
      contClass={secondary
        ? cC("z-0 transform transition ease-in-out duration-300",
            show, "traslate-y-0", "-translate-y-full")
        : "z-10"}
      scoreFunc={scorePassword}
      {...props}
    >
      <div {...cLo("absolute inset-y-0 right-2 pl-3 flex items-center cursor-pointer")}
        onClick={() => setConceal(!conceal)}
      >
        <SvgIconToggle className="h-5 w-5" cond={conceal} Path="eye_hide" Path2="eye_show" />
      </div>
    </InputScoredText>
  );
}

/**
 * Labeled checkbox
 */
export const LabeledRadio: React.FC< React.InputHTMLAttributes<HTMLInputElement> > = ({
  children, className: cn,
  ...props
}) =>
  <label {...cLo("cursor-pointer")}>
    <input
      type="radio"
      {...cLo("form-radio rounded-full cursor-pointer",
        "focus:ring focus:ring-offset-1 focus:ring-purple-600", cn)}
      {...props}
    />
    {children}
  </label>;

export const LabeledCheckbox: React.FC< React.InputHTMLAttributes<HTMLInputElement> > = ({
  children, className: cn,
  ...props
}) =>
  <label htmlFor={props.id} {...cLo("cursor-pointer")}>
    <input
      type="checkbox"
      {...cLo("form-checkbox rounded-sm cursor-pointer",
        "focus:ring focus:ring-offset-1 focus:ring-purple-600", cn)}
      {...props}
    />
    {children}
  </label>;

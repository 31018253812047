/*=============================================================================
 authPage.tsx - authentication page

 (C) 2020 SpacetimeQ INC.
=============================================================================*/
import { useAuthCtx } from 'app/AuthContext';
import { AuthForms }  from './AuthForms';
import { ErrorDlg, PendingDlg, } from 'ui/forms';
// import { useFromPath } from 'ui/layout/AuthRoute';
// import { cLo, } from 'utils/util';
import Profile from 'features/profile/ProfilePage';
// import Home from 'features/home/Home';
import { detectMobile, } from 'utils/util';

// const HeadBand = () => {
//   const fromPath = useFromPath();
//   return (
//     <h1 {...cLo("bg-gradient-to-r from-white via-black to-gray-50",
//         "text-lg text-center font-mono font-extrabold py-2")}
//     >
//       <span className="py-1 rounded-md">
//         <span className="text-blue-400">Space</span>
//         <span className="text-green-400">time</span>
//         <span className="text-red-400">Q</span>
//       </span>
//       {fromPath && <span className="text-sm text-white ml-1">{fromPath}</span>}
//     </h1>
//   );
// }

export default function AuthPage() {
  // const auth = useSelector(selectAuth);
  const auth = useAuthCtx();
  console.log("AuthPage:", auth);
  const isMobile = detectMobile();

  return (
    <div className="relative">
      {!isMobile &&
      <img className="absolute inset-x-0 top-0 w-full object-cover filter blur-sm"
        src="https://github.com/dashgon/dashgon.github.io/blob/master/public/stq/spacetimeq-neon-front.jpg?raw=true"
        alt="event"
      />}
      {
      auth.pending
      ? <PendingDlg />
      : auth.user
        ? <Profile />
        : <AuthForms />
      }
      {auth.error && <ErrorDlg error={auth.error} />}
    </div>
  );
}

// import { Redirect } from 'react-router-dom';
// ? <Redirect to={{ pathname: "/profile" }} />

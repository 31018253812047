/*=============================================================================
 ui/Avatars.tsx - Avatar UIs

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { ANON_AVATAR, } from 'app/res';
import { cL, cLo, cCo, } from 'utils/util';
import { getUserById, } from 'features/users/usersSlice';
import type { IRoom, } from 'models';
import { LImg } from 'utils/Image';

/**
 * border color of avatar image, according to the order of members in the room
 */
export const avatarBdrClr = (idx: number) => {
  // For tailwindcss, class names should appear in entirety not to be omitted in production code.
  const BORDER_COLORS = [  // defined in _app.tw.scss
    "Avt_bdr_00",
    "Avt_bdr_01",
    "Avt_bdr_02",
    "Avt_bdr_03",
    "Avt_bdr_04",
    "Avt_bdr_05",
    "Avt_bdr_06",
  ];
  return BORDER_COLORS[idx >= 0 ? idx % BORDER_COLORS.length : 0];
}

/**
 * TODO: check for utf8
 */
function initials(name: string0U) {
  let init: string = '?';
  if (name) {
    const ns = name.split(' ');
    if (ns[0])
      init = ns[0][0].toUpperCase();
    init += ns[1] ? ns[1][0].toUpperCase() : ns[0][1]?.toLowerCase();
  }
  return init;
}

interface IAvatarProps {
  url?:        string0U;
  name?:       string0U;    // user name
  bdrClr?:     TClassName;  // border color class
  clsRounded?: TClassName;
  clsText?:    TClassName;
};
/**
 * Avatar img or initials
 */
const AvatarOrInitial = ({
  url, name, bdrClr,
  clsRounded = "rounded-full",
  clsText    = "text-2xl",
}: Omit<IAvatarProps, 'on'>) => {
  const twInner = cL("w-full h-full shadow-md", clsRounded, bdrClr, bdrClr && "border-2");
  return url
  ? <LImg src={url}
      {...cLo("object-cover", twInner)}
      errorImg={ANON_AVATAR}
      alt="avatar"
    />
  : <p {...cLo("Avt_ini", clsText, twInner)}>
      {initials(name)}
    </p>;
}

interface IAvatarImgProps extends IClassNameObj, IAvatarProps {
  noImg?: boolean;   // don't show img just for the layout (for consecutive tweets)
};
/**
 * Avatar image
 */
export const AvatarImg: React.FC<IAvatarImgProps> = ({
  className: cn = "w-10 h-10",  // size for the Chat panel
  noImg,
  children,  // <OnLight on={!!onColor} {...{onColor}} pulse />
  ...props
}) =>
  <div {...cLo("Avt_img", cn)}>
    {!noImg && <>
      <AvatarOrInitial {...props} />
      {children}
    </>}
  </div>;

/**
 * TODO: working on any number of images
 */
export const AvatarComposition: React.FC<{
  imgs: readonly string0U[];  // array of image urls
}> = ({ imgs, children }) => {
  return (
    <div className="relative flex flex-shrink-0 w-12 h-12">
      <LImg src={imgs[0] || ANON_AVATAR}
        {...cCo("object-cover rounded-md shadow-md", imgs[1],
          "absolute w-8 h-8 ml-4", "w-full h-full")}
        errorImg={ANON_AVATAR}
        alt="avatar"
      />
      {imgs[1] &&
        <LImg src={imgs[1]}
          {...cLo("absolute object-cover w-8 h-8 mt-4 rounded-lg shadow-md")}
          errorImg={ANON_AVATAR}
          alt="avatar2"
        />}
      {children}
    </div>
  );
}

/**
 * on status light
 */
export const OnLight: IFClassName<{
  pulse?:   boolean;
  onColor?: TailwindColor<'bg'>;
}> = ({
  pulse,
  onColor = "bg-green-500",
  className = "w-2.5 h-2.5"
}) =>
  <div {...cLo("Avt_on", pulse && "animate-pulse")}>
    <div {...cLo(className, onColor, "rounded-full")} />
  </div>;

/**
 * Avatar images list for a room with on(joined in) status
 * 'relative' is required for the 'absolute' position of on light
 */
export const AvatarRoomImgs: IFClassName<{ room: IRoom; clsSize?: TClassName; }> = ({
  room,
  className,
  clsSize = "w-8 h-8",
}) => {
  return (
    <div {...cLo("flex flex-row flex-wrap", className)}>
      {room.users.map((uid, i) =>
        <AvatarByUid key={uid}
          {...cLo(clsSize, "-ml-1")}
          {...{uid}}
          bdrClr={avatarBdrClr(i)}
        >
          <OnLight
            {...cLo("w-1.5 h-1.5")}
            onColor={room.admins?.includes(uid) ? "bg-pink-500" : "bg-green-500"}
          />
        </AvatarByUid>
      )}
      {room.xUsers?.map((uid, i) =>
        <AvatarByUid key={uid}
          {...cLo(clsSize, "-ml-1")}
          {...{uid}}
          bdrClr={avatarBdrClr(room.users.length + i)}
        />
      )}
    </div>
  );
}

interface IAvatarByUidProps extends Omit<IAvatarProps, 'url'|'name'>, IClassNameObj {
  uid:       TUserID;
  clsLabel?: TClassName;  // set any class such as "text-3xs" to show label (name or email)
};
/**
 * Avatar by uid
 * @param uid UserID
 */
export const AvatarByUid: React.FC<IAvatarByUidProps> = ({
  uid, className, bdrClr, clsLabel,
  clsRounded = "rounded-full",
  clsText    = "text-lg",
  children
}) => {
  const user = getUserById(uid);
  const name = user?.name || user?.email;
  return (
    <>
      <div {...cLo("Avt_img", className)}>
        <AvatarOrInitial
          {...{name, bdrClr, clsRounded, clsText}}
          url={user?.photoURL}
        />
        {children}
      </div>
      {clsLabel &&
        <p {...cLo("flex truncate nowrap", clsLabel)}>
          {name}
        </p>}
    </>
  );
}

/**
 * Avatar image by uid
 */
export const AvatarImgByUid = ({ uid, ...props }: IAvatarByUidProps) => {
  const user = getUserById(uid);  // Not a hook, so doesn't be refreshed
  return (
    <AvatarImg
      url={user?.photoURL}
      name={user?.name || user?.email}
      {...props}
    />
  );
}

/*=============================================================================
 authAPI - authentication APIs abstraction layer
         - make the application including reducrers be decoupled from the firebase API details

 *** Check out common patterns in the auth function calls:
 firebase.auth().
   signInWithEmailAndPassword    (email: string, password: string): Promise<UserCredential>;
   createUserWithEmailAndPassword(email: string, password: string): Promise<UserCredential>;
   GoogleAuthProvider implements firebase.auth.AuthProvider
   signInWithCredential          (credential: AuthCredential):      Promise<UserCredential>;
   signInWithPopup               (provider: AuthProvider):          Promise<UserCredential>;
   signInWithRedirect            (provider: AuthProvider):          Promise<void>;
   getRedirectResult             ():                                Promise<UserCredential>;
   onAuthStateChanged(
     nextOrObserver: Observer<any> | ((a: User | null) => any),
     error?:         (a: Error) => any,
     completed?:     firebase.Unsubscribe
   ): firebase.Unsubscribe;
   singOut(): Promise<void>;
   
 UserCredential: {
   additionalUserInfo?: AdditionalUserInfo | null;
   credential:          AuthCredential     | null;
   operationType?:      string             | null;  // 'signIn', 'link', 'reauthenticate'
   user:                User               | null
 }

 firebase.User
 firebase.auth.Error { code: string, message: string }
 firebase.auth.AuthCredential {
   providerId:    string;  // 'google.com', 'facebook.com'
   signInMethod:  string;  // 'password', 'emailLink'
   toJSON():      Object;
   fromJSON(json: Object | string): AuthCredential | null;
 }

 (C) 2020 SpacetimeQ INC.
=============================================================================*/
import { useEffect } from 'react';
import firebase  from 'api/firebase';
import type { IUserEx, TUser0, ICtc, } from 'models';
import { useAppDispatch, } from 'app/store';
import { useSelector } from 'react-redux';
import { stateChanged, selectAuth, } from 'features/auth/authSlice';
import { resetUsers, fetchUser, createUser, updateUser, } from 'features/users/usersSlice';
import { resetCtcs, fetchCtc, fetchCtcByEmail, }  from 'features/ctcs/ctcsSlice';
import { resetRooms } from 'features/rooms/roomsSlice';
import { resetMsgs }  from 'features/msgs/msgsSlice';
import { errorFmt } from 'utils/util';
import { unwrapResult } from '@reduxjs/toolkit';

export type TAPIUser = Nullable<firebase.User>;
//type TUserCredential = firebase.auth.UserCredential | void;
export type TAuthProps = {  // Command and Arguments required to call firebase APIs
  command: 'signInEmail'
         | 'createUser'
         | 'signInGoogle'
         | 'signInGoogleR'
         | 'signOut'
         | 'sendEmailVerification';
  email?:  string;
  pwd?:    string;
};

/**
 * wrapper for firebase.auth().onAuthStateChanged()
 * 1. abstract away **firebase dependency**
 * 2. extract user fields (take only serializable parts for the redux)
 */
export const useAuthStateChanged = () => {
  const dispatch = useAppDispatch();     // dispatch is stable and won't change on re-renders
  const auth = useSelector(selectAuth);  // Hook that will reflect the changes

  useEffect(() => {  // When the page reloaded, this will reflect the current login state
    // The observer is only triggered on sign-in or sign-out.
    const unsubscribe = firebase.auth().onAuthStateChanged(async (fu: TAPIUser) => {
      const user = extractUser(fu);
      console.log("onAuthStateChanged:", fu || "sign-out");
      dispatch( stateChanged(user) );
      if (user) {
        try {
          // ------------------------------------------------------------------------
          const resultAction = await dispatch( fetchUser(user.uid) );
          // ------------------------------------------------------------------------
          unwrapResult(resultAction);  // extract payload
          // console.log("fetchUser resultAction:", resultAction);
          const uData = resultAction.payload as IUserEx;
          const { uid, emailV } = user;
          if (emailV && !uData.emailV) {  // need to sync emailVerified
            dispatch( updateUser({ uid, emailV }) );
          }
          if (uData.ctcId) {
            dispatch( fetchCtc(uData.ctcId) );
          } else {
            if (user.email) {
              const res = await dispatch( fetchCtcByEmail(user.email) );
              unwrapResult(res);  // extract payload
              const ctc = res.payload as ICtc;
              if (ctc) {
                const { org } = ctc;
                dispatch( updateUser({ uid, org, ctcId: ctc.id }) );
              }
            }
          }
        } catch (error) {
          if ((error as IError).code === 'unavailable') {
            console.log("dispatch ( fetchUser ):", error);
            alert("サーバ接続エラー!");
          } else {
            // ------------------------------------------------------------------------
            // If the user is not registered at the app level (not firebase auth level)
            dispatch( createUser(user) );  // create app user data
            // ------------------------------------------------------------------------
          }
        }
      } else {
        console.log("Resetting redux states");
        dispatch( resetUsers() );
        dispatch( resetRooms() );
        dispatch( resetMsgs()  );
        dispatch( resetCtcs()  );
      }
    });
    console.log("🍏 subscribed to the Firebase onAuthStateChanged()");
    return () => {
      unsubscribe();
      console.log("🍎 unsubscribed Firebase onAuthStateChanged()");
    }
  }, [dispatch]);

  return auth;
}

/**
 * extract basic data from firebase authenticated user
 * returns TUser from the (firebase) TAPIUser
 */
const extractUser = (au: TAPIUser): TUser0 => {
  return au  // authenticated user
  ? ({
    uid:      au.uid,
    email:    au.email,
    name:     au.displayName,
    photoURL: au.photoURL,
    emailV:   au.emailVerified,
  })
  : null;
}

/**
 * wraps firebase APIs with command string and
 * extracts some fields from the firebase.User result.
 * Actually user data is retrived at onAuthStateChanged, no need to return user here.
 * To abstract away firebase auth functions that return UserCredential
 * - They are not serializable and hard to be handled within the reducers
 */
export function authAPI({
  command,  // singOut, createUser, signInEmail, signInGoogle, ...
  email,    // required for signIn or createUser
  pwd       // password
}: TAuthProps): Promise<TUser0> {
  const auth = firebase.auth();
  return new Promise<TUser0>( async (resolve, reject) => {
    let promise: Promise<firebase.auth.UserCredential>;
    switch (command) {
      // ------------------------------------------------------------------------
      // APIs that return Promise<void>
      // ------------------------------------------------------------------------
      case 'signOut':
        auth.signOut();
        return resolve(null);
      case 'sendEmailVerification':
        sendEmailVerification();
        return resolve(null);
      // ------------------------------------------------------------------------
      // APIs that return Promise<firebase.auth.UserCredential>
      // ------------------------------------------------------------------------
      case 'createUser':
        promise = auth.createUserWithEmailAndPassword(email!, pwd!);
        break;
      case 'signInEmail':
        promise = auth.signInWithEmailAndPassword(email!, pwd!);
        break;
      case 'signInGoogle':
      case 'signInGoogleR':
        const provider = new firebase.auth.GoogleAuthProvider();
        // Optional: Specify additional OAuth 2.0 scopes
        provider.addScope("email");
        if (command === 'signInGoogleR') {
          firebase.auth().signInWithRedirect(provider);
          promise = auth.getRedirectResult();
        } else {
          promise = auth.signInWithPopup(provider);
        }
        break;
      default:
        return reject(errorFmt('ERROR', "authAPI", "No matching API command!"));
    }
    // Actually, user data is retrieved on stateChanged reducer,
    // we may not need the following process.
    try {
      const res = await promise;        // Now convert Promise<UserCredential> -> Promise<TUser0>
      console.log(res);
      return resolve(extractUser(res?.user));  // extracts necessary fields
    } catch (error) {
      return reject(error);
    }
  });
};

/**
 * firebase authenticated *currentUser*
 */
export const currentUser = () => firebase.auth().currentUser;

export async function sendEmailVerification() {
  const user = firebase.auth().currentUser;
  if (!user)
    return null;
  try {
    await user.sendEmailVerification();
  } catch (error) {
    console.error("sendEmailVerification", error);
    alert(error);
  }
}

/*=============================================================================
 usersSlice.ts - Cache of users data (kind of a local DB)

 - When should the cache be updated?

 This is the local user info list
 1. current user's registered data (basic data overlapping with the auth user)
 2. other frequently used user data such as:
 - contacts (friends) list
 - authors of the posts
 - notification senders

 (C) 2020 SpacetimeQ INC.
=============================================================================*/
import { createSlice, createAsyncThunk, createEntityAdapter, createSelector,
} from '@reduxjs/toolkit';
import type { EntityState, Update, } from '@reduxjs/toolkit';
import type { TRootState } from 'app/store';
import type { IUser, IUserEx, IUserUp, } from 'models';
import { USERS } from 'api/apiCommon';
import { fetchUserAPI, createUserAPI,
  updateUserAPI, updateUserContactsAPI, updateUserRoomsAPI, } from 'api/usersAPI';
import type { IUpdateUserContactsProps, IUpdateUserRoomsProps, } from 'api/usersAPI';
// for utility functions
import { useAuthCtx } from 'app/AuthContext';
import { useSelector } from 'react-redux';
import { store, errorOut, } from 'app/store';

const usersAdapter = createEntityAdapter<IUserEx>({
  selectId: user => user.uid,  // ID is other than user.id
});

interface IUserState extends EntityState<IUserEx> {};
const initialState: IUserState = usersAdapter.getInitialState();

/**
 * fetch User info by id (document id = user.uid)
 */
export const fetchUser = createAsyncThunk<
  IUserEx,  // Return type    of the payloadCreator
  TUserID   // First argument to the payloadCreator
>(
  `${USERS}/fetchUser`,      // type
  async (uid: TUserID) => {  // payloadCreator
    return await fetchUserAPI(uid); // ******************* API call
  }
);

/**
 * create User by id (document id = user.uid)
 */
export const createUser = createAsyncThunk<
  IUserEx,  // Return type    of the payloadCreator
  IUser     // First argument to the payloadCreator
>(
  `${USERS}/createUser`,    // type
  async (user: IUser) => {  // payloadCreator
    return await createUserAPI(user); // ******************* API call
  }
);

/**
 * update user with *Update<IUserEx>*
 */
export const updateUser = createAsyncThunk<
  Update<IUserEx>,  // Return type    of the payloadCreator
  IUserUp           // First argument to the payloadCreator
>(
  `${USERS}/updateUser`,     // type
  async (user: IUserUp) => { // payloadCreator
    return await updateUserAPI(user); // ******************* API call
  }
);

/**
 * update contacts with *type Update<IUserEx>*
 * @reduxjs/toolkit/dist/typings.d.ts
 * export declare type Update<T> = {
 *   id: EntityId;
 *   changes: Partial<T>; };
 */
export const updateUserContacts = createAsyncThunk<
  Update<IUserEx>,          // Return type    of the payloadCreator
  IUpdateUserContactsProps  // First argument to the payloadCreator
>(
  `${USERS}/updateUserContacts`,  // type
  async (props: IUpdateUserContactsProps) => { // payloadCreator
    return await updateUserContactsAPI(props); // ******************* API call
  }
);

/**
 * update Rooms with *Update<IUserEx>*
 */
export const updateUserRooms = createAsyncThunk<
  Update<IUserEx>,       // Return type    of the payloadCreator
  IUpdateUserRoomsProps  // First argument to the payloadCreator
>(
  `${USERS}/updateUserRooms`,  // type
  async (props: IUpdateUserRoomsProps) => { // payloadCreator
    return await updateUserRoomsAPI(props); // ******************* API call
  }
);

const usersSlice = createSlice({
  name: USERS,
  initialState,
  reducers: {
    userAdded:     usersAdapter.addOne,
    userRemoved:   usersAdapter.removeOne,
    userUpsertOne: usersAdapter.upsertOne,
    userUpdateOne: usersAdapter.updateOne,
    resetUsers:    _state => initialState,
  },
  extraReducers: builder => {
    builder
    // fetchUser ----------------------------------------------
    .addCase(fetchUser.fulfilled,  (state, action) => { usersAdapter.addOne(state, action); })
    .addCase(fetchUser.rejected,  (_state, action) => { errorOut(action.error); })
    // createUser ---------------------------------------------
    .addCase(createUser.fulfilled, (state, action) => { usersAdapter.addOne(state, action); })
    .addCase(createUser.rejected, (_state, action) => { errorOut(action.error, true); })
    // updateUser ---------------------------------------------
    .addCase(updateUser.fulfilled, (state, action) => { usersAdapter.updateOne(state, action); })
    .addCase(updateUser.rejected, (_state, action) => { errorOut(action.error, true); })
    // updateUserContacts -------------------------------------
    .addCase(updateUserContacts.fulfilled, (state, action) => { usersAdapter.updateOne(state, action); })
    .addCase(updateUserContacts.rejected, (_state, action) => { errorOut(action.error, true); })
    // updateUserRooms ----------------------------------------
    .addCase(updateUserRooms.fulfilled, (state, action) => { usersAdapter.updateOne(state, action); })
    .addCase(updateUserRooms.rejected, (_state, action) => { errorOut(action.error, true); })
  }
});
export default usersSlice.reducer;

export const {
  userAdded,
  userRemoved,
  userUpsertOne,
  userUpdateOne,
  resetUsers
} = usersSlice.actions;

/**
 * The entity adapter will contain a getSelectors() function that returns a set of selectors
 * that know how to read the contents of an entity state object.
 * Each selector function will be created using the createSelector function from Reselect,
 * to enable memoizing calculation of the results.
 */
export const {
  selectAll:      selectAllUsers,  // maps over the state.ids arrays, and returns an array of entities
  selectById:     selectUserById,
  selectIds:      selectUserIds,       // state.ids array
  // selectEntities: selectUserEntities,  // state.entities lookup table
} = usersAdapter.getSelectors((state: TRootState) => state.users);

export const selectUserByEmail = createSelector(
  [selectAllUsers, (_state: TRootState, email: string) => email],
  (users, email) => users.filter(user => user.email === email)
);

//-----------------------------------------------------------------------------
// utility functions
//-----------------------------------------------------------------------------
/**
 * current user with only the firebase authentication info
 */
export const useCurrentUser = () => {
  const user = useAuthCtx().user;
  // Since hooks cannot be called conditionally, we pass an invalid uid for an error case
  // ux (IUserEx) may not be available when the user is not registered yet
  return useSelector((state: TRootState) => selectUserById(state, user ? user.uid : -1));
}

export const useUserById = (uid: TUserID) =>
  useSelector((state: TRootState) => selectUserById(state, uid));

export const useUserByEmail = (email: string) =>
  useSelector((state: TRootState) => selectUserByEmail(state, email));

export const useAllUsers = () =>
  useSelector((state: TRootState) => selectAllUsers(state));

//-----------------------------------------------------------------------------
// utility functions - No hooks
//-----------------------------------------------------------------------------
export const isUserById = (uid: TUserID) =>
  store.getState().users.ids.includes(uid);  // ES6 includes

/**
 * Need to find a way to get a Promise for entities not loaded yet.
 */
export const getUserById = (uid: TUserID): Undefinable<IUserEx> => { // not a hook
  const users = store.getState().users;
  return users.entities[uid];
}

export const isSelf = (uid: TUserID) => {
  const user = store.getState().auth.user;
  return user ? uid === user.uid : false;
}

export const getCurrentUserEx = () => {
  const state = store.getState();
  const uid = state.auth.user?.uid;
  return uid ? state.users.entities[uid] : null;
}

/*=============================================================================
 calendar.ts - calendar utility functions

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import SEKKI    from 'asset/sekki.json';
import CALENDAR from 'asset/calendar.json';

//-------------------------------------------------------------------------------
// CONSTANTS for Calendar: to provide semantics to numbers
//-------------------------------------------------------------------------------
export const DEG0   = 0;    // 0°
export const DEG360 = 360;  // 360°
//-------------------------------------------------------------------------------
export const H24 = 24;      // 24 hours / day
export const M60 = 60;      // 60 min / hour
export const S60 = 60;      // 60 sec / min
export const M1000 = 1000;  // milliseconds / sec
//-------------------------------------------------------------------------------
export const M12 = 12;      // months
export const DW7 = 7;       // days / week
export const SD24 = 24;     // 二十四節気 Sekki Division
export const SD72 = 72;     // 七十二候
export const SD72_24 = 72/24;    // ratio of SD72 over SD24
export const AVG365 = 365.2425;  // Average days in a year
//-------------------------------------------------------------------------------
export type TSekkiDivision = 24 | 72;
//-------------------------------------------------------------------------------
export const deg2rad = (deg: number) => deg*Math.PI/180;
export const MOONAGE_MAX = 29.53;

// months: year * 12 + month(0..11) to Date format
export interface IYM {
  year:  number;
  month: number;  // 0..11
};
export interface IYMD extends IYM {
  date: number;
};
export interface IYMDW extends IYMD {
  dow:  number;  // 0..6
};

export const isTodayDate = (dt: Date) => {
  const today = new Date();
  return sameDate(dt, today);
}

export const sameDate = (date1: Date | number, date2: Date | number) => {
  const isSameDate = (d1: Date, d2: Date) =>
    d1.getDate()     === d2.getDate() &&
    d1.getMonth()    === d2.getMonth() &&
    d1.getFullYear() === d2.getFullYear();

  if (date1 instanceof Date &&
      date2 instanceof Date)
    return isSameDate(date1, date2);

  const dt1 = date1 instanceof Date ? date1 : new Date(date1);
  const dt2 = date2 instanceof Date ? date2 : new Date(date2);
  return isSameDate(dt1, dt2);
}

export const todayData = () => {
  const today = new Date();
  const year  = today.getFullYear();
  const month = today.getMonth() + 1;
  const date  = today.getDate();
  const dow   = today.getDay();
  const moon  = moonAgeYMD({ year, month: month-1, date });
  return ({ year, month, date, dow, moon });
}
export const monthsToYM = (months: number): IYM =>
  ({
    year:  Math.floor(months / M12),
    month: months % M12  // 0..11
  });
export const todayMonths = () => {
  const today = new Date();
  return today.getFullYear() * M12 + today.getMonth();
}

type TLanguageSet = 'en'|'jp'|'kr'|'jp6';
export const weekName  = (i: number, lang: TLanguageSet = 'en') => CALENDAR.WeekHead[lang][i];
export const sekkiName = (sd: TSekkiDivision) => SEKKI.title[sd];

/**
 * Leap year in the Gregorian calendar
 */
export const IsLeapYear = (yr: number) =>
  ( (yr % 4   === 0) &&
    (yr % 100 !== 0) ) ||
    (yr % 400 === 0);

/**
 * build object IYMD from IYM and date
 */
export const oYMDym = (ym: IYM, date: number): IYMD => ({ year: ym.year, month: ym.month, date });

export const dowFirstDayInYM = (ym: IYM) => new Date(ym.year, ym.month, 1).getDay();   // 0..6
// export const daysInYM        = (ym: IYM) => new Date(ym.year, ym.month, 0).getDate();  // days in a month

/**
 * total days in a month
 * m: 0..11
 * Gregorian Calendar: Sep. 1752 -> 1,2,14,..30 (11 days advanced)
 */
export const daysInYM = (ym: IYM) => {
  const DAYS_COUNT = {
    0: 31,
    1: IsLeapYear(ym.year) ? 29 : 28,
    2: 31,
    3: 30,
    4: 31,
    5: 30,
    6: 31,
    7: 31,
    8: (ym.year === 1752) ? 19 : 30,
    9: 31,
    10: 30,
    11: 31
  };
  return DAYS_COUNT[ym.month as keyof typeof DAYS_COUNT];
}

export const ymdToISOString = (ymd: IYMD) =>
  new Date(Date.UTC(ymd.year, ymd.month, ymd.date)).toISOString();

/**
 * Count the number of days with the year up to the specified date
 * m starts from 1
 */
export const NthDayInYear = (yr: number, m: number, d: number) => {
/*
  let mo = 1;
  let days = 0;
  while (mo < m) {
    days += DaysInMonth(mo, yr);  // add up days to the previous month
    mo++;
  }
*/
  // For the optimized performance, avoid the loop and use the pre-calculated array
  // accumulated days until the month
  const accDays = [
    0,
    31,
    31+28,
    31+28+31,
    31+28+31+30,
    31+28+31+30+31,
    31+28+31+30+31+30,
    31+28+31+30+31+30+31,
    31+28+31+30+31+30+31+31,
    31+28+31+30+31+30+31+31+30,
    31+28+31+30+31+30+31+31+30+31,
    31+28+31+30+31+30+31+31+30+31+30
  // 1, 2, 3, 4, 5, 6, 7, 8, 9,10,11 month
  ];
  if (m < 1 || m > M12)
    return 0;  // valid range check
  return (m > 2 && IsLeapYear(yr))
          ? accDays[m-1] + d
          : accDays[m-1] + d + 1;
}

//=============================================================================
/**
 * get Julian Day Number - Invented in 1583 by Joseph Scaliger
 * The 7980 year cycle (solar, lunar, and a particular Roman tax cycle)
 * Jan.1,4713 B.C. at noon GMT ~ Jan.22,3268 at noon GMT
 * The number of days from Jan. 1, 4713 B.C. at 12:00:00 GMT, until Jan. 1, 1970 at 00:00:00 UTC
 * A day is 86,400 seconds long. UNIX TIM / 86400000 is the number of days since Jan. 1, 1970
 * new Date gives you the number of seconds from epoch until whatever loca ltime your computer has
 */
export const getJulianDay = (date: Date) => (date.getTime() / 86400000.0 + 2440587.5);

/**
 * SOURCE: https://news.local-group.jp/moonage/moonage.js.txt
 * middle of the dayp
 */
export const moonAgeYMD = (d: IYMD) => getMoonAge(new Date(d.year, d.month, d.date, 12));
export const getMoonAge = (date: Date) => {  // 0..29.53, 15: full moon
  // 新月日計算
  // 引数  　julian  ユリウス通日
  // 戻り値  与えられたユリウス通日に対する直前の新月日(ユリウス日)
  const getNewMoon = (j: number) => {
    const n1 = 2451550.09765;
    const n2 = 29.530589;
    const n3 = 0.017453292519943;
    const k  = Math.floor((j - n1) / n2);
    const t  = k / 1236.85;
    return (
        n1
      + n2 * k
      + 0.0001337 * t * t
      - 0.40720   * Math.sin((201.5643 + 385.8169 * k) * n3)
      + 0.17241   * Math.sin((2.5534   +  29.1054 * k) * n3)
    );
  }
  
  const jd = getJulianDay(date);
  // console.log('Julian: ', jl);
  let nm = getNewMoon(jd);
  // getNewMoonは新月直前の日を与えるとうまく計算できないのでその対処
  // (一日前の日付で再計算してみる)
  if (nm > jd)
    nm = getNewMoon(jd - 1.0);
  // console.log('Moon age:', jd - nm);
  return (jd - nm);  // moon's age at current time
}

/**
 * 360 degree
 */
export const NormalizeDegree = (deg: number) => {
  if (deg >= DEG0 && deg < DEG360)  // Don't allow 360, it should be rewinded to 0.
    return deg;
  let nd = deg % DEG360;
  if (nd < 0)
    nd += DEG360;
  return nd;
}

//=============================================================================
/**
 * Position of the Sun: https://en.wikipedia.org/wiki/Position_of_the_Sun
 * Precision: compared to 国立天文台＞暦計算室＞暦象年表
 * Error range: 1.26 ~ 15.41 min (0.0106 degree) for 2019年二十四節気 視黄経
 * (Tested at Dec.6,2018)
 */
export const getPosSun = (date: Date, calcDistance: boolean = true) => {
  const n = getJulianDay(date) - 2451545.0;
  // The mean longitude of the Sun, corrected for the aberration of light
  const L = NormalizeDegree(280.460 + 0.9856474*n);  // in degree
  // The mean anomaly of the Sun
  const g = deg2rad(357.528 + 0.9856003*n);  // in radian
  // The ecliptic longitude of the Sun
  return {
    longitude: NormalizeDegree(L + 1.915*Math.sin(g) + 0.020*Math.sin(2*g)),
    distance:  calcDistance ? 1.00014 - 0.01671*Math.cos(g) - 0.00014*Math.cos(2*g) : null
  };
}

//=============================================================================
/**
 * 8 phases can be expressed in the default emojis
 *
 *        0🌑         360°/8 = 45°
 *    7🌘     1🌒     avg max age 29.53, but it can exceed 29.7
 *
 *  6🌗    🌎   2🌓   set tolerance = 1 to output for any date
 *
 *    5🌖     3🌔     - output the phase of the moon only for the day when
 *        4🌕           it falls on the 8 significant phases (each 45°)
 */
export const emojiMoon = (age: number, tolerance: number = .28) => {
  const moon = ['🌑', '🌒', '🌓', '🌔', '🌕', '🌖', '🌗', '🌘'];
  const f = age / (MOONAGE_MAX / moon.length);
  const i = Math.trunc(f);
  return Math.abs(f - i) <= tolerance ? moon[i >= moon.length ? 0 : i] : null;
}

// brightness of the moon from age: 0..1
export const brightnessMoon = (age: number) => {
  const rot = (age % MOONAGE_MAX) / MOONAGE_MAX;
  return (1 - Math.cos(rot * Math.PI * 2)) / 2;
}

//=============================================================================
/**
 * 二十四節気 360°/24 = 15°
 * 七十二候   360°/72 = 5°
 * minimize the expensive call to getPosSun
 * need to test if the exact angle of each sekki falls in the day
 * Sun's travel angle per day fluctuates so cannot apply the average angle/day
 * wrap the angle 360° to 0°
 * returning id:
 * starting 1 from 立春 or 東風解凍
 *
 * 七十二候
 * - s72[0..23][0..2][0..2]  for each 24 節気 3 候, 0:name,1:読み,2:意味
 * -  hsla(hue,100%,50%,opacity)
 *
 * TODO
 * - Cache the calculated sekki data of each year and build a hash table
 * - Since 24 sekki are included in 72 ko, need just 72 mm-dd's per year.
 */
export const sekkiYmd = (ymd: IYMD, div?: TSekkiDivision) =>
  sekki(new Date(ymd.year, ymd.month, ymd.date), div);
export const sekki = (date: Date, div: TSekkiDivision = SD24) => {
  //-------------------------------------------
  let { longitude: l_from } = getPosSun(date, false);  // date should be given at 00:00
  //-------------------------------------------
  const DEG360_d = DEG360 - 3;
  const f = l_from / (DEG360 / div);  // float id
  let i = Math.round(f);
  const diff = Math.abs(f - i);
  if (diff > (div / 280))  // verify if the day really falls in the sekki angle
    return null;
  if (i >= div)
    i = 0;
  if (l_from > DEG360_d)
    l_from -= DEG360;
  const angle_sekki = i * (DEG360 / div);
  if (l_from > angle_sekki)
    return null;
  const dms = H24 * M60 * S60 * M1000;  // day in ms
  //-------------------------------------------------------------------
  let { longitude: l_to } = getPosSun(new Date(date.getTime() + dms), false);
  //-------------------------------------------------------------------
  if (l_to > DEG360_d)
    l_to -= DEG360;
  // console.log(date.toISOString(), diff.toFixed(3), angle_sekki, l_from, l_to,
  //             (angle_sekki > l_to) ? "❌" : "✅");
  if (l_to < angle_sekki)
    return null;
  if (div === SD24) {
    return ({
      id:  1 + (i + 3) % div,  // 1..24 二十四節気の立春 that is 4th in data but first in real life
      s24: SEKKI.s24[i],
      s72: undefined,
      hue: SEKKI.s24hue[i]
    });
  }
  // div === 72
  const i24  = Math.trunc(i / SD72_24);  // 3 comes from SD72 / SD24
  const i24r = i % (SD72_24);
  const sk72 = SEKKI.s72[i24][i24r];
  return ({
    id:  1 + (i + 9) % div,  // 1..72 七十二候の東風解凍 that is 10th in data but first in real life
    s24: i24r === 0 ? SEKKI.s24[i24] : undefined,
    s72: {
      name: sk72[0],
      yomi: sk72[1],
      full: sk72[2],
    },
    hue: SEKKI.s24hue[i24]
  });
}

/*
const dateTimeFormat = new Intl.DateTimeFormat('ja-JP-u-ca-japanese', {
  era: 'long',
  year: 'numeric',
  month: 'long',
  day: '2-digit',
});
console.log(dateTimeFormat.format(new Date()));
令和3年6月20日
 */

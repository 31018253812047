/*=============================================================================
 msgsAPI.ts - msgs database APIs abstraction layer

 (C) 2021 SpacetimeQ INC.
=============================================================================*/
// ----------------------------------------------------------------------------
// constants for *Slice reducers; use constants for consistency
// ----------------------------------------------------------------------------
import firebase from './firebase';
import type { IMsgCreate,  IMsg,  IMsgUp, } from 'models';
import { fsPath, serverTS, } from './apiCommon';
import type { Update } from '@reduxjs/toolkit'

//-----------------------------------------------------------------------------
// Room Msgs
//-----------------------------------------------------------------------------
/**
 * There are two ways to create a new document in the Firestore
 * 1. db.collection('room-id').doc('msg-id').set(data)
 * 2. db.collection('room-id').add(data)  with a generated id
 *   returns document id
 */
export const addMsgAPI = (msg: IMsgCreate): Promise<TMsgID> => {
  const { roomId, ...data } = msg;  // roomId is just for path, not saved to server
  return new Promise<TMsgID>(async (resolve, reject) => {
    try {
      //-----------------------------------------------------------------------
      // msgs collection, add a new message
      //-----------------------------------------------------------------------
      const docRef = await firebase.firestore()
        .collection(fsPath.msgs(roomId))
        .add({
          ...data,
          ...serverTS('time'),  // should come later not to be overwritten
        });  // Promise<DocumentReference<T>>
      //-----------------------------------------------------------------------
      // console.log("msg sent:", docRef.id);  // msg id is generated
      resolve(docRef.id);
    } catch (error) {
      reject(error);
    }
  });
}

/**
 * update msg
 */
export const updateMsgAPI = (msg: IMsgUp): Promise< Update<IMsg> > => {
  const { roomId, id, ...data } = msg;
  return new Promise< Update<IMsg> >(async (resolve, reject) => {
    try {
      //-----------------------------------------------------------------------
      // user document, update
      //-----------------------------------------------------------------------
      await firebase.firestore()
        .doc(fsPath.msg(roomId, id))
        .update(data);  // Promise<void>
      //-----------------------------------------------------------------------
      // console.log("msg successfully updated:", id);
      resolve({ id, changes: { ...data } });
    } catch (error) {
      reject(error);
    }
  });
}

/*=============================================================================
 store.ts - redux store

 1. Authenticated user
 2. Users
 3. Rooms
 4. Msgs (for a room)
 5. Ctcs (contacts)

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { useDispatch }    from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { SerializedError, } from '@reduxjs/toolkit';
import authReducer        from 'features/auth/authSlice';
import usersReducer       from 'features/users/usersSlice';
import roomsReducer       from 'features/rooms/roomsSlice';
import msgsReducer        from 'features/msgs/msgsSlice';
import ctcsReducer        from 'features/ctcs/ctcsSlice';

/**
 * One Source of Truth
 */
export const store = configureStore({
  reducer: {
    auth:  authReducer,
    users: usersReducer,
    rooms: roomsReducer,
    msgs:  msgsReducer,
    ctcs:  ctcsReducer,
  },
  devTools: process.env.NODE_ENV !== 'production'
});

export type TRootState = ReturnType<typeof store.getState>;
export type TDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<TDispatch>();

/**
 * ErrorMessage
 * compare with ErrorDlg in forms.tsx
 */
export const errorOut = (e: SerializedError, modal: boolean = false) => {
  const msg = e.code + " : " + e.message;
  return e.code === 'WARN'
  ? console.log(msg)
  : modal
    ? alert(msg)
    : console.error(msg);
}

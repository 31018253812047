/*=============================================================================
 res.ts - resources for the application

 (C) 2021 SpacetimeQ INC
=============================================================================*/

export const ANON_AVATAR  = '/img/icon-anonymous.png';
export const FALLBACK_BKG = '/osanbashi.jpg';

let gLastUniqueId = 1000;  // unique within this app's instance'
/**
 * generate Unique Id
 * @param prefix default 'stq'
 */
export const genUniqueId = (prefix: string = 'stq') => {
  gLastUniqueId++;
  return `${prefix}${gLastUniqueId.toString()}`;
}

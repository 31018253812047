/*=============================================================================
 Namecard.tsx - Business namecard

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { useState, useCallback, } from 'react';
import type { ICtc, } from 'models';
import { useCtcByEmail, updateCtc, } from 'features/ctcs/ctcsSlice';
import { useLocaleCtx, } from 'ui/locale/LocaleCtx';
import { useAppDispatch, } from 'app/store';
import { unwrapResult } from '@reduxjs/toolkit';
import { cL, cC, cLo, cLoIf, errorFmt, } from 'utils/util';
import { ErrorDlg } from 'ui/forms';
import { ButtonC, } from 'ui/ui';
import { ModalDlg, } from 'ui/ModalDlgTw';

interface IEmail {
  email: string;
};
interface INamecardProps extends IEmail, IEditable {};

export const Namecard: IFClassName<INamecardProps> = ({ className, email, editable }) => {
  const { dic } = useLocaleCtx();
  const ctcExample = {
    id:     '',  // meaning ctc is not fetched (yet)
    email,
    fName:  dic('ctc0_fName'),
    lName:  dic('ctc0_lName'),
    fNameL: dic('ctc0_fNameL'),
    lNameL: dic('ctc0_lNameL'),
    org:    dic('ctc0_org'),
    orgSub: dic('ctc0_orgSub'),
    orgUrl: dic('ctc0_orgUrl'),
    dept:   dic('ctc0_dept'),
    title:  dic('ctc0_title'),
    zip1:   dic('ctc0_zip1'),
    addr11: dic('ctc0_addr11'),
    addr12: dic('ctc0_addr12'),
    addr13: dic('ctc0_addr13'),
    phone:  dic('ctc0_phone'),
    mobile: dic('ctc0_mobile')
  };
  const ctcD = useCtcByEmail(email);
  // ctcD will be null until ready, be careful for the reentrant nature
  const [c, setC]  = useState<ICtc>(ctcD || ctcExample);
  let changeCnt = 0;
  if (!c.id && ctcD) { // now we got ctcD - THIS PATTERN is important!
    setC(ctcD);  // c.id will be set to the fetched ctc doc id.
  } else {
    if (ctcD) {  // count any changed property - difference between two objects
      Object.entries(ctcD).forEach(([key, value]) => {
        if (value !== c[key as keyof ICtc]) {
          changeCnt++;
        }
      });
    }
  }
  const szText = (text: string0) => {
    const len = text ? text.length : 0;
    return (len < 10)
      ? "text-2xl"
      : (len < 15)
        ? "text-xl"
        : (len < 20)
          ? "text-lg"
          : "text-sm";
  }
  const zipP = dic('zip_prefix');
  const twPhone = "text-blue-700 mr-1";
  return (
    <div>
      <div {...cLo("border border-gray-400 shadow-md cursor-pointer")}>
        <div {...cLo("p-4 md:p-6 text-sm bg-white FontMincho")}>
          <div {...cLo("grid grid-cols-2 h-56", className)}>
            <div {...cLo("col-span-1 flex flex-col justify-between")}>
              <div>
                <div {...cLo("font-bold TextShadowB whitespace-nowrap", szText(c.org))}>
                  {c.org}
                </div>
                <div {...cLo("font-bold text-xs text-gray-500 italic mt-1 whitespace-nowrap")}>
                  {c.orgSub}
                </div>
              </div>
              <div>
                <div {...cLo("text-xs text-blue-800")}>
                  {c.zip1 && <div>{zipP}{c.zip1}</div>}
                  <div>{c.addr11}</div>
                  <div>{c.addr12}</div>
                  <div>{c.addr13}</div>
                </div>
                <div {...cLo("text-xs text-red-800")}>
                  {c.zip2 && <div {...cLo("mt-1")}>{zipP}{c.zip2}</div>}
                  <div>{c.addr21}</div>
                  <div>{c.addr22}</div>
                  <div>{c.addr23}</div>
                </div>
              </div>
            </div>
            <div {...cLo("col-span-1 text-right flex flex-col justify-between")}>
              <div>
                <div {...cLo("font-bold text-2xl text-black")}>{c.lNameL} {c.fNameL}</div>
                <div {...cLo("font-bold text-base text-black")}>{c.fName} {c.lName}</div>
                <div {...cLo("text-gray-700 mt-1")}>{c.title}</div>
                <div {...cLo("text-xs mb-1")}>{c.dept}</div>
              </div>
              <div>
                {c.phone &&  <div><span {...cLo(twPhone)}>℡.</span>{c.phone}</div>}
                {c.mobile && <div><span {...cLo(twPhone)}>m.</span>{c.mobile}</div>}
                <div {...cLo("text-gray-700")}>{c.email}</div>
                {c.orgUrl && <a href={c.orgUrl} rel="noreferrer" target="_blank">
                  <span {...cLo("text-xs text-blue-600")}>{c.orgUrl}</span>
                </a>}
              </div>
            </div>
          </div>
        </div>
      </div>
      {editable && <NamecardEdit {...{c, setC, changeCnt}} />}
    </div>
  );
}

const NamecardEdit = ({ c, setC, changeCnt }: {
  c:         ICtc;
  setC:      (c: ICtc) => void;
  changeCnt: number;
}) => {
  const { dic } = useLocaleCtx();
  const [edit,  setEdit]  = useState(false);
  const [error, setError] = useState<TError0>(null);
  const [open,  setOpen]  = useState(false);  // ModalConfirm
  const dispatch = useAppDispatch();
  const twG2        = "grid grid-cols-2";
  const twLabel0    = "font-bold text-xs text-center text-gray-500";
  const twOutBorder = "border border-gray-300 rounded-md m-1 p-1";
  const twFlexCol   = "flex flex-col";
  const twWork      = "bg-blue-50";
  const twButton    = "py-1 text-base rounded-md TextShadowB";
  const updateText = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, key: keyof ICtc) => {
      setC({ ...c, [key]: e.target.value });
    }, [c, setC]);
  const onModalDlgCB = async (ok: boolean) => {
    if (ok) {
      try {
        const { createdAt, ...cUp } = c;  // remove createdAt
        //-----------------------------------------------------------------------
        // update or create ctc
        //-----------------------------------------------------------------------
        const resultAction = await dispatch( updateCtc(cUp) );
        //-----------------------------------------------------------------------
        unwrapResult(resultAction);  // extract payload
        const ctc = resultAction.payload as ICtc;
        console.log("updateCtc result:", ctc);
      } catch(error) {
        console.log("dispatch ( updateCtc ):", error);
        setError(errorFmt('ERROR', "Ctc Update", "Failed to update/create a ctc!"));
      }
      setEdit(false);
    }
  }

  return (
    <div>
      <div {...cLo("my-4 flex flex-row-reverse justify-center")}>
        <ButtonC
          classX={cC(twButton, edit, "BC_Red w-36 ml-8", "BC_Gray w-80")}
          onPress={() => setEdit(!edit)}
        >
          {dic(edit ? 'cancel' : 'edit')}
        </ButtonC>
        {edit && <ButtonC
          classX={cC(cL(twButton, "w-36"), changeCnt, "BC_Green", "BC_Gray")}
          isDisabled={!changeCnt}
          onPress={() => setOpen(true)}
        >
          {dic('save')}
        </ButtonC>}
      </div>
      <div {...cLoIf(edit, "-mt-8 my-2 py-4 bg-gray-50",
        "border-dashed border-4 border-gray-300 rounded-xl")}
      >
        <div {...cLo(twOutBorder)}>
          <div {...cLo(twG2)}>
            <LabeledInput
              value={c.fName}
              onChange={e => updateText(e, 'fName')}
            >
              {dic('ctc_fName')}
            </LabeledInput>
            <LabeledInput
              value={c.lName}
              onChange={e => updateText(e, 'lName')}
            >
              {dic('ctc_lName')}
            </LabeledInput>
          </div>
          <div {...cLo(twG2)}>
            <LabeledInput
              value={c.fNameL}
              onChange={e => updateText(e, 'fNameL')}
            >
              {dic('ctc_fNameL')}
            </LabeledInput>
            <LabeledInput
              value={c.lNameL}
              onChange={e => updateText(e, 'lNameL')}
            >
              {dic('ctc_lNameL')}
            </LabeledInput>
          </div>
        </div>
        <div {...cLo(twOutBorder, twWork)}>
          <LabeledInput
            value={c.org}
            onChange={e => updateText(e, 'org')}
          >
            {dic('ctc_org')}
          </LabeledInput>
          <LabeledInput
            value={c.orgSub}
            onChange={e => updateText(e, 'orgSub')}
          >
            {dic('ctc_orgSub')}
          </LabeledInput>
          <LabeledInput
            value={c.orgUrl}
            onChange={e => updateText(e, 'orgUrl')}
          >
            {dic('ctc_orgUrl')}
          </LabeledInput>
          <LabeledInput
            value={c.email}
            onChange={e => updateText(e, 'email')}
          >
            {dic('ctc_email')}
          </LabeledInput>
          <div {...cLo(twG2)}>
            <LabeledInput
              value={c.title}
              onChange={e => updateText(e, 'title')}
            >
              {dic('ctc_title')}
            </LabeledInput>
            <LabeledInput
              value={c.dept}
              onChange={e => updateText(e, 'dept')}
            >
              {dic('ctc_dept')}
            </LabeledInput>
          </div>
        </div>
        <div {...cLo(twG2)}>
          <div {...cLo(twFlexCol, twOutBorder, twWork)}>
            <label {...cLo(twLabel0)}>{dic('ctc_addr1')}</label>
            <LabeledInput
              value={c.zip1}
              onChange={e => updateText(e, 'zip1')}
            >
              {dic('ctc_zip1')}
            </LabeledInput>
            <LabeledInput
              value={c.addr11}
              onChange={e => updateText(e, 'addr11')}
            >
              {dic('ctc_addr11')}
            </LabeledInput>
            <LabeledInput
              value={c.addr12}
              onChange={e => updateText(e, 'addr12')}
            >
              {dic('ctc_addr12')}
            </LabeledInput>
            <LabeledInput
              value={c.addr13}
              onChange={e => updateText(e, 'addr13')}
            >
              {dic('ctc_addr13')}
            </LabeledInput>
          </div>
          <div {...cLo(twFlexCol, twOutBorder)}>
            <label {...cLo(twLabel0)}>{dic('ctc_addr2')}</label>
            <LabeledInput
              value={c.zip2 || ''}
              onChange={e => updateText(e, 'zip2')}
            >
              {dic('ctc_zip2')}
            </LabeledInput>
            <LabeledInput
              value={c.addr21 || ''}
              onChange={e => updateText(e, 'addr21')}
            >
              {dic('ctc_addr21')}
            </LabeledInput>
            <LabeledInput
              value={c.addr22 || ''}
              onChange={e => updateText(e, 'addr22')}
            >
              {dic('ctc_addr22')}
            </LabeledInput>
            <LabeledInput
              value={c.addr23 || ''}
              onChange={e => updateText(e, 'addr23')}
            >
              {dic('ctc_addr23')}
            </LabeledInput>
          </div>
        </div>
        <div {...cLo(twOutBorder)}>
          <div {...cLo(twG2)}>
            <LabeledInput
              value={c.phone}
              onChange={e => updateText(e, 'phone')}
            >
              {dic('ctc_phone')}
            </LabeledInput>
            <LabeledInput
              value={c.mobile}
              onChange={e => updateText(e, 'mobile')}
            >
              {dic('ctc_mobile')}
            </LabeledInput>
          </div>
        </div>
      </div>
      {error && <ErrorDlg {...{error}} />}
      <ModalDlg {...{open, setOpen}}
        checkmark
        title={dic('title_namecard_edit')}
        ok={dic('ok')}
        cancel={dic('cancel')}
        onOkCancel={onModalDlgCB}
      >
        <div {...cLo("my-2 bg-yellow-100 rounded-lg")}>
          {dic('changed_items') + ':' + changeCnt}
        </div>
        <div>{dic('ask_save')}</div>
      </ModalDlg>
    </div>
  );
}

interface ILabeledInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  // hide?:       boolean;
  // toggleHide?: () => void;  // callback when hide icon clicked
};

const LabeledInput: React.FC<ILabeledInputProps> = ({
  children, className,
  // hide, toggleHide,
  ...props
}) => {
  return (
    <div {...cLo("grid-span-1 mx-1")}>
      <label>
        <span {...cLo("text-2xs text-gray-500 px-2 mr-2")}>
          {children}
        </span>
        <input
          type="text"
          {...cLo("py-1.5 px-2 w-full border border-gray-300 rounded-md",
            "focus:ring focus:ring-offset-1 focus:ring-purple-600", className)}
          {...props}
        />
      </label>
    </div>
  );
}

/*
              hide={c.hide?.['mobile']}
              toggleHide={() => setC({ ...c,  hide: { ...c.hide, c.hide?.['mobile']: })}

          <input
            type="text"
            {...cCo(cL("py-1.5 pl-2 pr-6 w-full border border-gray-300 rounded-md",
              "focus:ring focus:ring-offset-1 focus:ring-purple-600", className),
              hide, "bg-gray-400 text-red-100")}
            {...props}
          />
          <div {...cCo("absolute inset-y-0 right-1 flex items-center cursor-pointer",
            hide, "text-red-700 opacity-50", "text-green-700 opacity-50")}
          >
            <SvgIconToggle className="h-5 w-5" cond={!!hide} Path="eye_hide" Path2="eye_show" />
          </div>
        </div>
 */

/*=============================================================================
 menus.tsx - menus

 - PopupMenu
 - PaneHead

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { useState, useEffect, useRef, useCallback } from 'react';
import { SvgIcon, SvgIconFull, } from 'utils/svg';
import type { TPathDataKey, } from 'utils/svg';
import { cL, cCo, cLo, cLoIf, showIf, } from 'utils/util';
import { twBtnFocus } from 'ui/ui';

/**
 * If the click happened at the outside of the ref element, call callback.
 * TODO: Replace with React Aria's useOverlay
 */
export const useClickOutside = (ref: React.RefObject<HTMLDivElement>, callback: Function) => {
  useEffect(() => {
    const MOUSEDOWN = 'mousedown';
    const handleClickOutside = (e: MouseEvent) => {
      if (!ref.current?.contains(e.target as Node))
        callback();
    }
    document.addEventListener(MOUSEDOWN, handleClickOutside);
    return () => document.removeEventListener(MOUSEDOWN, handleClickOutside);
  }, [ref, callback]);  // for callback function useCallback()
}

// menu item
interface IMenuItem extends Partial<IMouseProps>, IClassX {  // classX to darw border
  item:      React.ReactNode;  // menuitem
  disabled?: boolean;          // disable the menuitem
};
interface IMenuListProps extends IClassNameObj {  // classX to show/hide
  title?:    string;       // title of the menulist
  menuItems: IMenuItem[];  // array of menuitems
};
/**
 * list of menu items
 */
export const MenuList = ({ className, title, menuItems }: IMenuListProps) =>
  <ul {...cLo("absolute text-sm font-bold bg-gray-100 border border-gray-600",
      "rounded-md shadow-lg cursor-pointer z-20 md:right-0 md:min-w-max", className)}
  >
    <li {...cLoIf(title, "text-xs text-center bg-purple-600 text-white",
      "px-1 truncate max-w-[10rem]")}
    >
      {title}
    </li>
    {menuItems.map((m, i) =>
      <li key={i}
        {...cCo(cL("px-2 py-1", m.classX), m.disabled,
          "italic text-gray-400",
          "text-black hover:bg-gray-300")}
        {...(!m.disabled && { onClick: m.onClick })}
      >
        {m.item}
      </li>)}
  </ul>;

interface IPopupMenuProps extends IMenuListProps {
  Path: TPathDataKey;  // anchor button svg
};
/**
 * Popup menu with an anchor button: MenuList passed as the children
 * When the menu width becomes too short, the popup should be left aligned on the left bar.
 * Responsive on the condition that m (medium) is the break point.
 */
export const PopupMenu = ({
  title,
  className = "w-6",  // removed h-6 to enable items-center
  menuItems, Path
}: IPopupMenuProps) => {
  const [menu, setMenu] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, useCallback(() => setMenu(false), []));
  return (
    <div {...{ref}}
      {...cLo(className, twBtnFocus, "relative mr-1")}
      onClick={() => setMenu(!menu)}
    >
      <SvgIconFull {...{Path}} />
      <MenuList
        {...cLo(showIf(menu), "right-0 min-w-max")}
        {...{title, menuItems}}
      />
    </div>
  );
}

export interface IPaneHeadProps extends IClassX {  // classX for title
  title:    string;        // title of the list
  iconPath: TPathDataKey;  // Left icon path
};
/**
 * PaneHead: Head row of any info list.
 * children is for the popup menu button at the top right
 */
export const PaneHead: React.FC<IPaneHeadProps> = ({ title, classX, iconPath, children }) =>
  <div {...cLo("flex flex-row items-center justify-between flex-none pr-1",
        "dark:bg-gray-800 bg-gray-300 border-b border-r border-gray-400")}
  >
    <SvgIcon Path={iconPath} classX="m-1 text-gray-400" />
    <p {...cLo(classX, "font-mono font-bold text-xs truncate",
        "dark:text-yellow-400 text-yellow-800 max-w-xs hidden sm:block")}
    >
      {title}
    </p>
    <div className="flex flex-row">
      {children}
    </div>
  </div>;

export const MenuItemExit = ({ text }: { text: string; }) =>
  <div className="flex flex-row items-center">
    <SvgIcon Path="door_exit" classX="text-red-400 mr-1" />
    {text}
  </div>;

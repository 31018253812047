/*=============================================================================
 roomsAPI.ts - rooms database APIs abstraction layer

 - users: current joined users
 - xUsers: exited users

 (C) 2021 SpacetimeQ INC.
=============================================================================*/
import firebase from './firebase';
import type { IRoomCreate, IRoom, IRoomUp, IAddOrRemove, } from 'models';
import { fsPath, serverTS, serializeTS, } from './apiCommon';
import { errorFmt, } from 'utils/util';
import { getRoomById, } from 'features/rooms/roomsSlice';
import type { Update } from '@reduxjs/toolkit'
import { CREQ, WARN_REQ_IN_QUEUE } from './reqQueue';

//-----------------------------------------------------------------------------
// Room
//-----------------------------------------------------------------------------
/**
 * fetch room: wrapper of firebase API
 * PATTERN: fetchUserAPI
 */
export const fetchRoomAPI = (id: string): Promise<IRoom> =>
  new Promise<IRoom>(async (resolve, reject) => {
    if (!CREQ.addReq(id, "fetchUserAPI")) {
      reject(WARN_REQ_IN_QUEUE);
      return;
    }
    try {
      //-----------------------------------------------------------------------
      // user document, read
      //-----------------------------------------------------------------------
      const doc = await firebase.firestore()
        .doc(fsPath.room(id))
        .get();
      //-----------------------------------------------------------------------
      if (doc.exists) {
        const data = doc.data(); // need to exclude non-serializables such as createdAt
        // console.log(doc.id, " => ", data);
        if (data) {
          resolve({  // order matters: cratedAt should come last not to be overwritten
            id,
            ...(data as IRoomCreate),
            ...serializeTS('createdAt', data.createdAt),
          });
        } else {
          reject(errorFmt('ERROR', "fetchRoomAPI", "Null data"));
        }
      } else {
        // create user doc here
        reject(errorFmt('ERROR', "fetchRoomAPI", `${fsPath.rooms}/${id} not found!`));
      }
    } catch (error) {
      reject(error);
    } finally {
      CREQ.removeReq(id);
    }
  });

/**
 * create room
 * PATTERN: createUserAPI
 */
export const createRoomAPI = (room: IRoomCreate): Promise<IRoom> => {
  return new Promise<IRoom>(async (resolve, reject) => {
    try {
      //-----------------------------------------------------------------------
      // rooms collection, create
      //-----------------------------------------------------------------------
      const docRef = await firebase.firestore()
        .collection(fsPath.rooms)
        .add({
          ...room,
          ...serverTS('createdAt'),  // should come later not to be overwritten
        });
      //-----------------------------------------------------------------------
      console.log("Document successfully written:", docRef.id);
      resolve({
        ...room,
        id:        docRef.id,
        createdAt: (new Date()).getTime(),  // use local time to avoid fetching server time
      });
    } catch (error) {
      reject(error);
    }
  });
}

/**
 * update room with the given room data
 *  @param room IRoomUp
 *  @returns Update<IRoom>
 * PATTERN: updateUserAPI
 */
export const updateRoomAPI = (room: IRoomUp): Promise< Update<IRoom> > => {
  const { id, ...data } = room;
  return new Promise< Update<IRoom> >(async (resolve, reject) => {
    try {
      //-----------------------------------------------------------------------
      // room document, update
      //-----------------------------------------------------------------------
      await firebase.firestore()
        .doc(fsPath.room(id))
        .update({
          ...data,
          ...serverTS('updatedAt'),
        });  // Promise<void>
      //-----------------------------------------------------------------------
      console.log("room successfully updated:", id);
      resolve({ id, changes: { ...data } });
    } catch (error) {
      reject(error);
    }
  });
}

/**
 * update users list
 */
export interface IUpdateRoomUsersProps extends IAddOrRemove {
  id:  TRoomID;  // room id of which its users or xUsers will be updated
  uid: TUserID;  // user id to add or remove
};
/**
 * update users list of *id* by *add*ing or removing *uid*
 * PATTERN: updateUserRoomsAPI
 * React Hook "useRoomById" is called in function "updateRoomUsersAPI" that is neither
 * a React function component nor a custom React Hook function.
 * React component names must start with an uppercase letter  react-hooks/rules-of-hooks
 */
export const updateRoomUsersAPI = ({ id, uid, add }: IUpdateRoomUsersProps)
: Promise< Update<IRoom> > => {
  const room = getRoomById(id);  // cannot use a hook here
  return new Promise< Update<IRoom> >(async (resolve, reject) => {
    if (!room) {
      reject(errorFmt('ERROR', "No Room", id));
      return;
    }
    try {
      //-----------------------------------------------------------------------
      // room document, update
      //-----------------------------------------------------------------------
      await firebase.firestore()
        .doc(fsPath.room(room.id))
        .update(add
          ? {
              users:  firebase.firestore.FieldValue.arrayUnion(uid),
              xUsers: firebase.firestore.FieldValue.arrayRemove(uid),  // if exist
            }
          : {
              users:  firebase.firestore.FieldValue.arrayRemove(uid),
              xUsers: firebase.firestore.FieldValue.arrayUnion(uid),
            });
      //-----------------------------------------------------------------------
      console.log("Users successfully updated");
      let users:  TUserID[] = room.users  ? [...room.users]  : [];
      let xUsers: TUserID[] = room.xUsers ? [...room.xUsers] : [];
      if (add) {
        users.push(uid);
        xUsers = xUsers.filter(id => id !== uid);
      } else {
        users = users.filter(id => id !== uid);
        xUsers.push(uid);
      }
      resolve({ id: room.id, changes: { users, xUsers } });
    } catch (error) {
      reject(error);
    }
  });
}

/*=============================================================================
 Layout.tsx - 

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { BrowserRouter, Switch, Route, } from "react-router-dom";
import { ARoute, AuthRedirectToFrom, } from './AuthRoute';
import { RouteCompos } from './Routes';
import { useAuthCtx }  from 'app/AuthContext';
import Navbar from './Navbar';
import PageNotFound from './PageNotFound';

export default function Layout() {
  const user = useAuthCtx().user;

  return (
    <BrowserRouter>
      <Navbar />
      <AuthRedirectToFrom isUser={!!user} />
      <Switch>
        {RouteCompos.map((rt, i) =>
          <ARoute key={i} authLv={user ? 1 : 0} {...rt} />
        )}
        <Route component={PageNotFound} />
      </Switch>
    </BrowserRouter>
  );
}

import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App from 'app/App';

import { LocaleProvider } from 'ui/locale/LocaleCtx';
import { AuthProvider } from 'app/AuthContext';
import { store } from 'app/store';
import { Provider } from 'react-redux';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import reportWebVitals from './reportWebVitals';
import 'out/tailwind.o.css';

if (!process.env.NODE_ENV ||
     process.env.NODE_ENV === 'production') {
  console.log = function() {}  // suppress all console.log in production mode
}

ReactDOM.render(
  <StrictMode>
    <LocaleProvider>
      <Provider {...{store}}>
        <AuthProvider>
          <App />
        </AuthProvider>
      </Provider>
    </LocaleProvider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

/*=============================================================================
 UserMenu.tsx - User Menus

 - Dependent on class Dropdown in App.scss

 (C) 2020 SpacetimeQ INC.
=============================================================================*/
import { NavLink, } from 'react-router-dom';
import { useAuthCtx } from 'app/AuthContext';
import { useLocaleCtx, } from 'ui/locale/LocaleCtx';
import type { TDicKey, } from 'ui/locale/selectLocales';
import { accessLevel } from 'ui/layout/AuthRoute';
import type { TAccessLevel, } from 'ui/layout/AuthRoute';
import { ANON_AVATAR, } from 'app/res';
import { cC0o, cCo, cL, cLo, cLoIf, } from 'utils/util';
import { LogoutButton } from 'features/auth/AuthForms';
import { MenuItemExit, } from 'ui/menus';
import { ProfileModal } from 'features/profile/ProfilePage';
import { SvgIcon, } from 'utils/svg';
import { AvatarImgByUid, } from 'ui/Avatars';
import { useUserById } from 'features/users/usersSlice';

// onClick={onMenuItemClick}Menu items have two types of links:
// 1. Route causes the page(url) change - to: url
// 2. command with no page change       - id: used as a command
interface IUserMenuItem {
  access: TAccessLevel;
  to?:    string;
  id:     TDicKey;
};
const USER_MENUITEMS: IUserMenuItem[] =
[
  // { access: 0, to: "/auth",    id: "menu_signin", },  // when signed-out
  // { access: 1, to: "/profile", id: "menu_profile", }, // when signed-in
  // { access: 1,                 id: "menu_profile", }, // when signed-in
  // { access: 1, to: "/setting", id: "setting", },   //
  { access: 1,                 id: "menu_signout", }
];

const ProfileImg = ({ uid }: { uid: Undefinable<TUserID> }) => {
  const ux = useUserById(uid || "0");
  const twSize = "w-8 h-8";
  return ux
  ? <AvatarImgByUid uid={ux.uid}
      {...cLo(twSize)}
      clsRounded="rounded-full"
      bdrClr="border-2 border-yellow-400"
    />
  : <img {...cLo(twSize, "rounded-full bg-gray-200")}
      src={ANON_AVATAR}
      alt="avatar"
    />;
}

/**
 * Popup Menu - display on hover: with CSS classes Dropdown, Dropdown-menu
 * anchor is the profile image button
 * For touch screen: show on click, hide on outer-click
 */
export const UserMenu: IFClassName = ({ className }) => {
  const { user } = useAuthCtx();
  return (
    <div {...cLo(className, "relative Dropdown z-40")}>
      <button
        {...cLo("flex text-sm border-2 border-transparent rounded-full",
          "hover:border-yellow-500 focus:outline-none focus:border-white")}
        id="user-menu"
        aria-label="User menu"
        aria-haspopup="true"
      >
        <ProfileImg uid={user?.uid} />
      </button>
      <div {...cLo("Dropdown-menu hidden absolute right-0 rounded-full shadow-lg",
          "min-w-max origin-top-right")}
      >
        <UserMenuList popup name={user?.name || user?.email} accessLv={accessLevel(!!user)} />
      </div>
    </div>
  );
}

/**
 * Used in Navbar.tsx, for Mobile
 */
export const UserMenuListPulldown: React.FC<IMenuItemClickProps> = (props) => {
  const { user } = useAuthCtx();
  return (
    <div className="pb-3 border-t border-gray-700 z-40">
      <div className="flex md:justify-end">
        {props.children}
      </div>
      {/*
      <ProfileModal
        {...cLoIf(user, "flex items-center w-full py-1 px-4 space-x-3 bg-gray-300",
          "hover:bg-gray-100")}
      >
        <div className="flex-shrink-0">
          <ProfileImg uid={user?.uid} />
        </div>
        <div className="space-y-1 font-medium leading-none">
          <div className="text-base text-black">{user?.name}</div>
          <div className="text-sm text-gray-800">{user?.email}</div>
        </div>
      </ProfileModal>
      */}
      <UserMenuList accessLv={accessLevel(!!user)} {...props} />
    </div>
  );
}

interface IUserMenuListProps extends Partial<IMenuItemClickProps> {
  popup?:   boolean;
  name?:    string0;
  accessLv: TAccessLevel;
};
/**
 * Disable the menuitem when it matches the current URL, using <NavLink> rather than <Link>.
 * Show menuitems according to the access level
 */
const UserMenuList = ({ popup, accessLv, name, onMenuItemClick }: IUserMenuListProps) => {
  const { dic } = useLocaleCtx();
  return (
    <div {...cC0o(popup,
        "pb-1 bg-white rounded-md shadow-xs",
        "px-2 mt-3 space-y-1")}
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="user-menu"
    >
      <ProfileModal
        {...cLoIf(name, "flex flex-row items-center p-1 w-full font-bold text-xs text-center",
          "bg-gray-600 text-white rounded-t-md hover:bg-gray-400")}
      >
        <SvgIcon Path="user_frame" classX="mr-1" strokeWidth={0} />
        {name}
      </ProfileModal>
      {USER_MENUITEMS.filter(a => a.access === accessLv).map((m, i) =>
        {return (m.to)
        ? <NavLink
            key={i}
            exact to={m.to}
            {...cCo("block", popup,
              "px-4 py-2 text-sm font-bold text-gray-700 hover:bg-gray-100",
              cL("px-3 py-1 text-base font-medium dark:text-gray-300 text-gray-800",
                "hover:text-white hover:bg-gray-700 focus:outline-none",
                "focus:text-white focus:bg-gray-700"))}
            activeClassName="pointer-events-none italic text-gray-600 opacity-50"
            role="menuitem"
            onClick={onMenuItemClick}
          >
            {dic(m.id)}
          </NavLink>
        : <MenuCommands key={i} {...{popup}} command={m.id} />
      })}
    </div>
  );
}

const MenuCommands = ({ popup, command }: {
  popup?:  boolean;
  command: string;
}) => {
  const { dic } = useLocaleCtx();
  switch (command) {
    case "menu_signout":
      return (
        <LogoutButton {...cCo("px-4", popup, "w-full", "rounded-md bg-gray-200")}>
          <MenuItemExit text={ dic('menu_signout') } />
        </LogoutButton>
      );
    default:
      return null;
  }
}

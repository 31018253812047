/*=============================================================================
 useLocalStorage.ts - typesafe LocalStorage utility

 - SECRET_KEY included in the client side javascript: needs improvement

 (C) 2021 SpacetimeQ INC.
=============================================================================*/
import { useEffect } from 'react';

import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';

const LSPrefix   = 'stq_';              // LocalStorage prefix for all item names
const SECRET_KEY = 'stq-yokohama';      // secret key for encryption
const ITEM_EMAIL = LSPrefix + "email";  // out of useLSEmailPwd function, for useEffect dependency
const ITEM_PASS  = LSPrefix + "pass";

/**
 * get LocalStorage of string (or extended) type
 * conversion to number does not work
 */
export const getLS = <T extends string|number = string>(name: string): T =>
  localStorage.getItem(LSPrefix + name) as T;

/**
 * set LocalStorage of string (or extended) type
 */
export const setLS = <T extends string|number = string>(name: string, val: T) =>
  localStorage.setItem(LSPrefix + name, val as string);

/**
 * get LocalStorage of boolean type
 */
export const getLSBool = (name: string, defB: boolean = true): boolean => defB
  ? getLS(name) !== 'false'
  : getLS(name) === 'true';

/**
 * set LocalStorage of boolean type
 */
export const setLSBool = (name: string, b: boolean) => setLS(name, b ? 'true' : 'false');

/**
 * Email and Password from LocalStorage; useEffect wrapper
 */
export const useLSEmailPwd = (
  setSave :   (save:  boolean) => void,
  setEmail:   (email: string)  => void,
  setPwd  :   (pwd:   string)  => void,
) => {
  useEffect(() => {  // initialization from the localStorage is the side-effect
    const ls_email = localStorage.getItem(ITEM_EMAIL) || getLS<string>('refEmail');
    if (ls_email) {
      setEmail(ls_email);
      // console.log("🍏", ls_email);
      const ls_pass = localStorage.getItem(ITEM_PASS);
      if (ls_pass)  // retrieve password and decrypt
        setPwd(CryptoAES.decrypt(ls_pass, SECRET_KEY).toString(CryptoENC));
      // No flag for save option; the existence of the email record tells running in save mode
      setSave(true);
    }
  }, [setSave, setEmail, setPwd]);  // run only once on mount

// Update Email and Password to LocalStorage
  const updateLSEmailPwd = (save: boolean, email: string, pwd: string) => {
    if (save) { // save with encrypted password
      localStorage.setItem(ITEM_EMAIL, email);
      localStorage.setItem(ITEM_PASS, CryptoAES.encrypt(pwd, SECRET_KEY).toString());
    } else {  // if no save mode, remove all items
      if (localStorage.getItem(ITEM_EMAIL)) {
        localStorage.removeItem(ITEM_EMAIL);
        localStorage.removeItem(ITEM_PASS);
      }
    }
  }
  return updateLSEmailPwd;
}

/**
 * use LocalStorage
 * @param setStateCB is called to getItem
 * @returns updateLSState to be used when need to setItem
 */
export const useLS = <T extends string = string>(
  name:       string,
  setStateCB: (state: T) => void  // 'signup' or 'login'
) => {
  useEffect(() => {  // initialization from the localStorage is the side-effect
    const ls_st = getLS<T>(name);
    if (ls_st)  // need to check if the type is T
      setStateCB(ls_st);
  }, [setStateCB, name]);  // run only once on mount

  // Update Email and Password to LocalStorage
  const updateLSState = (state: T) => {
    setLS<T>(name, state);
  }
  return updateLSState;
}

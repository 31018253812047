/*=============================================================================
 selectLocales.tsx - locales data and LocaleSelector

 - One source of translation text
   -> Splitter for dynamic loading

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { useLocaleCtx, } from './LocaleCtx';
import { cLo, } from 'utils/util';
import en from 'asset/text/en.json';
import jp from 'asset/text/jp.json';
import kr from 'asset/text/kr.json';

/**
 * Dictionary list
 *
 * By separating files according to the locale, we can fetch selectively.
 */
export const dicList = { en, jp, kr };
export type TDic    = typeof dicList['en'];
export type TDicKey = keyof TDic;

export type TLocale = keyof typeof dicList;
type TLocaleOptions = {  // should be a type NOT an interface
  [K in TLocale]: string;
};

export const localeOptions: TLocaleOptions = {
  jp: '🇯🇵',
  en: '🇺🇸',
  kr: '🇰🇷',
}

/**
 * Utility functions
 *
 * form select override
 * - put the select locale always on top, due to the politically sensitive issue
 */
export const LocaleSelector = () => {
  const { locale, setLocale } = useLocaleCtx();

  return (
    <select id="locale_select"
      style={{
        borderWidth: 0,
        lineHeight: '0.75rem',
        paddingRight: 0,
        backgroundImage: 'none',
        outline: 'none',
        boxShadow: 'none',
        fontSize: '1.5rem',
      }}
      {...cLo("form-select ml-1 mr-3 focus:outline-none dark:text-gray-400",
        "bg-transparent cursor-pointer")}
      onChange={e => setLocale(e.target.value as TLocale)}
      value={locale}
    >
      <option value={locale}>{localeOptions[locale]}</option>
      {Object.entries(localeOptions)
        .filter(([k, _n]) => k !== locale)
        .map(([key, name]) =>
          <option {...{key}} value={key}>{name}</option>
      )}
    </select>
  );
}

export const selectableLocale = (locale: string) =>
  Object.keys(localeOptions).includes(locale);

export const LocaleFlag = () => {
  const { locale } = useLocaleCtx();

  return (
    <span>{localeOptions[locale]}</span>
  );
}

export const FullName = ({ firstName, lastName }: { firstName: string; lastName: string; }) => {
  const { locale } = useLocaleCtx();

  return (locale === 'en')
    ? <span>{firstName} {lastName}</span>
    : <span>{lastName} {firstName}</span>;
}

/*=============================================================================
 Navbar.tsx - 

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { VERSION_STR } from 'app/App';
import { useState, useRef, useCallback, } from 'react'
import { useLocaleCtx, } from 'ui/locale/LocaleCtx';
import { NavLink, } from 'react-router-dom';
import { cLo, cL, cCo, } from 'utils/util';
import { twTextFocus, BurgerButton, } from 'ui/ui';
import { ToggleDarkButton, } from 'ui/toggleDark';
import { LocaleSelector, } from 'ui/locale/selectLocales';
import { RouteMenus, } from './Routes';
// import { UserMenu, UserMenuListPulldown, } from './UserMenu';
import { UserMenuListPulldown, } from './UserMenu';
import { useClickOutside, } from 'ui/menus';

/**
 * color doesn't change for the png file
 */
const LogoButton: IFClassName<{ onPress: TButtonClickHandler }> = ({ className, onPress }) =>
  <button
    {...cLo(className, "p-0.5 mt-2 mr-1 border-2 border-transparent rounded-full",
      "bg-gray-800 hover:bg-gray-700 focus:bg-gray-700 focus:outline-none")}
    aria-label="Logo"
    onClick={onPress}
  >
    <img className="w-6 h-6" src="/logo.png" alt="" />
  </button>;

const twBgBorder = "dark:border-gray-600 dark:bg-gray-800 border-gray-800 bg-gray-200";

/**
 * Using block and flex in the same <div> causes a newline effect,
 * thus use "hidden md:block" at the separate outer layer.
 */
const MainMenuList: IFClassName< Partial<IMenuItemClickProps> > = ({
  className, onMenuItemClick
}) => {
  const { dic } = useLocaleCtx();
  return (
    <div {...cLo(className)}>
      <p {...cLo("p-1 text-xs text-center")}>{VERSION_STR}</p>
      {RouteMenus.map(m =>
        <NavLink
          key={m.to}
          exact to={m.to}
          {...cLo("p-2 rounded-md opacity-70", twTextFocus)}
          activeClassName="underline opacity-100"
          onClick={onMenuItemClick}
        >
          {m.icon || dic(m.id) }
        </NavLink>)}
    </div>
  );
}

const Navbar = () => {
  const [mblMenu, setMblMenu] = useState(false);  // is Mobile Menu On
  const onMenuItemClick = () => setMblMenu(false);
  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, useCallback(() => setMblMenu(false), []));
  return (
    <nav>
      <div {...cLo("fixed z-50 w-full flex flex-col shadow-lg border-b", twBgBorder)}>
        <div {...cLo("flex items-center justify-between Sq_navbar-h mx-8")}>

          <div {...cLo("flex items-center dark:text-gray-200 text-gray-800")}>
            <LogoButton {...cLo("flex flex-shrink-0 mb-2")}
              onPress={() => {
                window.location.reload();  // Can this run hard refresh?
                alert(VERSION_STR);
              }}

            />
            <div {...cLo("hidden md:block flex flex-row font-medium")}>
              <MainMenuList {...cLo("flex flex-row items-center")} {...{onMenuItemClick}} />
            </div>
          </div>

          <div {...cLo("flex items-center")}>
            <ToggleDarkButton />
            <LocaleSelector />
            {/*
            <UserMenu />
            */}
          </div>

          {/* Mobile only */}
          <div {...cLo("flex p-1 md:hidden")}>
            <BurgerButton isSelected={mblMenu} onPress={() => setMblMenu(!mblMenu)} />
          </div>
        </div>
      </div>

      {/* Mobile Pulldown menu */}
      <div {...{ref}}
        {...cCo(cL(twBgBorder, "md:hidden fixed z-40 w-full shadow-2xl Drawer_transition pt-10"),
          mblMenu, 'block translate-y-0', '-translate-y-full pointer-events-none')}
      >
        <UserMenuListPulldown {...{onMenuItemClick}}>
          <MainMenuList {...cLo("flex flex-col")} {...{onMenuItemClick}} />
        </UserMenuListPulldown>
      </div>
      {/* To push down page offset */}
      <div className="Sq_navbar-h" />
    </nav>
  );
}

export default Navbar;

/*=============================================================================
 Tooltips.tsx - tooltips

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { useRef } from 'react';
import { cLo, } from 'utils/util';
// react-aria ------------------------------------------------------------------------------------
import { AriaTooltipProps } from '@react-types/tooltip';
import { useTooltipTriggerState, TooltipTriggerState } from '@react-stately/tooltip';
import { useTooltip, useTooltipTrigger } from '@react-aria/tooltip';  // need to install
import { useFocusVisible } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
// react-aria ------------------------------------------------------------------------------------

interface ITooltipProps extends AriaTooltipProps {  // children: anchor element
  state:      TooltipTriggerState;
  clsColor?:  TClassName;
  clsPos?:    TClassName;
  clsSize?:   TClassName;
  clsBorder?: TClassName;
};
/** Tooltip
 *  @param state useTooltipTriggerState()
 *  @param tooltipProps useTooltipTrigger
 */
export const Tooltip: React.FC<ITooltipProps> = ({
  state,
  clsColor  = "bg-yellow-100 text-black",
  clsPos    = "z-50 inset-x-0 top-0 mt-6 p-1",
  clsSize   = "w-40",
  clsBorder = "border-2 border-gray-600 rounded-md shadow-md",
  children,
  ...props
}) => {
  const { tooltipProps } = useTooltip(props, state);
  return (
    <span
      {...cLo("absolute", clsColor, clsPos, clsSize, clsBorder)}
      {...mergeProps(props, tooltipProps)}
    >
      {children}
    </span>
  );
}

interface ITooltipSpanProps extends IClassNameObj, Omit<ITooltipProps, 'state'> {
  tip: React.ReactNode;  // tooltip element
};
/** Wrap child element with a button and an attached tooltip
 *  delay: 500 (React Aria's default is 1500)
 *  @param tip React element
 *  'relative' element will be the boundary of 'absolute' position
 */
export const TooltipSpan: React.FC<ITooltipSpanProps> = ({
  className,
  clsColor,
  clsPos,
  clsSize,
  clsBorder,
  tip, children
}) => {
  useFocusVisible();
  const ref = useRef<HTMLSpanElement>(null);
  const state = useTooltipTriggerState();
  const { triggerProps, tooltipProps } = useTooltipTrigger({ delay: 500, isOpen: true }, state, ref);
  return (
    <span className="relative">
      <span {...{ref}}
        {...{className}}
        {...triggerProps}
      >
        {children}
      </span>
      {state.isOpen &&
        <Tooltip
          {...{state}}
          {...tooltipProps}
          {...{clsColor, clsPos, clsSize, clsBorder}}
        >
          {tip}
        </Tooltip>
      }
    </span>
  );
}

/*=============================================================================
 msgsSlice.ts - messages data

 - 'msgs' is a subcollection under the rooms(sessions) Collection
 - fetching all msgs are done by dataAPI.ts/useMsgCollection (subcribing to the Firebase)

 (C) 2020 SpacetimeQ INC.
=============================================================================*/
import { createSlice, createAsyncThunk, createEntityAdapter, } from '@reduxjs/toolkit';
import type { EntityState, Update, } from '@reduxjs/toolkit';
import type { TRootState } from 'app/store';
import type { IMsg, IMsgCreate, IMsgUp, } from 'models';
import { MSGS } from 'api/apiCommon';
import { addMsgAPI, updateMsgAPI, } from 'api/msgsAPI';
import { store, errorOut, } from 'app/store';

const msgsAdapter = createEntityAdapter<IMsg>({
  // selectId:     msg => msg.id,  // default behavior
  sortComparer: (a, b) => (b.time < a.time) ? 1 : (b.time > a.time ? -1 : 0)  // ascending order
});

interface IMsgState extends EntityState<IMsg> {
  status: 'idle'
        | 'succeeded'
        | 'failed',
  error:  string0,
  roomId: string0,  // set with msgEnterRoom reducer
};

const initialState: IMsgState = msgsAdapter.getInitialState({
  status: 'idle',
  error:  null,
  roomId: null,
});

/**
 * For the context where useSelector(selectRoomId) cannot be used.
 */
export const getRoomId = () => store.getState().msgs.roomId;

/** create msg in the room (document id = msg.id)
 */
export const addMsg = createAsyncThunk<
  TMsgID,     // Return type    of the payloadCreator
  IMsgCreate  // First argument to the payloadCreator
>(
  `${MSGS}/addMsg`,  // type
  async (msg: IMsgCreate) => {  // payloadCreator
    console.log("addMsg:", msg);
    return await addMsgAPI(msg); // ******************* API call
  }
);

/**
 * update msg
 */
export const updateMsg = createAsyncThunk<
  Update<IMsg>,  // Return type    of the payloadCreator
  IMsgUp         // First argument to the payloadCreator
>(
  `${MSGS}/updateMsg`,  // type
  async (msg: IMsgUp) => {  // payloadCreator
    console.log("updateMsg:", msg);
    return await updateMsgAPI(msg); // ******************* API call
  }
);

const msgsSlice = createSlice({
  name: MSGS,
  initialState,
  //-----------------------------------------------------------------------------
  // reducers
  //-----------------------------------------------------------------------------
  reducers: {
    msgEnterRoom(state, action) {     // useMsgsRoom's useEffect has dependency on roomId,
      state.roomId = action.payload as string;  // that will make reload happen
      console.log("msgEnterRoom:", action.payload);
    },
    msgExitRoom(state) {
      console.log("msgExitRoom:", state.roomId);
      state.roomId = null;
    },
    msgAddOne:    msgsAdapter.addOne,
    msgUpsertOne: msgsAdapter.upsertOne,
    msgSetAll:    msgsAdapter.setAll,
    msgRemoveOne: msgsAdapter.removeOne,
    msgRemoveAll: msgsAdapter.removeAll,
    resetMsgs:    _state => initialState,
  },
  extraReducers: builder => {
    builder
    // addMsg  ----------------------------------------------
    .addCase(addMsg.fulfilled, (state, _action) => {
      state.status = 'succeeded';  // update data will be received later
    })
    .addCase(addMsg.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || "addMsg error!";
    })
    // updateMsg  ----------------------------------------------
    // updateUser ---------------------------------------------
    .addCase(updateMsg.fulfilled, (state, action) => { msgsAdapter.updateOne(state, action); })
    .addCase(updateMsg.rejected, (_state, action) => { errorOut(action.error, true); })
  }
});
export default msgsSlice.reducer;

export const {
  msgEnterRoom,
  msgExitRoom,
  msgAddOne,
  msgUpsertOne,
  msgSetAll,
  msgRemoveOne,
  msgRemoveAll,
  resetMsgs,
} = msgsSlice.actions;

export const {
  selectAll:   selectAllMsgs,
  selectById:  selectMsgById,
  selectIds:   selectMsgIds,
  selectTotal: selectTotalMsgs,  // total number of entities
} = msgsAdapter.getSelectors(  (state: TRootState) => state.msgs);
export const selectMsgStatus = (state: TRootState) => state.msgs.status;
export const selectRoomId    = (state: TRootState) => state.msgs.roomId;

/*=============================================================================
 themeContext.tsx - theme (darkmode, color palettes, etc...) context

  ⚆ 🌙 ☀️
  |  |  +- 'light'
  |  +---- 'dark'
  +------- mode switch: 'class' or 'media' (as defined in tailwindcss)
           In 'media' mode, dim and disable the slider control

  For 'media' mode, toggle button is disabled and dark mode is set automatically.
  Uses localStorage.theme = 'dark' or 'light' in 'class'(manual) mode.
  If the localStorage.theme item does not exist, 'media' mode is enabled.
  On media query change event, the dark mode changes accordingly in 'media' mode.

  - toggleDark.tsx provides the UI implementation
  - App.scss
    :root { background-color: var(--stq-color-background); }

 (C) 2020 SpacetimeQ INC.
=============================================================================*/
import { createContext, useContext, useState, useLayoutEffect, useCallback, } from 'react';
import { isSafari } from 'utils/util';

type TDarkThemeMode = 'dark' | 'light';
interface IDarkThemeState {
  dark:     boolean;        // is 'dark' mode?
  darkMode: TDarkThemeMode; // current dark mode in literal
  media:    boolean;        // is media (automatic) mode?
  night:    boolean;        // media state if it's night, to change the mode swich color
  toggle:   (classMode?: boolean) => void;  // callback
};

/**
 * Context Provider
 */
const DarkThemeContext = createContext<IDarkThemeState>({
  dark:     false,
  darkMode: 'light',
  media:    false,
  night:    false,
  toggle:   () => {}
});

/**
 * Context Consumer using Hooks
 */
export const useDarkThemeCtx = () => useContext(DarkThemeContext);

const matchMediaDark: MediaQueryList = window.matchMedia('(prefers-color-scheme: dark)');

/**
 * Theme settings priority
 * 1. localStorage.theme = "dark"
 * 2. OS setting: (prefers-color-scheme: dark) basically changes according to the local time
 * Current theme state should be saved to the localStorage only when the toggle switch is operated.
 */
export const DarkThemeProvider: React.FC = ({ children }) => {
  const ITEM_THEME = 'theme';
  const modeName = (isDark: boolean = true): TDarkThemeMode => isDark ? 'dark' : 'light';
  const [dark,  setDark]  = useState(false);
  const [media, setMedia] = useState(false);
  const [night, setNight] = useState(false);

  // set dark mode and save the state to localStorage
  const applyDarkMode = useCallback((dk: boolean, save = true) => {
    setDark(dk);
    const mode = modeName(dk);
    applyTheme(mode);
    if (save)
      window.localStorage.setItem(ITEM_THEME, mode);
  }, []);

  // paints the app before it renders elements
  useLayoutEffect(() => {
    const isMediaMode = () => !(ITEM_THEME in localStorage);  // called in the event handler
    const handleMediaChange = (e: MediaQueryListEvent) => {
      setNight(e.matches);
      if (isMediaMode())
        applyDarkMode(e.matches, false);
    }
    const mediaNight = matchMediaDark.matches;
    setNight(mediaNight);
    if (localStorage.getItem(ITEM_THEME) === modeName()) {  // 'class' mode
      applyDarkMode(true);
    } else if (isMediaMode()) {
      setMedia(true);
      if (mediaNight)
        applyDarkMode(true, false);
    }
    const EV_CHANGE  = 'change';
    // https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList/addListener
    if (isSafari)
      matchMediaDark.addListener(handleMediaChange)
    else
      matchMediaDark.addEventListener(EV_CHANGE, handleMediaChange)  // iOS safari not supports
    return () => {
      if (isSafari)
        matchMediaDark.removeListener(handleMediaChange);
      else
        matchMediaDark.removeEventListener(EV_CHANGE, handleMediaChange);
    }
  }, [applyDarkMode]);  // no dependency on dark, runs only once on load

  // toggle(media) will toggle between "media" and "class" mode
  // toggle()      will toggle between "dark"  and "light" mode
  function toggle(classMode = true) {
    if (classMode) {  // manual toggle mode
      if (media)
        setMedia(false);
      applyDarkMode(media ? dark : !dark);  // when switching from media mode, inherit dark
    } else {  // read from the media (system) preference
      if (!media) {
        setMedia(true);
        applyDarkMode(matchMediaDark.matches, false);
        window.localStorage.removeItem(ITEM_THEME);
      }
    }
  }

  const darkMode = modeName(dark);

  const applyTheme = (mode: TDarkThemeMode) => {
    const root = document.getElementsByTagName('html')[0];
    root.style.cssText = themeCSSVars[mode].join(';');
  }

  return (  // For tailwindcss, automatically wraps with the dark class
    <DarkThemeContext.Provider value={{dark, darkMode, media, night, toggle}}>
      <div className={darkMode}>
        {children}
      </div>
    </DarkThemeContext.Provider>
  );
}

/**
 * CSS variables used in sass: index signature
 */
const themeCSSVars: { [K in TDarkThemeMode]: string[] } = {
  dark: [
    '--stq-color-text: white',
    '--stq-color-background: #374151',
    '--stq-color-shadow: 128,128,128',
  ],
  light: [
    '--stq-color-text: black',
    '--stq-color-background: #f3f4f6',
    '--stq-color-shadow: 0,0,0',
  ]
};

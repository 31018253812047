/*=============================================================================
 Profile.tsx - User profile

 - user info can be gathered from three sources
   1. firebase authenticated user: firebase.User
   2. STQM application-specific user info: IUser - subset of firebase.User
   3. Extended user info: IUserEx - user entered additional info

 (C) 2020 SpacetimeQ INC.
=============================================================================*/
import { useRef, useState, } from 'react';
import { useAuthCtx } from 'app/AuthContext';
import { useLocaleCtx } from 'ui/locale/LocaleCtx';
import { currentUser } from 'api/authAPI';
import { createUser, useUserById, updateUser, } from 'features/users/usersSlice';
import { EmailVerified, } from 'features/users/UserInfo';
import { apiReq } from 'features/auth/authSlice';
import { useAppDispatch } from 'app/store';
import { ButtonC, } from 'ui/ui';
import { SvgIcon, } from 'utils/svg';
import type { ISvgIconProps, } from 'utils/svg';
// import { DnDOpenFile } from 'utils/DnDOpenFile';
import { timeUMTlocal, sec2hms, } from 'utils/datetime';
// import { FALLBACK_BKG, ANON_AVATAR, } from 'app/res';
import { ANON_AVATAR, } from 'app/res';
import { cL, cLo, cLoIf, errorFmt, } from 'utils/util';
import { getLS, setLS, } from 'utils/useLocalStorage';
import type { IUserEx } from 'models';
import { Namecard, } from './Namecard';
import { unwrapResult } from '@reduxjs/toolkit';
import { ErrorDlg } from 'ui/forms';
import { ModalDlg, } from 'ui/ModalDlgTw';
// for ModalDialog
// react-aria ------------------------------------------------------------------------------------
import { OverlayContainer } from '@react-aria/overlays';
import { useOverlayTriggerState } from '@react-stately/overlays';
import { useButton }  from '@react-aria/button';
// react-aria ------------------------------------------------------------------------------------
import { ModalDialog } from 'ui/ModalDialog';

/**
 * Profile page - self
 */
export default function ProfilePage() {
  return (
    <div {...cLo("relative h-screen overflow-y-scroll flex flex-col items-center py-4")}>
      <div {...cLo("max-w-lg min-w-lg lg:w-1/2 rounded-lg shadow-lg",
        "sm:mx-2 sm:mt-4 dark:bg-gray-300 bg-white")}
      >
        <ProfileSelf editable />
      </div>
    </div>
  );
}

/**
 * Register if the user not exist yet.
 * deprecated: now it's automatically set when the user first logs in.
 */
const Register = () => {
  const dispatch = useAppDispatch();
  const { user } = useAuthCtx();
  const { dic } = useLocaleCtx();
  return (
    <div className="font-semibold text-lg text-center">
      <ButtonC
        className="w-60 m-2"
        onPress={() => dispatch( createUser(user!) )}
      >
        {dic('user_register')}
      </ButtonC>
    </div>
  );
}

/**
 * send an email verification
 *
 * - Save the request history on the LocalStorage
 */
const ButtonEmailVerification = ({ email }: { email: string }) => {
  const dispatch = useAppDispatch();
  const { dic } = useLocaleCtx();
  const evKey = `eV:${email}`;
  const lastSent = Number(getLS(evKey));
  const elapsedMS = Date.now() - (lastSent || 0);
  return (
    <span {...cLo("font-mono text-xs text-red-700 ml-4")}>
      <button
        type="button"
        {...cLo("rounded-full border-2 border-rose-400 p-2 hover:text-white hover:bg-rose-800",
          "bg-yellow-100 shadow-md")}
        onClick={() => {
          if (elapsedMS > 3*60*1000) {  // at least 3min after last sent
            setLS(evKey, Date.now());
            dispatch( apiReq({ command: 'sendEmailVerification' }) );
          } else {
            alert(dic('check_email'));
          }
        }}
      >
        {lastSent
        ? `${dic('verify_email_again')}(${sec2hms(elapsedMS/1000)} ${dic('ago')})`
        : dic('verify_email')}
      </button>
    </span>
  );
}

interface IProfileModalProps extends IClassNameObj {
  uid?: TUserID;  // user id: if not specified, self
};
/**
 * Profile with a modal dialog
 */
export const ProfileModal: React.FC<IProfileModalProps> = ({ uid, className, children }) => {
  const state = useOverlayTriggerState({});
  const openBtnRef  = useRef<HTMLButtonElement>(null);
  const closeBtnRef = useRef<HTMLButtonElement>(null);
  const { dic } = useLocaleCtx();

  // useButton ensures that focus management is handled correctly, across all browsers.
  // Focus is restored to the button once the dialog closes.
  const { buttonProps: openBtnProps  } = useButton( { onPress: () => state.open()  }, openBtnRef);
  const { buttonProps: closeBtnProps } = useButton( { onPress: () => state.close() }, closeBtnRef);
  return (
    <>
      <button ref={openBtnRef}
        {...openBtnProps}
        {...cLo(className, "focus:outline-none cursor-pointer")}
      >
        {children}
      </button>
      {state.isOpen &&
      <OverlayContainer>
        <ModalDialog
          title={dic('menu_profile')}
          clsDialog={cL("mx-8 lg:w-2/3 max-w-lg font-mono rounded-lg shadow-lg")}
          isOpen
          onClose={state.close}
          isDismissable
        >
          <div {...cLo("flex flex-col justify-center rounded-b-md bg-gray-200 text-black")}>
            {uid
            ? <ProfileUserById {...{uid}} />
            : <ProfileSelf />
            }
            <button
              ref={closeBtnRef}
              {...closeBtnProps}
              {...cLo("p-1 bg-gray-600 text-white rounded-b-lg shadow-lg",
                "hover:text-yellow-400 hover:bg-gray-700")}
            >
              {dic('close')}
            </button>
         </div>
        </ModalDialog>
      </OverlayContainer>}
    </>
  );
}

interface IUserPhotoFrameProps extends IEditable {
  ux?:          IUserEx;
  setName?:     (text: string) => void;
  setBackURL?:  (text: string) => void;
  setPhotoURL?: (text: string) => void;
};
/**
 * User photos (background and avatar) portion of the profile
 */
const UserPhotoFrame = ({
  editable, ux
}: IUserPhotoFrameProps) => {
  const { dic } = useLocaleCtx();
  const dispatch = useAppDispatch();
  const [open,  setOpen]  = useState(false);  // ModalConfirm
  const [error, setError] = useState<TError0>(null);
  const [handle,   setHandle]   = useState<stringU>(ux?.handle || undefined);
  // const [userid,   setUserid]   = useState<stringU>(ux?.userid || undefined);
  const [backURL,  setBackURL]  = useState<string>(ux?.backURL  || '');
  const [photoURL, setPhotoURL] = useState<string>(ux?.photoURL || '');
  // const twDnD = "relative flex-1 flex flex-col justify-center";
  const twAvatar = "mx-auto h-24 w-24 bg-gray-200";
  const twAvatarBorder = "border-white shadow-md rounded-full overflow-hidden";
  const twInput = "px-1 w-40 font-semibold rounded-md text-center bg-transparent";
  // Check if ux got value
  if (handle === undefined && ux?.handle)   setHandle(ux.handle);
  // if (userid === undefined && ux?.userid)   setUserid(ux.userid);
  if (!backURL  && ux?.backURL)  setBackURL(ux.backURL);
  if (!photoURL && ux?.photoURL) setPhotoURL(ux.photoURL);
  const changed =
    (handle !== ux?.handle);
    // || (userid !== ux?.userid);
  const onModalDlgCB = async (ok: boolean) => {
    if (ux && ok) {
      try {
        //-----------------------------------------------------------------------
        // update user info
        //-----------------------------------------------------------------------
        const resultAction = await dispatch( updateUser({ uid: ux.uid, handle }) );
        //-----------------------------------------------------------------------
        unwrapResult(resultAction);  // extract payload
        console.log("user profile updated", handle);
      } catch(error) {
        console.log("dispatch ( updateUser ):", error);
        setError(errorFmt('ERROR', "Profile Update", "Failed to update the user!"));
      }
    }
    setOpen(false);
  }

  return (
    <>
      <div {...cLo("w-full justify-center items-center overflow-hidden")}>
        {/*
        {editable
        ? <>
            <DnDOpenFile info
              url={backURL}
              {...cLo(twDnD, "h-52 sm:h-64")}
              onFileCB={f => setBackURL?.(f?.preview || FALLBACK_BKG)}
            />
            <DnDOpenFile
              url={photoURL || ANON_AVATAR}
              {...cLo(twDnD, twAvatar, "rounded-full")}
              clsBorder={twAvatarBorder}
              clsButton="bottom-0 right-0"
              onFileCB={f => setPhotoURL?.(f?.preview || ANON_AVATAR)}
            />
          </>
          :
          */}
          <div {...cLo("flex flex-col justify-center mb-2 pb-12")}>
            <img
              src={backURL}
              {...cLo("w-full h-auto max-h-52 overflow-y-scroll object-cover")}
              alt="back"
            />
            <img
              src={photoURL || ANON_AVATAR}
              {...cLo(twAvatar, "-my-12", twAvatarBorder)}
              alt="avatar"
            />
          </div>
      </div>
      <div {...cLo("relative")}>
        <div {...cLo("flex flex-col justify-center items-center")}>
          <input
            {...cLo(twInput, "text-base",
              (handle !== ux?.handle) && "bg-pink-100")}
            type="text"
            placeholder={dic('handle')}
            value={handle}
            disabled={!editable}
            onChange={ev => setHandle(ev.target.value)}
          />
          {/*
          <input
            {...cLo(twInput, "UseridPrefix text-sm",
              (userid !== ux?.userid) && "bg-yellow-100")}
            type="text"
            placeholder={dic('userid')}
            value={userid}
            disabled={!editable}
            onChange={ev => setUserid(ev.target.value)}
          />
          */}
        </div>
        {editable && changed && <ButtonC
          classX={cL("absolute inset-y-0 right-0 mr-8 BC_Green px-8 text-base rounded-md TextShadowB")}
          onPress={() => setOpen(true)}
        >
          {dic('save')}
        </ButtonC>}
      </div>
      {error && <ErrorDlg {...{error}} />}
      <ModalDlg {...{open, setOpen}}
        checkmark
        title={dic('title_profile_edit')}
        ok={dic('ok')}
        cancel={dic('cancel')}
        onOkCancel={onModalDlgCB}
      >
        <div>{dic('ask_save')}</div>
      </ModalDlg>
    </>
  );
}

interface IUserDetail {
  ux?:     IUserEx;
  myself?: boolean;
};
interface IUserDetailProps extends IUserDetail, IEditable {
  setProfile?: (text: string) => void;
  setGroup?:   (text: string) => void;
  setCity?:    (text: string) => void;
};

/**
 * user detail portion of the profile
 */
const UserDetail: React.FC<IUserDetailProps> = ({
  children, myself, editable, ux
}) => {
  const [detail, setDetail] = useState(false);
  // const { dic } = useLocaleCtx();
  // const twInput = "p-1 w-full rounded-md bg-gray-200";
  const {
    email, emailV, env,
    createdAt, signInAt,
  } = ux || { emailV: false };
  return (
    <div>
      <div {...cLo("p-1 text-sm text-gray-700")}>
        <div {...cLo("px-4 pb-4")}>
          <ItemSvgIcon Path="email_pro">
            {email}
            <EmailVerified {...cLo("ml-1")} {...{emailV}} />
            {myself && email && !emailV && <ButtonEmailVerification {...{email}} />}
          </ItemSvgIcon>
        </div>
        {email && <Namecard {...{email, editable}} />}
      </div>
      <div {...cLo("pt-2 font-mono")}>
        <div {...cLo("px-2 text-xs text-right bg-gray-800 text-gray-100 cursor-pointer")}
          onClick={() => setDetail(!detail)}
        >
          {env?.timeZone}
          <span {...cLo("pl-2 italic text-green-200")}>
            {env?.locale}
          </span>
        </div>
        <div {...cLoIf(detail)}>
          <div {...cLoIf(createdAt, "pl-1 text-3xs bg-gray-200 text-red-700")}>
            created {timeUMTlocal(createdAt)}
          </div>
          <div {...cLoIf(signInAt, "pl-1 text-3xs bg-gray-100 text-indigo-500")}>
            sign-in {timeUMTlocal(signInAt)}
          </div>
          <div {...cLoIf(children, "mt-1 text-3xs text-gray-400")}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

const ItemSvgIcon: React.FC< ISvgIconProps & Partial<IMouseProps> > = ({
  children,
  className = "w-6 h-6 fill-current mr-2",
  vLen = 512,
  onClick,
  ...props
}) =>
  <div {...cLo("flex items-center mt-1")}
    {...(onClick && {onClick})}
  >
    <SvgIcon {...{className, vLen}} {...props} />
    {children}
  </div>;

/**
 * Profile self
 * Just after OAuth login, photoURL is still empty!
 */
export function ProfileSelf({ editable }: IEditable) {
  // Use firebase user info for self data intentionally.
  const cUser = currentUser();      // firebase user
  const ux0 = useUserById(cUser?.uid ?? '-1');  // extended user info
  // If the use is not registered yet, ux is null.
  if (!cUser)
    return null;
  const { timeZone, locale } = Intl.DateTimeFormat().resolvedOptions();
  const { metadata } = cUser;
  const ux = {
    env: { timeZone, locale },
    createdAt: metadata.creationTime,
    signInAt:  metadata.lastSignInTime,
    ...ux0
  } as IUserEx;
  return (
    <div>
      <UserPhotoFrame {...{editable, ux}} />
      <UserDetail myself {...{ux, editable}}>
        {cUser.providerData.map((pd, i) =>
          <ul key={i}>
            [Provider {i}] {pd?.providerId} / {pd?.displayName}
          </ul>
        )}
      </UserDetail>
      {!ux && <Register />}
    </div>
  );
}

/**
 * Profile by uid - readonly
 */
export function ProfileUserById({ uid }: { uid: TUserID; }) {
  const ux = useUserById(uid);  // extended user info
  if (!ux)
    return null;
  return (
    <div>
      <UserPhotoFrame {...{ux}} />
      <UserDetail {...{ux}} />
    </div>
  );
}

/*
          <input
            {...cLo(twInput, "border-b border-gray-400")}
            type="text"
            placeholder={dic('menu_profile')}
            value={profile}
            disabled={!editable}
            onChange={ev => setProfile?.(ev.target.value)}
          />
          <ItemSvgIcon Path="email_pro">
            {email}
            <EmailVerified {...cLo("ml-1")} {...{emailV}} />
            {myself && email && !emailV && <ButtonEmailVerification {...{email}} />}
          </ItemSvgIcon>
          <ItemSvgIcon
            d={getPathD("people_back") +
               getPathD("people_front")}
          >
            <input
              {...cLo(twInput)}
              type="text"
              placeholder={dic('group')}
              value={group}
              disabled={!editable}
              onChange={ev => setGroup?.(ev.target.value)}
            />
          </ItemSvgIcon>
          <ItemSvgIcon Path="pin_location">
            <input
              {...cLo(twInput)}
              type="text"
              placeholder={dic('location')}
              value={city}
              disabled={!editable}
              onChange={ev => setCity?.(ev.target.value)}
            />
          </ItemSvgIcon>

      <div className="flex justify-center my-4">
        <div {...cLo("flex justify-center")}>
          <ButtonC
            classX={cL("BC_Green p-2 m-2 w-40 text-base rounded-md")}
            onPress={() => alert("プロフィールの更新機能は近日公開の予定です！")}
          >
            {dic('update')}
          </ButtonC>
        </div>
      </div>
 */

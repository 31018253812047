/*=============================================================================
 Routes.tsx - 

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import type { IAuthRouteProps } from './AuthRoute';
import type { TDicKey, } from 'ui/locale/selectLocales';
// ----------------------------------------------------------------------------
// Components for each routed page
// ----------------------------------------------------------------------------
// import Home from 'features/home/Home';
import AuthPage  from 'features/auth/AuthPage';
// import RootPage  from 'features/root/RootPage';
// import Dashboard from 'features/dash/Dashboard';
import Calendar  from 'features/cal/Calendar';
// import Timeline  from 'features/timeline/Timeline';
// import DnDKanban from 'features/kanban/DnDKanban';
// import Shop      from 'features/shop/Shop';
// import Chat      from 'features/chat/Chat';
import EventPage    from 'features/event/EventPage';
// import Profile      from 'features/profile/ProfilePage';
// import CtcsList     from 'features/ctcs/CtcsList';
// import Setting   from 'features/setting/SettingPage';
// ----------------------------------------------------------------------------
// import { SvgIcon, } from 'utils/svg';
import RSVP, { RouteRsvp, } from 'features/rsvp/RSVP';

// ----------------------------------------------------------------------------
/**
 * Routes
 * Route paths, constants
 */
const ROUTES = {
  root:     "/",
  auth:     "/auth",
  event:    "/event",
  // chat:     "/chat",
  calendar: "/calendar",
  // profile:  "/profile",
  // ctcslist: "/ctcslist",
  rsvp:     RouteRsvp,  // from IRSVP
};

// ----------------------------------------------------------------------------
/**
 * Route components
 * - connects the route with a component and sets each access level
 * Connects Components to the Route path
 * Route - Component map for react-router's <Route>
 * react-router: component and render cannot be used at the same time
 * SubRoutes not supported yet.
 * - access level: 0 public, 1: login required
 *
 * { path: ROUTES.root,    children: <Home />,  access: 0 },
 */
export const RouteCompos: Partial<IAuthRouteProps>[] = [
  { path: ROUTES.auth,     children: <AuthPage />,  access: 0 },
  { path: ROUTES.event,    children: <EventPage />, access: 0 }, // 1 },
  { path: ROUTES.calendar, children: <Calendar />,  access: 0 },
  // { path: ROUTES.chat,     children: <Chat />,      access: 1 },
  // { path: ROUTES.profile,  children: <Profile />,   access: 1 },
  // { path: ROUTES.ctcslist, children: <CtcsList />,  access: 1 },
  { path: ROUTES.rsvp,     children: <RSVP />,      access: 0 },
  { path: ROUTES.root,     children: <EventPage />,      access: 0, exact: true },
  // { path: ROUTES.root,     children: <Profile />,   access: 1, exact: true },
];

// ----------------------------------------------------------------------------
/**
 * Connects Menu Labels to the Route path
 * Route - Menu map for react-router's <Link> or <NavLink>
 */
interface IRouteMenuProps {
  to:    ValueOf< typeof ROUTES >;
  id?:   TDicKey;
  icon?: React.ReactNode;
}
/**
 * Menus list for Desktop
 * title will be shown if icon is not provided
 */
export const RouteMenus: IRouteMenuProps[] = [
  // { to: ROUTES.root,     icon: <SvgIcon Path="home_nav" /> },
  // { to: ROUTES.profile,     icon: <SvgIcon Path="home_nav" /> },
  // { to: ROUTES.dash,     title: "DB" },
  // { to: ROUTES.chat,     id: "menu_chat" },
  { to: ROUTES.event,    id: "menu_event" },
  { to: ROUTES.calendar, id: "menu_calendar" },
  // { to: ROUTES.ctcslist, id: "menu_ctcslist" },
];

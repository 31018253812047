/*=============================================================================
 LocaleCtx.tsx - multiple languages (locales) context, i18n

 - https://dev.to/halilcanozcelik/create-a-multi-language-website-with-react-context-api-4i27

 - dic function: if there's no matching translation just use 'en' version.
  (8) ['en-US', 'en', 'ja', 'ko', 'la', 'fr', 'zh-TW', 'zh-CN']

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { useState, createContext, useContext, } from 'react';
import { getLS, setLS } from 'utils/useLocalStorage';
import type { TLocale, TDic, TDicKey, } from './selectLocales';
import { localeOptions, dicList, selectableLocale, } from './selectLocales';
import { getBrowserLocales, } from 'utils/util';

const LOCALE_FALLBACK = 'jp';  // should be ja

/**
 * Locale Context Provider
 */
interface ILocaleState {
  locale:    TLocale;
  dic:       (key: Undefinable<TDicKey>) => ValueOf<TDic>|'';
  setLocale: (selected: TLocale) => void;
};
export const LocaleContext = createContext<ILocaleState>({
  locale:    LOCALE_FALLBACK,
  dic:       (key: Undefinable<TDicKey>) => key ? dicList.en[key] : '',
  setLocale: (_selected: TLocale) => {}
});
export const useLocaleCtx = () => useContext(LocaleContext);

export const LocaleProvider: React.FC = ({ children }) => {
  const LOCALE = 'locale';
  let lo = getLS<TLocale>(LOCALE) || getBrowserLocales()?.[0];
  if (!selectableLocale(lo))
    lo = LOCALE_FALLBACK;
  const [locale, setLocale] = useState<TLocale>(lo);

  const provider = {
    locale,
    dic: (key: Undefinable<TDicKey>) =>
      key
      ? dicList[locale][key] || dicList.en[key]  // use 'en' version if no matching locale
      : '',
    setLocale:  (selected: TLocale) => {
      const newLocale = localeOptions[selected] ? selected : LOCALE_FALLBACK;
      setLocale(newLocale);
      setLS(LOCALE, newLocale);
    }
  }

  return (
    <LocaleContext.Provider value={provider}>
      {children}
    </LocaleContext.Provider>
  );
}

